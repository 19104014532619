import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';

import './ReportDropdown.css';

class ReportDropdown extends Component {
    
    state = {
        dropdownOpen: false        
    };

    toggle = () => {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() { 
        return ( 
            <Dropdown
            className="position-absolute"
            style={{right:'5px'}}
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
            direction="down">
                <DropdownToggle
                tag="a"
                onClick={this.toggle}
                data-toggle="dropdown"
                aria-expanded={this.state.dropdownOpen}
                className="report-dropdown-toggle"
                >
                    <ChevronDown size={20} />
                </DropdownToggle>
                <DropdownMenu>
                    <Link className="dropdown-item" to={`/reports/creatives?oid=${this.props.offerId}&startDate=${this.props.startDate}&endDate=${this.props.endDate}`} onClick={this.toggle}>Creatives</Link>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-item py-2" to={`/reports/subid?oid=${this.props.offerId}&startDate=${this.props.startDate}&endDate=${this.props.endDate}`} onClick={this.toggle}>SubID 1</Link>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-item" to={`/reports/landingPages?oid=${this.props.offerId}&startDate=${this.props.startDate}&endDate=${this.props.endDate}`} onClick={this.toggle}>Landing Pages</Link>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
 
export default ReportDropdown;