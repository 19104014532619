import React, { Component } from 'react';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Link } from 'react-router-dom';
import Loading from '../../../component/UI/Loading/Loading';
import { clearLocalStorageAndReload, verifyToken } from '../../../assets/JS/functions';
import { X } from 'react-feather';
import moment from 'moment';
import _ from 'lodash';

class ReportLandingPage extends Component {

    state = {
        dataLoaded: false,
        report: [],
        totals: [],
        campaign: [],
        sort: {
            column: 'earnings',
            order: 'desc'
        }
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        });

        const mbToken = localStorage.getItem('mb-auth-token');
        let realEndDate = moment(this.props.endDate).add(1, "d").format('YYYY-MM-DD HH:mm:ss');

        /* Get the affiliate object from the DB and populate the state information */
        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/reports/landingPages?oid=${this.props.offerId}&startDate=${this.props.startDate}&endDate=${realEndDate}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                this.props.handleDownloadData(result);
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report,
                                    totals: result.totals,
                                    campaign: result.campaign
                                })
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportlandingPage: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    handleSort = (sortBy) => {
        const sort = { ...this.state.sort };

        if (sort.column === sortBy) {
            sort.order = (sort.order === 'asc') ? 'desc' : 'asc';
        } else {
            sort.column = sortBy;
            switch (sort.column) {
                case 'landing_page':
                    sort.order = 'asc'
                    break;
                case 'clicks':
                    sort.order = 'desc'
                    break;
                case 'leads':
                    sort.order = 'desc'
                    break;
                case 'conversion':
                    sort.order = 'desc'
                    break;
                case 'sales':
                    sort.order = 'desc'
                    break;
                case 'earnings':
                    sort.order = 'desc'
                    break;
                case 'epc':
                    sort.order = 'desc'
                    break;
                default:
                    throw new Error('Invalid sorting');
            }

        }

        const sorted = _.orderBy(this.state.report, sortBy, sort.order)

        this.setState({
            report: sorted,
            sort: sort
        });
    }

    render() {

        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");

        return (
            <React.Fragment>

                {this.state.dataLoaded &&
                    <div class="alert alert-info m-2 d-inline-block">
                        Landing Pages for: <Link to={`/campaign/${this.state.campaign.campaign_id}`}>{this.state.campaign.name}</Link> &nbsp;
                    <Link to={`/reports/earnings?startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                <table class="table table-striped table-card table-hover">
                    <thead>
                        <tr>
                            <ReportHeader sortBy="landing_page" title="Landing Page" sort={this.state.sort} handleSort={this.handleSort} />
                            <th scope="col">&nbsp;</th>
                            <ReportHeader sortBy="clicks" title="Clicks" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="leads" title="Leads" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="conversion" title="CR (%)" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="sales" title="Sales" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="earnings" title="Earnings" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="epc" title="EPC" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {this.state.report.length === 0 ?
                                    <tr>
                                        <td colspan="7">No stats for this date range.</td>
                                    </tr>
                                    :
                                    <React.Fragment>

                                        {this.state.report.map(item => (
                                            <tr>
                                                <td>{item.landing_page_id}</td>
                                                <td><img src={item.thumbnail} alt="Landing Page" width="150px" /></td>
                                                <td align="right"><Link to={`/reports/clicks?oid=${this.props.offerId}&lpid=${item.landing_page_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>{Intl.NumberFormat('en-GB').format(item.clicks)}</Link></td>
                                                <td align="right"><Link to={`/reports/conversions?oid=${this.props.offerId}&lpid=${item.landing_page_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>{Intl.NumberFormat('en-GB').format(item.leads)}</Link></td>
                                                <td align="right">{parseFloat(item.conversion).toFixed(2)}</td>
                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.earnings)}</td>
                                                <td align="right">${parseFloat(item.epc).toFixed(2)}</td>
                                            </tr>
                                        ))}

                                        <tr class="font-weight-bold">
                                            <td colspan="2">Totals</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.clicks)}</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.leads)}</td>
                                            <td align="right">{parseFloat(this.state.totals.conversion).toFixed(2)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.sales)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.earnings)}</td>
                                            <td align="right">${parseFloat(this.state.totals.epc).toFixed(2)}</td>
                                        </tr>

                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <tr>
                                <td colspan="10"><Loading /></td>
                            </tr>
                        }

                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default ReportLandingPage;