import React, { Component } from 'react';

class FormIdentification extends Component {

    constructor(props) {
        super(props);
        this.formIdentification = React.createRef();
        this.idFront = React.createRef();
        this.idBack = React.createRef();
    }

    state = {}

    async componentDidMount() {
        let requestBody = {
            token: this.props.appData.appToken,
            email: this.props.values.email
        }

        await fetch(`${process.env.REACT_APP_API_URL}/register/trackApplication/removeIdEntry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
            .then(res => res.json())
            .then(result => {
                return result;
            })
            .catch(async error => {
                console.log('error: ', error);
            });
    }

    validate = () => {
        /* Check required fields */
        const input_front = this.idFront.current;
        const input_back = this.idBack.current;

        /* Reset any previous custom validity settings to valid */
        input_front.setCustomValidity('');
        input_back.setCustomValidity('');

        // console.log(this.props.values.idFront)

        if (input_front.checkValidity() === false || input_back.checkValidity() === false) {
            return false
        }
        else if (!this.props.values.idFront.type.match(/.(jpg|jpeg|png|gif)$/i)) {
            /* Check the extensions from the state */
            // File type is incorrect
            input_front.setCustomValidity('The image must be a jpg, png, or gif.');
            return false
        }
        else if (this.props.values.idBack.type && !this.props.values.idBack.type.match(/.(jpg|jpeg|png|gif)$/i)) {
            input_back.setCustomValidity('The image must be a jpg, png, or gif.');
            return false
        }
        else if (this.props.values.idFront.size > 25000000) {
            input_front.setCustomValidity('The image must be less than 25MB');
            return false
        }
        else if (this.props.values.idBack.size && this.props.values.idBack.size > 25000000) {
            input_back.setCustomValidity('The image must be less than 25MB');
            return false
        }
        else {
            return true
        }
    }

    saveAndContinue = async (e) => {
        e.preventDefault()
        if (this.validate()) {
            let name = this.props.values.firstname + " " + this.props.values.lastname;
            await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, 'Identification', this.props.values.company, name, this.props.values.address_1, this.props.values.address_2, this.props.values.city, this.props.values.state, this.props.values.country, this.props.values.zipcode, this.props.values.phone, this.props.values.mobile, this.props.values.im_handle, this.props.values.experience_description, this.props.values.experience_level)
            this.props.nextStep()
        } else {
            /* Forces html5 form validation */
            const formIdentification = this.formIdentification.current;
            formIdentification.reportValidity();
        }
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    render() {

        return (
            <form ref={this.formIdentification}>
                <p className="text-white pb-2">We require all of our affiliates to submit a piece of photo government identification. Please upload a photo of the front and back of a piece of identifcation stating your full name and address.</p>
                <div className="form-row mb-3 text-white">
                    <div className="col text-left">
                        <label htmlFor="phone">Photo ID Front*</label>
                        <input
                            ref={this.idFront}
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={this.props.handleFileChange('idFront')}
                            placeholder="Photo ID Front"
                            className="form-control"
                            required />
                    </div>
                    <div className="col text-left">
                        <label htmlFor="mobile">Photo ID Back</label>
                        <input
                            ref={this.idBack}
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={this.props.handleFileChange('idBack')}
                            placeholder="Photo ID Back"
                            className="form-control" />
                    </div>
                </div>
                <div className="d-flex flex-row-reverse">
                    <div className="pl-2 flex-1">
                        <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Next</button>
                    </div>
                    <div className="flex-1">
                        <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default FormIdentification;