import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

import RegistrationForm from '../../component/RegistrationForm/RegistrationForm';

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png';
import dotPattern from '../../assets/images/dot-pattern.png';
import loginVideo from '../../assets/images/login.mp4';

import crypto from 'crypto';

import './Register.css';

class Register extends Component {
    state = {
        appData: {
            referrer: "",
            mktg: "",
            mktgDetails: {
                header: "",
                image: "",
                content: ""
            },
            blg: "",
            original_affiliate_id: "",
            http_referrer: "",
            coreg_id: "",
            coreg_email: "",
            appToken: ""
        }
    }

    async componentDidMount() {
        // Load the Facebook Pixel       
        ReactPixel.init('845836302726163')
        ReactPixel.pageView();
        LinkedInTag.init('7640665');
        LinkedInTag.track('17776452');

        // Get required data from url (referrer, mktg, coregId, coregEmail)
        let referrerId = '';
        let promoId = '';
        let coregId = '';
        let coregEmail = '';
        let originalAffiliateId = 0;
        let httpReferrer = '';
        let bloggerId = '';

        // Check these for undefined
        const { referrer: urlReferrerId, mktg: urlPromoId, i: urlCoregId, e: urlCoregEmail, blg: urlBloggerId } = queryString.parse(this.props.location.search);

        // Check these for null
        const storageReferrerId = localStorage.getItem('mb-ref');
        const storageOriginalAffiliateId = localStorage.getItem('mb-oid');
        const storagePromoId = localStorage.getItem('mb-mktg');
        const storageBloggerid = localStorage.getItem('mb-blg');

        // Check to see if there is referrer info in the url, and if not, check if it has been set in local storage previously
        if (urlReferrerId !== undefined && !isNaN(urlReferrerId)) {
            referrerId = urlReferrerId;
            // Set the localStorage in case they navigate away and come back later
            localStorage.setItem('mb-ref', urlReferrerId);
        } else if (storageReferrerId !== null && !isNaN(storageReferrerId)) {
            referrerId = storageReferrerId
        }

        if (referrerId > 0){
            this.props.showSystemMessage("success", "+10% Referral Bonus Activated");
        }

        // Check if affiliate has logged into another accout previously
        if (storageOriginalAffiliateId !== null && !isNaN(storageOriginalAffiliateId)) {
            originalAffiliateId = storageOriginalAffiliateId
        }

        // Check to see if there is a marketing campaign
        if (urlPromoId !== undefined) {
            promoId = urlPromoId
            // Set the localStorage in case they navigate away and come back later
            localStorage.setItem('mb-mktg', urlPromoId);
        } else if (storagePromoId !== null) {
            promoId = storagePromoId
        }

        // chcek to see if we should store mb-blg if a user came in from a blogger link
        if (urlBloggerId !== undefined && !isNaN(urlBloggerId)) {
            bloggerId = urlBloggerId
            localStorage.setItem('mb-blg', urlBloggerId)
        } else if (storageBloggerid !== null && !isNaN(storageBloggerid)) {
            bloggerId = storageBloggerid
        }

        // Check to see if this is a coreg signup
        if (!isNaN(urlCoregId) && urlCoregEmail.length > 0) {
            coregId = urlCoregId;
            coregEmail = urlCoregEmail;
        }

        // Check http referrer
        if (document.referrer !== undefined) {
            httpReferrer = document.referrer;
        }

        let marketingDetails = {
            header: "",
            image: "",
            content: ""
        }

        if (promoId !== '') {
            // Get the promo information if any
            await fetch(`${process.env.REACT_APP_API_URL}/marketing/campaign/${promoId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result["success"] === true) {
                            marketingDetails = {
                                header: result.data.campaign_header,
                                image: result.data.campaign_image,
                                content: result.data.campaign_content
                            }

                            if (result.data.campaign_header.length > 0 && window.innerWidth < 768){
                                this.props.showSystemMessage("success", result.data.campaign_header);
                            }
                        }
                    }).catch(error => {
                        // No need to do anything
                    });
        }

        // Generate a token for tracking this application
        const rand = Math.random().toString(36).substring(2, 20) + Math.random().toString(36).substring(2, 20);
        const hash = crypto.createHash('md5').update(rand).digest('hex');

        this.setState({
            appData: {
                referrer: referrerId,
                mktg: promoId,
                mktgDetails: marketingDetails,
                blg: bloggerId,
                original_affiliate_id: originalAffiliateId,
                http_referrer: httpReferrer,
                coreg_id: coregId,
                coreg_email: coregEmail,
                appToken: hash
            }
        });
    }

    // This is only called when applicant returns to incomplete application
    // updateAppToken(token) {
    //     this.setState({ ...this.state, appData: { ...this.state.appData, appToken: token } });
    // }

    render() {
        return (
            <React.Fragment>

                <section className="register-container text-center d-flex align-items-center" style={{ background: 'RGBA(0,0,0,0.9)' }}>

                    {/* Background Video */}
                    <div style={{ backgroundImage: `URL(${dotPattern})`, backgroundRepeat: 'repeat', height: '100%', width: '100%', position: 'fixed', zIndex: '9' }}></div>
                    <video autoPlay muted loop className="login-video">
                        <source src={loginVideo} type="video/mp4" />
                    </video>                

                    <div className="row w-100 z-99 h-100" style={{ marginLeft: '0px' }}>
                        <div className="col-md-5 left-register">
                            <div className="m-auto p-4">
                                <div style={{ position: 'relative' }}>                                    
                                    {this.state.appData.mktgDetails.image !== "" ?
                                        <img className="partner-logo" src={`${process.env.REACT_APP_RESOURCE_URI}/images/marketingPartners/${this.state.appData.mktgDetails.image}`} alt="partner-logo" /> 
                                    :
                                        <img className="partner-logo" src={`${process.env.REACT_APP_RESOURCE_URI}/images/marketingPartners/MBHOR.jpg`} alt="partner-logo" /> }
                                    {this.state.appData.mktgDetails.header !== "" ?
                                        <h2>{this.state.appData.mktgDetails.header}</h2>
                                        :
                                        <h1>Affiliate Registration</h1>
                                    }
                                    <hr className="border-light w-50"></hr>
                                    {this.state.appData.mktgDetails.content !== "" ?
                                        <p dangerouslySetInnerHTML={{ __html: `${this.state.appData.mktgDetails.content}` }}></p>
                                        :
                                        <p>
                                            Start maximizing your affiliate earnings by working with the <b>#1 voted CPA network.</b>

                                            Simply fill out our affiliate application to the right to begin promoting thousands of campaigns from our largest selection of brands ever!
                                            <br></br>
                                            <div className="row mktg-icons">
                                                <div className="col-sm">
                                                    <div className="mb-feature">
                                                        <div className="mb-feature-image" style={{textAlign: 'center'}}>
                                                            <img style={{maxWidth: '50px'}} src="https://maxbounty.com/resources/images/marketingPartners/activecampaigns.png" />
                                                        </div>
                                                        <div>
                                                            <p className="font-weight-bold mb-1">2000+ ACTIVE OFFERS</p>
                                                            <p>Promote offers in diverse verticals from diverse advertisers.   </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="mb-feature">
                                                        <div className="mb-feature-image" style={{textAlign: 'center'}}>
                                                            <img style={{maxWidth: '50px'}} src="https://maxbounty.com/resources/images/marketingPartners/featurerich.png" />
                                                        </div>
                                                        <div>
                                                            <p className="font-weight-bold mb-1">FEATURE-RICH DASHBOARD</p>
                                                            <p>Discover our best-converting offers and track your progress.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col-sm">
                                                    <div className="mb-feature">
                                                        <div className="mb-feature-image" style={{textAlign: 'center'}}>
                                                            <img style={{maxWidth: '50px'}} src="https://maxbounty.com/resources/images/marketingPartners/weeklypayments.png" />
                                                        </div>
                                                        <div>
                                                            <p className="font-weight-bold mb-1">WEEKLY PAYMENTS</p>
                                                            <p>Keep your budget healthy by being paid on time every week. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="mb-feature">
                                                        <div className="mb-feature-image" style={{textAlign: 'center'}}>
                                                            <img style={{maxWidth: '50px'}} src="https://maxbounty.com/resources/images/marketingPartners/affiliatemanagers.png" />
                                                        </div>
                                                        <div>
                                                            <p className="font-weight-bold mb-1">COMMITTED AFFILIATE MANAGERS</p>
                                                            <p>Get expert advice on what offers and strategies work best.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col-sm">
                                                    <div className="mb-feature">
                                                        <div className="mb-feature-image" style={{textAlign: 'center'}}>
                                                            <img style={{maxWidth: '50px'}} src="https://maxbounty.com/resources/images/marketingPartners/performance.png" />
                                                        </div>
                                                        <div>
                                                            <p className="font-weight-bold mb-1">PERFORMANCE BONUSES</p>
                                                            <p>Earn even more with performance-based promotions and rewards.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="mb-feature">
                                                        <div className="mb-feature-image" style={{textAlign: 'center'}}>
                                                            <img style={{maxWidth: '50px'}} src="https://maxbounty.com/resources/images/marketingPartners/international.png" />
                                                        </div>
                                                        <div>
                                                            <p className="font-weight-bold mb-1">INTERNATIONAL OFFERS</p>
                                                            <p>Promote affiliate offers to people around the globe.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                           

                                            <p style={{fontSize: '16px'}}>Learn more at <a href="https://www.maxbounty.com/">MaxBounty.com</a></p>
                                        </p>                                        
                                    }
                                    <p>Increase your chances of approval. Read our step-by-step application guide <a rel="noopener noreferrer" target="_blank" href="https://www.mb102.com/lnk.asp?o=20793&c=918271&a=334932&l=21717">here</a>.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 right-register">                            
                            <div className="form-register">
                                <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="MaxBounty" width="100" />
                                <RegistrationForm appData={this.state.appData} showSystemMessage={this.props.showSystemMessage}
                                    location={this.props.location}
                                ></RegistrationForm>
                                <Link to="/">Already have an account? Sign in now.</Link>
                                <p className="mt-5 mb-3 text-muted">MaxBounty Inc. </p>
                            </div>
                        </div>

                    </div>

                </section>

            </React.Fragment>
        );
    }
}

export default Register;