import React, { Component } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import crypto from 'crypto';
import moment from 'moment';

class Payment extends Component {

    state = {
        paymentModal: false,
        paymentStep: 1,
        payoneerPaymentButtonActive: true,
        tipaltiPaymentButtonActive: true,
        paymentChoice: '',
        paymentInformation: this.props.affiliate.payment_information,
        payoneer: {
            iframe: '',
            data: {
                payee_id: this.props.affiliate.publisher_id,
                lock_type: 'ADDRESS',
                payee: {
                    contact: {
                        email: this.props.affiliate.affiliate_personal_information.email,
                        mobile: ''
                    },
                    address: {
                        address_line_1: this.props.affiliate.affiliate_address.address1,
                        address_line_2: this.props.affiliate.affiliate_address.address2,
                        city: this.props.affiliate.affiliate_address.city,
                        country: this.props.affiliate.affiliate_address.country_code,
                        zip_code: this.props.affiliate.affiliate_address.zipcode
                    },
                    redirect_url: "https://affiliates.maxbounty.com/paycompletion"
                }
            }
        },
        tipalti: {
            iframe: ''
        }
    }

    togglePaymentModal = () => {
        this.setState({
            paymentModal: !this.state.paymentModal
        });
    }

    usePayoneer = () => {

        const authToken = localStorage.getItem("mb-auth-token");

        this.setState({ payoneerPaymentButtonActive: false })

        /* Make required changes in MaxBounty and get iframe through API */

        fetch(`${process.env.REACT_APP_API_URL}/paychange/payoneer`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            },
            body: JSON.stringify(this.state.payoneer.data)
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {

                    this.setState({
                        paymentStep: 2,
                        payoneerPaymentButtonActive: true,
                        paymentChoice: 'payoneer',
                        paymentInformation: {
                            ...this.state.paymentInformation,
                            tmp_payoneer: 1,
                            method: result['method'],
                            platform: result['platform']
                        },
                        payoneer: {
                            ...this.state.payoneer,
                            iframe: result['iframe']
                        }
                    });

                } else if (result["success"] === false) {
                    this.props.showSystemMessage("error", result.errors.message);
                } else {
                    this.props.showSystemMessage("error", result.errors.message);
                }
            })
            .catch(error => {
                this.props.showSystemMessage("error", error.message);
            });

    }

    useTipalti = () => {

        const authToken = localStorage.getItem("mb-auth-token");

        this.setState({ tipaltiPaymentButtonActive: false });

        /* Make required changes in MaxBounty */
        fetch(`${process.env.REACT_APP_API_URL}/paychange/tipalti`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {

                    /* We don't actually need anything in the response of this request. */

                    /* Load the iframe */

                    const tipaltiBaseUrl = "https://ui2.tipalti.com/payeedashboard/home?";

                    /* Determine the Timestamp variable - seconds since epoch */
                    const epochTime = moment('1970-01-01').format();
                    const isDaylightSavingsTime = moment().isDST();
                    let utcOffset = moment().utcOffset() * (-60);

                    if (isDaylightSavingsTime) {
                        utcOffset += 3600;
                    }

                    const epochSeconds = moment().diff(epochTime, 'seconds') + utcOffset;

                    /* Build the url */
                    const queryString = `idap=${this.props.affiliate.publisher_id}&payer=MaxBounty&ts=${epochSeconds}`;
                    const hmacString = crypto.createHmac('sha256', process.env.REACT_APP_TIPALTI_KEY).update(queryString).digest('hex').toLowerCase();

                    const iframeUrl = `${tipaltiBaseUrl}${queryString}&hashkey=${hmacString}`;

                    this.setState({
                        paymentStep: 2,
                        tipaltiPaymentButtonActive: true,
                        paymentChoice: 'tipalti',
                        paymentInformation: {
                            method: result['method'],
                            platform: result['platform']
                        },
                        tipalti: {
                            iframe: iframeUrl
                        }
                    });

                } else if (result["success"] === false) {
                    this.props.showSystemMessage("error", result.errors.message);
                } else {
                    this.props.showSystemMessage("error", result.errors.message);
                }
            })
            .catch(error => {
                this.props.showSystemMessage("error", error.message);
            });
    }

    getPaymentSettingsScreen = () => {

        let iframeUrl;

        if (this.state.paymentChoice.toLowerCase() === 'payoneer') {
            iframeUrl = this.state.payoneer.iframe;
        } else if (this.state.paymentChoice.toLowerCase() === 'tipalti') {
            iframeUrl = this.state.tipalti.iframe;
        }
        return <iframe title={this.state.paymentChoice} style={{ width: '100%', height: '700px', border: '1px solid black' }} src={iframeUrl}></iframe>
    }

    checkDateForPayment = () => {
        let currentDay = moment().day();
        let display = true;

        // Tuesday
        if (currentDay === 2) {
            let timeOnTuesday = `${moment().utcOffset('-0500').format('YYYY-MM-DD')} 09:00`;
            if (moment().utcOffset('-0500') >= moment(timeOnTuesday)) {
                display = false;
            }
        }

        // Wednesday
        if (currentDay === 3) {
            let timeOnWednesday = `${moment().utcOffset('-0500').format('YYYY-MM-DD')} 12:00`;
            if (moment().utcOffset('-0500') <= moment(timeOnWednesday)) {
                display = false;
            }
        }

        return display;
    }

    render() {

        // Before they can switch anything, they should have to re-enter password.

        const pay = this.state.paymentInformation;
        const thePlatform = pay.platform || '';

        return (
            <div className="card my-2 max-width-750">
                <div className="card-header d-flex align-items-center">
                    <div className="flex-1">Payment Information</div>
                    <div className="text-right">
                        {this.props.showEdit &&
                            <button className="btn btn-warning btn-sm" onClick={this.togglePaymentModal}>Settings</button>}
                        <Modal isOpen={this.state.paymentModal} toggle={this.togglePaymentModal} size='lg'>
                            <ModalHeader toggle={this.togglePaymentModal}>Payment Settings</ModalHeader>
                            <ModalBody>

                                {this.state.paymentStep === 1 &&
                                    <div>
                                        <div className="d-flex flex-wrap">
                                            <div className="card m-1 image-card border">
                                                <img className="card-img-top" src={require(`../../../assets/images/payoneer.jpg`)} alt="Payoneer" />
                                                <div className="card-body d-flex flex-column justify-content-between">
                                                    <h5 className="card-title">Payoneer</h5>
                                                    <ul>
                                                        <li>- Bank Transfer</li>
                                                        <li>- Prepaid Mastercard</li>
                                                        <li>- <a target="_blank" rel="noopener noreferrer" href={require(`../../../assets/resources/payoneer.pdf`)}>More Details</a> </li>
                                                    </ul>
                                                    {this.state.payoneerPaymentButtonActive ? (this.checkDateForPayment() ?
                                                        <React.Fragment>
                                                            {pay.tmp_payoneer === 1 && thePlatform.toLowerCase() !== 'payoneer' ?
                                                                <button onClick={this.usePayoneer} className="btn btn-warning">Pending</button>
                                                                :
                                                                <React.Fragment>
                                                                    {thePlatform.toLowerCase() === 'payoneer' ?
                                                                        <button onClick={this.usePayoneer} className="btn btn-primary">Edit Details</button>
                                                                        :
                                                                        <button onClick={this.usePayoneer} className="btn btn-primary">Use Payoneer</button>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <button className="btn btn-secondary" disabled>Changes Unavailable (See Below)</button>) :
                                                        <button className="btn btn-primary" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card m-1 image-card border">
                                                <img className="card-img-top" src={require(`../../../assets/images/tipalti.jpg`)} alt="Tipalti" />
                                                <div className="card-body d-flex flex-column justify-content-between">
                                                    <h5 className="card-title">Tipalti</h5>
                                                    <ul>
                                                        <li>- ACH</li>
                                                        <li>- Wire</li>
                                                        <li>- Local Bank Transfer (eCheck)</li>
                                                        <li>- PayPal</li>
                                                        {/* <li className="text-danger">* Please contact your AM if you are switching to Tipalti.</li> */}
                                                    </ul>
                                                    {this.state.tipaltiPaymentButtonActive ?
                                                        (this.checkDateForPayment() ?
                                                            <React.Fragment>
                                                                {thePlatform.toLowerCase() === 'tipalti' ?
                                                                    <button onClick={this.useTipalti} className="btn btn-primary">Edit Details</button>
                                                                    :
                                                                    <button onClick={this.useTipalti} className="btn btn-primary">Use Tipalti</button>
                                                                }
                                                            </React.Fragment>
                                                            :
                                                            <button className="btn btn-secondary" disabled>Changes Unavailable (See Below)</button>) :
                                                        <button className="btn btn-primary" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {!this.checkDateForPayment() &&
                                            <div className="mt-2 alert alert-warning">You cannot change payment details from Tuesday 9AM EST to Wednesday 12PM EST.</div>
                                        }
                                    </div>
                                }

                                {this.state.paymentStep === 2 &&
                                    <div>
                                        {this.getPaymentSettingsScreen()}
                                    </div>
                                }

                                <div>

                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.togglePaymentModal}>Close</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
                <div className="card-body p-0">
                    <table className="table table-card">
                        <tbody>
                            <tr>
                                <td className="no-border">Platform</td>
                                <td style={{ width: '70%' }} className="no-border">{(pay.platform !== '' && pay.platform !== null) ? pay.platform.toUpperCase() : (pay.tmp_payoneer === 1 && (pay.platform === '' || pay.platform === null)) ? 'Payoneer (Pending)' : null}</td>
                            </tr>
                            <tr>
                                <td>Method</td>
                                <td>{pay.method}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Payment;