import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import ScrollToTop from './hoc/ScrollToTop/ScrollToTop';
import Layout from './hoc/Layout/Layout';
import CampaignPage from './container/CampaignPage/CampaignPage';
import Dashboard from './container/Dashboard/Dashboard';
import Reports from './container/Reports/Reports';
import Browse from './container/Browse/Browse';
import Search from './container/Search/Search';
import MaxMoney from './container/MaxMoney/MaxMoney';
import Resources from './container/Resources/Resources';
import Refer from './container/Refer/Refer';
import Settings from './container/Settings/Settings';
import AccountBalance from './container/AccountBalance/AccountBalance';
import GlobalPostback from './container/GlobalPostback/GlobalPostback';
import Login from './container/Login/Login';
import OttLogin from './container/OttLogin/OttLogin';
import Register from './container/Register/Register';
import Verify from './container/Verify/Verify';
import Faq from './container/Faq/Faq';
import Terms from './container/Terms/Terms';
import ForgotPassword from './container/ForgotPassword/ForgotPassword';
import ResetPassword from './container/ResetPassword/ResetPassword';
import LoadingPage from './component/UI/LoadingPage/LoadingPage';
// import OTPsetup from './container/Login/OTP/OTPsetup';
// import OTPcheck from './container/Login/OTP/OTPcheck';

import Footer from './component/Footer/Footer';
import SystemMessage from './component/SystemMessage/SystemMessage';
import Announcements from './container/Announcements/Announcements';

import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import './App.css';
import PayCompletion from './container/PayCompletion/PayCompletion';

//fab imports all of the brand icons. You use it differently than other icons. example: icon={['fab','apple']}
library.add(faSpinner, fab);

class App extends Component {

  state = {
    dataLoaded: true,
    authenticated: false,
    affiliate: {
      affiliate_id: 0,
      name: "",
      phone: '',
      mobile_phone: '',
      maxmoney: 0,
      affiliate_manager: {
        name: "",
        email: "",
        phone: "",
        im_handle: "",
        image: ""
      },
      global_postback: {
        type: "",
        code: ""
      },
      payment_information: {
        payee: "",
        method: "",
        platform: null,
        tmp_payoneer: null
      },
      affiliate_flags: []
    },
    systemMessage: {
      display: false,
      type: "",
      message: ""
    }
  }

  checkAuth = () => {
    const mbToken = localStorage.getItem('mb-auth-token');
    const affiliateID = localStorage.getItem('mb-aid');
    const authToken = !localStorage.getItem('mb-t') ? '' : localStorage.getItem('mb-t');
    let decodedToken = ''

    try {
      decodedToken = CryptoAES.decrypt(authToken, CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoENC);
    } catch (err) {
      decodedToken = ''
    }

    let isAuth = false;
    //isAuth = true; /* For Testing */

    if (mbToken && affiliateID && (mbToken === decodedToken)) {
      isAuth = true;
    }

    return isAuth;
  }

  fetchAffiliateData = async () => {
    const mbToken = localStorage.getItem('mb-auth-token');

    let isAuth = this.checkAuth();

    /* Get the affiliate object from the DB and populate the state information */

    if (isAuth) {

      this.setState({
        dataLoaded: false
      });

      await fetch(`${process.env.REACT_APP_API_URL}/affiliate`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-app-token': mbToken
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result["success"] === true) {
              this.setState({
                dataLoaded: true,
                affiliate: {
                  affiliate_id: result.publisher_id,
                  name: result.affiliate_personal_information.name,
                  phone: result.affiliate_personal_information.phone,
                  mobile_phone: result.affiliate_personal_information.mobile_phone,
                  maxmoney: result.maxmoney_balance,
                  affiliate_manager: {
                    name: result.affiliate_manager.name,
                    email: result.affiliate_manager.email,
                    phone: result.affiliate_manager.phone,
                    im_handle: result.affiliate_manager.im_handle,
                    image: result.affiliate_manager.image_name
                  },
                  global_postback: {
                    code: result.promotional_methods.global_callback_code,
                    type: result.promotional_methods.global_callback_type
                  },
                  payment_information: {
                    payee: result.payment_information.payee,
                    method: result.payment_information.method,
                    platform: result.payment_information.platform,
                    tmp_payoneer: result.payment_information.tmp_payoneer
                  },
                  affiliate_flags: result.affiliate_flags
                },
                systemMessage: this.state.systemMessage
              })
            } else if (result["success"] === false) {
              this.logout();
              this.setState({
                dataLoaded: true
              });
            } else {
              this.logout();
              this.setState({
                dataLoaded: true
              });
            }
          }
        ).catch(error => {
          console.log('Error: ', error);
          this.showSystemMessage("error", "Connection error.");
          this.setState({
            dataLoaded: true
          });
        });
    }
  }

  verifyToken = async () => {
    const authtoken = localStorage.getItem('mb-auth-token');
    const affiliateID = localStorage.getItem('mb-aid')
    let isAuth = this.checkAuth();

    /* Get the affiliate object from the DB and populate the state information */

    if (isAuth) {

      return fetch(`${process.env.REACT_APP_API_URL}/application/verifyToken`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-app-token': authtoken
        }
      })
        .then(res => res.json())
        .then(result => {
          if (result["success"] === true) {

            fetch(`${process.env.REACT_APP_API_URL}/affiliate/pageView`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'x-app-token': localStorage.getItem('mb-auth-token')
              },
              body: JSON.stringify({ screen_name: this.props.history.location.pathname, query_string: this.props.history.location.search })
            })
          } else if (result["success"] === false) {
            this.logout();
          } else {
            this.logout();
          }
        }).catch(error => {
          console.log('Error: ', error);
          this.showSystemMessage("error", "Connection error.");
        });

    } else {
      this.logout();
    }

  }

  componentDidMount() {
    // Check to see if localstorage has been set
    // THIS IS SET UPON LOGIN AT api/authenticationApp
    this.fetchAffiliateData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.verifyToken();
    }
  }

  logout = () => {
    const affiliateID = !localStorage.getItem('mb-aid') ? '' : localStorage.getItem('mb-aid');
    const mbToken = !localStorage.getItem('mb-auth-token') ? '' : localStorage.getItem('mb-auth-token');
    const authToken = !localStorage.getItem('mb-t') ? '' : localStorage.getItem('mb-t');

    if (affiliateID !== '' || mbToken !== '' || authToken !== '') {
      localStorage.removeItem('mb-aid');
      localStorage.removeItem('mb-auth-token');
      localStorage.removeItem('mb-t');
      window.location.reload();
    }

  }

  handleNameChange = (name) => {
    this.setState({
      affiliate: { ...this.state.affiliate, name: name },
      systemMessage: this.state.systemMessage
    });
  }

  handleMobileChange = (mobileNum) => {

    this.setState({
      affiliate: { ...this.state.affiliate, mobile_phone: mobileNum },
      systemMessage: this.state.systemMessage
    });
  }

  HandleEmailFlagChange = () => {
    // Remove flag 121 from affiliates_flags array
    // Updating the state here by lifting the state up to remove the flag from App page
    let tempFlagArray = [...this.state.affiliate.affiliate_flags];
    tempFlagArray = tempFlagArray.filter(x => x !== 121);

    this.setState({ affiliate: { ...this.state.affiliate, affiliate_flags: tempFlagArray } });
  }

  handleMaxMoneyChange = (amount, type, message) => {

    this.setState({
      affiliate: { ...this.state.affiliate, maxmoney: amount },
      systemMessage: {
        display: true,
        type: type,
        message: message
      }
    });
  }

  handleGlobalPostbackChange = (type, code, message_type, message) => {
    this.setState({
      affiliate: {
        ...this.state.affiliate,
        global_postback: {
          "type": type,
          "code": code
        }
      },
      systemMessage: {
        display: true,
        type: message_type,
        message: message
      }
    });
  }

  showSystemMessage = (type, message) => {
    // Available types
    // "success", "error"
    this.setState({
      systemMessage: {
        display: true,
        type: type,
        message: message
      }
    });
  }

  hideSystemMessage = () => {
    this.setState({
      systemMessage: {
        display: false,
        type: this.state.systemMessage.type,
        message: this.state.systemMessage.message
      }
    });
  }

  // To reload the App.js manually when needed ('Skip for now' option from OTPsetup.js)

  reloadThePage = () => {
    this.fetchAffiliateData();
  }

  render() {

    return (
      <ScrollToTop>
        <div className="App">

          {this.state.systemMessage.display && (
            <SystemMessage type={this.state.systemMessage.type} hideSystemMessage={this.hideSystemMessage}>
              {this.state.systemMessage.message}
            </SystemMessage>
          )}

          {this.checkAuth() ?

            <Layout
              affiliate={this.state.affiliate} showSystemMessage={this.showSystemMessage}>

              {/* NEED A CONTAINER TO WRAP ALL LAYOUT CHILDREN */}
              {/* Keep Campaigns component here, showing the main part of the page */}
              {/* <Campaigns /> */}
              {/* Create a component called UserStats: to include user details and earning stats */}

              {/* Show announcements only on main/dashboard page */}
              {(this.props.location.pathname === "/" || this.props.location.pathname === "/dashboard") ? <Announcements key={this.state.affiliate.affiliate_id} showSystemMessage={this.showSystemMessage} paymentInformation={this.state.affiliate.payment_information} affiliateFlags={this.state.affiliate.affiliate_flags} phone={this.state.affiliate.phone} mobilePhone={this.state.affiliate.mobile_phone} /> : null}

              <Switch>
                {/* Adding a question mark after the param means it's optional. The component will still mount without it */}
                <Route path="/browse/:tab?" render={(props) => <Browse key={props.match.params.tab} tab={props.match.params.tab} history={props.history} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/campaign/:oid" render={(props) => <CampaignPage key={props.match.params.oid} affiliate_id={this.state.affiliate.affiliate_id} campaign_id={props.match.params.oid} location={props.location} globalPostback={this.state.affiliate.global_postback} flags={this.state.affiliate.affiliate_flags} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/search" render={(props) => <Search key={props.location.search} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/settings" render={() => <Settings editName={this.handleNameChange}
                  editMobile={this.handleMobileChange}
                  showSystemMessage={this.showSystemMessage}
                  removeInvalidEmailFlag={this.HandleEmailFlagChange} />} />
                <Route path="/accountbalance" render={(props) => <AccountBalance showSystemMessage={this.showSystemMessage} />} />
                <Route path="/globalpostback" render={() => <GlobalPostback showSystemMessage={this.showSystemMessage} globalPostback={this.state.affiliate.global_postback} editGlobalPostback={this.handleGlobalPostbackChange} />} />
                <Route path="/maxmoney" render={() => <MaxMoney affiliate={this.state.affiliate} updateMaxMoney={this.handleMaxMoneyChange} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/reports/:type?" render={(props) => <Reports key={`${props.match.params.type}/${props.location.search}`} type={props.match.params.type} location={props.location} history={props.history} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/resources" render={() => <Resources showSystemMessage={this.showSystemMessage} />} />
                <Route path="/refer" render={() => <Refer affiliate={this.state.affiliate} />} />
                <Route path="/faq" render={() => <Faq />} />
                <Route path="/terms" render={() => <Terms />} />
                <Route path="/dashboard" render={() => <Dashboard affiliate={this.state.affiliate} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/paycompletion" render={() => <PayCompletion />} />
                <Route path="/" render={() => <Redirect to={{ pathname: "/dashboard" }} />} />
              </Switch>
              <Footer />
            </Layout>

            :

            this.state.dataLoaded ?
              <Switch>
                <Route path="/register" render={(props) => <Register location={props.location} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/forgot" render={() => <ForgotPassword showSystemMessage={this.showSystemMessage} />} />
                <Route path="/resetPassword" render={(props) => <ResetPassword location={props.location} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/verify" render={(props) => <Verify location={props.location} showSystemMessage={this.showSystemMessage} />} />
                <Route path="/ottlogin" render={() => <OttLogin />} />
                <Route path="/terms" render={() => <Terms />} />
                {/* <Route path="/otpCheck" render={() => <OTPcheck showSystemMessage={this.showSystemMessage} />} />
                <Route path="/otpSetup" render={() => <OTPsetup showSystemMessage={this.showSystemMessage} />} /> */}
                <Route path="/" render={() => <Login showSystemMessage={this.showSystemMessage} pageReloaded={this.reloadThePage} />} />
              </Switch>

              :
              <Route path="/*" component={LoadingPage} />
          }

        </div>
      </ScrollToTop>
    );
  }
}


export default withRouter(App);


// A container DashboardIndex containing UserStats, Sliders and DashboardOffers components revealing
// Another container with individual campaign information to replace the previous container when clicked on any offer