import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

const ReportHeader = (props) => {

    const { sortBy, title, alignment, handleSort, sort } = props;
    let classes = "sortable";
    classes = (alignment === "right") ? classes + " text-right" : classes;

    function sortChevron(currentSort){
        let chevron;
        if (sort.column === currentSort){
            chevron = (sort.order === 'asc') ? <ChevronUp size={20} /> : <ChevronDown size={20} />
        }
        return chevron;
    }

    return ( 
        <th scope="col" onClick={() => handleSort(sortBy)} className={classes}>{title} {sortChevron(sortBy)}</th>
    );
}
 
export default ReportHeader;