import React, { Component } from 'react';
import { Unlock } from 'react-feather';

class AffiliateCampaignStatus extends Component {

    getStatusElement = () => {

        const { affiliate_campaign_status } = this.props.data.details;

        if (this.props.mode === 'button'){
            if (affiliate_campaign_status === 'Request approval to run'){
                return <button className="btn btn-warning" onClick={this.props.toggleModal}><Unlock size={20} /> Request approval to run</button>
            } else if (affiliate_campaign_status === 'You have requested the maximum number of by request campaigns. New requests are not allowed until your existing requests have been reviewed and processed.'){
                return <div className="alert alert-warning text-left">You have requested the maximum number of by request campaigns. New requests are not allowed until your existing requests have been reviewed and processed.</div>
            } else {
                return affiliate_campaign_status;
            }
        } else {
            return affiliate_campaign_status;
        }

    }

    render() {
        return (
            <React.Fragment>
                {this.getStatusElement()}
            </React.Fragment>
        );
    }
}


export default AffiliateCampaignStatus;