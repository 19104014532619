import React, { Component } from 'react';
import Waypoint from 'react-waypoint';

import CampaignSet from '../../../component/CampaignSet/CampaignSet';

class TrendingCampaigns extends Component {

    state = {
        dataLoaded: false,
        firstPage: true,
        page: 0
    }

    loadCampaigns = (theCategory, thePage, theLimit) => {

        if (this.state.firstPage) {
            this.setState({
                dataLoaded: true,
                firstPage: false,
                category: theCategory,
                page: thePage,
                limit: theLimit
            })
        } else {
            this.setState({
                category: theCategory,
                page: thePage + 1,
                limit: theLimit
            })
        }

    }

    render() {
        return (
            <React.Fragment>
                {this.state.dataLoaded &&
                    <CampaignSet category={this.state.category} page={this.state.page} limit={this.state.limit} showSystemMessage={this.props.showSystemMessage} 
                        view={this.props.view}/>
                }
                <Waypoint
                    onEnter={() => this.loadCampaigns(this.props.category, this.state.page, this.props.limit)} />
            </React.Fragment>
        );
    }
}

export default TrendingCampaigns;