import React, { Component } from 'react';
import ReportDropdown from '../ReportDropdown/ReportDropdown';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Link } from 'react-router-dom';
import Loading from '../../../component/UI/Loading/Loading';
import { clearLocalStorageAndReload, verifyToken } from '../../../assets/JS/functions';

import moment from 'moment';
import _ from 'lodash';

class ReportEarnings extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        report: [],
        totals: {},
        sort: {
            column: 'earnings',
            order: 'desc'
        }
    }

    componentDidMount() {

        this._isMounted = true;

        this.setState({
            dataLoaded: false
        });

        const mbToken = localStorage.getItem('mb-auth-token');

        let realEndDate = moment(this.props.endDate).add(1, "d").format('YYYY-MM-DD HH:mm:ss');

        /* Get the affiliate object from the DB and populate the state information */
        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/reports/earnings?startDate=${this.props.startDate}&endDate=${realEndDate}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                }).then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                this.props.handleDownloadData(result);

                                if (this._isMounted) {
                                    this.setState({
                                        dataLoaded: true,
                                        report: result.report,
                                        totals: result.totals
                                    });
                                }
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportEarnings: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    handleSort = (sortBy) => {
        const sort = { ...this.state.sort };

        if (sort.column === sortBy) {
            sort.order = (sort.order === 'asc') ? 'desc' : 'asc';
        } else {
            sort.column = sortBy;
            switch (sort.column) {
                case 'name':
                    sort.order = 'asc'
                    break;
                case 'clicks':
                    sort.order = 'desc'
                    break;
                case 'leads':
                    sort.order = 'desc'
                    break;
                case 'conversion':
                    sort.order = 'desc'
                    break;
                case 'sales':
                    sort.order = 'desc'
                    break;
                case 'earnings':
                    sort.order = 'desc'
                    break;
                case 'epc':
                    sort.order = 'desc'
                    break;
                default:
                    throw new Error("Invalid sorting method");
            }

        }

        const sorted = _.orderBy(this.state.report, sortBy, sort.order)
        if (this._isMounted) {
            this.setState({
                report: sorted,
                sort: sort
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");

        return (
            <table className="table table-striped table-card table-hover">
                <thead>
                    <tr>
                        <th scope="col">#ID</th>
                        <ReportHeader sortBy="name" title="Campaign" sort={this.state.sort} handleSort={this.handleSort} />
                        <ReportHeader sortBy="clicks" title="Clicks" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        <ReportHeader sortBy="leads" title="Leads" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        <ReportHeader sortBy="conversion" title="CR (%)" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        <ReportHeader sortBy="sales" title="Sales" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        <ReportHeader sortBy="earnings" title="Earnings" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        <ReportHeader sortBy="epc" title="EPC" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                        <th scope="col" className="text-right">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.dataLoaded ?
                        <React.Fragment>
                            {this.state.report.length === 0 ?
                                <tr>
                                    <td colSpan="10">No stats for this date range.</td>
                                </tr>
                                :
                                <React.Fragment>

                                    {this.state.report.map(item => (
                                        <tr key={item.campaign_id}>
                                            <td>{item.campaign_id}</td>
                                            <td><Link to={`/campaign/${item.campaign_id}`}>{item.name}</Link></td>
                                            <td align="right"><Link to={`/reports/clicks?oid=${item.campaign_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>{Intl.NumberFormat('en-GB').format(item.clicks)}</Link></td>
                                            <td align="right"><Link to={`/reports/conversions?oid=${item.campaign_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>{Intl.NumberFormat('en-GB').format(item.leads)}</Link></td>
                                            <td align="right">{parseFloat(item.conversion).toFixed(2)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.earnings)}</td>
                                            <td align="right">${parseFloat(item.epc).toFixed(2)}</td>
                                            <td className="pr-2"><ReportDropdown offerId={item.campaign_id} startDate={formattedStartDate} endDate={formattedEndDate} /></td>
                                        </tr>
                                    ))}

                                    <tr className="font-weight-bold">
                                        <td colSpan="2">Totals</td>
                                        <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.clicks)}</td>
                                        <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.leads)}</td>
                                        <td align="right">{parseFloat(this.state.totals.conversion).toFixed(2)}</td>
                                        <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.sales)}</td>
                                        <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.earnings)}</td>
                                        <td align="right">${parseFloat(this.state.totals.epc).toFixed(2)}</td>
                                        <td align="right">&nbsp;</td>
                                    </tr>

                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <tr>
                            <td colSpan="10"><Loading /></td>
                        </tr>
                    }

                </tbody>
            </table>
        );
    }
}

export default ReportEarnings;