import React, { Component } from 'react';
import Loading from '../../../component/UI/Loading/Loading';
import { clearLocalStorageAndReload, verifyToken } from '../../../assets/JS/functions';
import moment from 'moment';

class ReportReferrals extends Component {

    state = {
        dataLoaded: false,
        report: [],
        campaign: []
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        });

        const mbToken = localStorage.getItem('mb-auth-token');

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/referrals`

        /* Get the affiliate object from the DB and populate the state information */
        verifyToken().then(result => {
            if (result === true) {
                fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                // Update the current report with the data.
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report
                                })
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    render() {

        return (
            <table className="table table-striped table-card table-hover">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Affiliates</th>
                        <th scope="col" className="text-right">Referral Income</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.dataLoaded ?
                        <React.Fragment>
                            {this.state.report.length === 0 ?
                                <tr>
                                    <td colSpan="3">No stats for this date range.</td>
                                </tr>
                                :
                                <React.Fragment>

                                    {this.state.report.map(item => (
                                        <tr>
                                            <td>{moment.utc(item.referral_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            <td>{item.affiliates}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.referral_income)}</td>
                                        </tr>
                                    ))}

                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <tr>
                            <td colSpan="10"><Loading /></td>
                        </tr>
                    }

                </tbody>
            </table>
        );
    }
}

export default ReportReferrals;