import React, { Component } from 'react';

class Faq extends Component {
    state = {}
    render() {
        return (
            <div className="py-2">
                <div className="split-left">
                    <div className="card">
                        <div className="card-header d-flex align-items-center">
                            FAQ
                        </div>
                        <div className="card-body">
                            <h5>GENERAL</h5>
                            <p className="font-weight-bold">What role does MaxBounty.com play in online advertising?</p>
                            <p>Affiliates seek to generate advertising revenue from their websites and mailings lists but often lack in time or size to deal directly with multiple large sponsors. On the flipside, advertisers want traffic to generate sales and leads for their websites without the commitment of dealing with hundreds of individual affiliates. MaxBounty.com acts as a single point of contact for advertisers and affiliates, acting as a trusted source for both sides. Affiliates benefit from having access to thousands of advertising campaigns and receiving a single aggregated "always on time" commission payment from MaxBounty. Advertisers benefit by having their CPA campaigns made available to thousands of affiliates without having to recruit or maintain a single one.</p>

                            <p className="font-weight-bold">What is CPA Marketing?</p>
                            <p>CPA stands for Cost Per Action. Unlike other marketing models where an advertiser pays for every user seeing their ad (CPM) or clicking on their ad (CPC), an advertiser under the CPA model pays only when a specific action is taken on their website. The advertiser defines this action in advance and only pays when that action is taken. The advertiser action can be as simple as filling out a form on their website or as complex as a multi-page sign-up ending with a purchase through credit card. Generally speaking, the more involved the action, the higher an affiliate would expect to be paid for generating it. </p>

                            <h5>AFFILIATE APPLICATIONS</h5>

                            <p className="font-weight-bold">Does MaxBounty accept international affiliates?</p>
                            <p>We accept affiliates from the majority of countries globally. </p>

                            <p className="font-weight-bold">Will I be approved if I don't have a website?</p>
                            <p>You don’t need a website to promote our campaigns, but you will need to outline on our application and to your new affiliate manager exactly how you will be promoting. We allow many traffic sources, but there are some that are not permitted. </p>

                            <p className="font-weight-bold">When are affiliates paid?</p>
                            <p>Affiliates are initially paid on a monthly net 15 basis. For example, affiliates generating revenue in January will receive their payments on February 15th. Once an affiliate has received their first payment from us, they are automatically placed on a weekly payment schedule if they are receiving their payments electronically. We require a minimum balance of $100 before we issue a payment. If you don't reach this level, your earnings will roll over to the subsequent pay period until you reach the minimum $100.</p>

                            <p className="font-weight-bold">What about weekly payments?</p>
                            <p>Affiliates who have earned at least one payment from us and who are paid electronically are automatically moved to weekly payments.</p>

                            <p className="font-weight-bold">How are affiliates paid?</p>
                            <p>We pay affiliates via Payoneer and Tipalti payment platforms and within each they offer a range of specific payment options like Check, Intercash, eCheck, ACH, PayPal and Wire.  Options available depend on an affiliate's home country.</p>

                            <p className="font-weight-bold">What is incentive traffic and is it allowed?</p>
                            <p>Incentive traffic is when an affiliate offers a user a reward of some kind (virtual or real) to sign up to an advertiser through their affiliate link. A potential affiliate must understand that while MaxBounty does allow some forms of incentive traffic, affiliates using incentive traffic will be limited to advertising only the few ad campaigns which accept it. Most campaigns do not accept incentive traffic. We're very selective on the kinds of incentive traffic we accept and very strict on incentive affiliate adherence to only advertising the campaigns which allow it.</p>

                            <p className="font-weight-bold">Does MaxBounty accept content unlock traffic?</p>
                            <p>Yes, we generally accept this kind of traffic but only on campaigns which accept incentive traffic. You are required to own or have the right to distribute the content which is being unlocked. It should be not be pornographic in nature and is subject to the same <a href="https://maxbounty.com/terms.cfm" target="_blank" rel="noopener noreferrer">terms and conditions</a> an affiliate's website is subject to.</p>

                            <h5>ACTIVE AFFILIATES</h5>
                            <p className="font-weight-bold">What's EPC?</p>
                            <p>EPC stands for Earnings Per Click and is displayed on all network campaigns. It's a computed average income affiliates are earnings on a given campaign calculated on a per-click basis. This number isn't a guarantee of how much an affiliate will earn by advertising a campaign but is simply a tool for affiliates to gauge their potential interest in advertising a campaign and measuring their conversion compared to the average across the network.</p>

                            <p className="font-weight-bold">Does MaxBounty have an affiliate referral program?</p>
                            <p>Yes. Affiliates can refer other affiliates and receive a 5% bounty on their earnings for the first 12 months of their activity with the network. The potential referred affiliate must be new to MaxBounty and not be another advertising network.</p>

                            <p className="font-weight-bold">What can I do if I lost my password?</p>
                            <p>Visit <a href="https://maxbounty.com/forgot.cfm">this page</a> to reclaim a lost password.</p>

                            <p className="font-weight-bold">I want to run a campaign which says 'incentive', but I don't have an incentive website.  Can I still advertise it?</p>
                            <p>A campaign indicating it accepts incentives will also accept non-incentive traffic, so you would be fine to advertise it. We're simply trying to display which campaigns are OK for incentive affiliates to advertise. If there are two versions of a campaign, a non-incentive affiliate would be best to advertise the version which doesn't specify incentive traffic allowed.</p>

                            <p className="font-weight-bold">Are leads/sales tracked in real time or delayed?</p>
                            <p>In most cases, leads and sales are tracked in real time, but there can be up to a 5-minute delay for our server to refresh affiliate statistics. There are some advertising campaigns where a sale/lead is only declared days after the user signed up. In such circumstances, this will be disclosed in the ad campaign description.</p>

                            <p className="font-weight-bold">Can I sign up to campaigns myself or get my friends to sign up?</p>
                            <p>No, it's against the <a href="https://maxbounty.com/terms.cfm" target="_blank" rel="noopener noreferrer">terms and conditions</a> which govern an affiliate account for an affiliate to generate his own leads or get his friends to sign up for him.    </p>

                            <p className="font-weight-bold">Why is it I'm not seeing any leads/sale generated on a campaign when I know leads/sales must have been generated?</p>
                            <p>There are a number of reasons why this could happen. These include, but are not limited to users visiting a campaign page through another affiliate link, users entering invalid data, users not fully completing the signup, user data not matching campaign demographic requirements, and surfers having cookies disabled on their computer. Generally speaking, most cases of untracked test or friend-generated leads are explained by an affiliate simply unfamiliar with all the details an advertiser requires to validate a lead as real.</p>

                            <p className="font-weight-bold">Can I use Google/Facebook/Youtube/etc as a traffic source?</p>
                            <p>Affiliates can purchase approved ad placements from third party traffic sources. Each campaign on MaxBounty has specific rules as to whether a given traffic source is allowed or not. For example, it's not uncommon for some advertisers to request they not receive social network traffic, to which affiliates must comply. Affiliates are responsible for the nature and quality of the advertising they bring into the network from third parties. Affiliates must ensure they are following the <a href="https://maxbounty.com/terms.cfm" target="_blank" rel="noopener noreferrer">terms of service</a> and advertising guidelines of the websites from where they are acquiring traffic.</p>

                            <p className="font-weight-bold">Why do my paid clicks stats from Google/Bing/Facebook/etc. not match the number of clicks showing in MaxBounty? </p>
                            <p>Click tracking between two sources never matches 100%. Some of the clicks an affiliate purchases from a third party might get IP filtered because they are from a country the campaign doesn't accept. Even if you're buying specific country-targeted traffic to send to a campaign accepting traffic from that same country, rarely does the logic behind two IP filters match 100%. It could also be that the surfer abandons the browser before a click is tracked on both sides. A small discrepancy in click stats is expected and normal.</p>

                            {/* <p className="font-weight-bold">What's the difference between scrubbing and shaving?</p>
                            <p>Scrubbing is when an advertiser doesn't pay for invalid or duplicate leads. For example, while an affiliate might have generated 100 raw sign ups to a campaign, 10 were already in the advertiser's database and 10 more contained bad data.    So out of those 100 sign ups, 80 were valid leads and 20 were scrubbed.   Shaving is when an advertiser doesn't pay for leads which would otherwise be valid but is attempting to increase their profit on a campaign.     MaxBounty does not accept advertiser lead shaving. </p> */}

                            {/* <p className="font-weight-bold">Does MaxBounty shave or scrub leads?</p>
                            <p>No, we do not. MaxBounty is completely dependent on an advertiser identifying when a lead/sale is valid.</p> */}

                            <p className="font-weight-bold">Can I rebroker MaxBounty campaigns to other affiliates?</p>
                            <p>As a rule, MaxBounty does not allow its affiliates to rebroker our offers to other affiliates or list them publicly on any form of Network. Any deviation from this unless agreed upon with MaxBounty beforehand, could result in termination of your account and commissions not being issued.</p>

                            {/* <p className="font-weight-bold">Where can I find the MaxBounty API?</p>
                            <p>The API can be found <a href="maxbountyapi.doc">here</a>.</p> */}

                            <h5>AFFILIATE TERMINATION AND PROBLEMS</h5>
                            <p className="font-weight-bold">I'm not using my affiliate account.  Can I sell or transfer it?</p>
                            <p>No, you may not. It is grounds for account termination or worse. Only people interested in acquiring dormant affiliate accounts do so with the intent of defrauding advertisers. If an affiliate sells their account, they're likely assisting someone in committing a crime and are subject to potential litigation. MaxBounty fully supports advertisers and law enforcement agencies in their attempts to prosecute fraud. If you don't want your affiliate account anymore, simply tell your affiliate manager and we'll close it for you.</p>

                            <p className="font-weight-bold">Can I do a joint venture (JV) where I allow someone else access to my account for a share of the profits they generate?</p>
                            <p>No, this is directly against MaxBounty's affiliate <a href="https://maxbounty.com/terms.cfm" target="_blank" rel="noopener noreferrer">terms and conditions</a>.   Just like selling your affiliate account, the bulk of those interested in joint ventures do so with intent of defrauding advertisers. An affiliate is solely responsible for the leads generated on a campaign. Should the second party in the joint venture generate fraudulent leads/sales, it's the affiliate who risks account termination and litigation, not the second party.</p>

                            <p className="font-weight-bold">My affiliate account was terminated but I want to plead my case.  What should I do?</p>
                            <p>In most cases, by the time you've received the termination email, it's too late. Our compliance department monitors traffic/leads constantly and action is swift when a problem is found. Affiliates are generally told when a minor problem is found and given the opportunity to make corrections. Major problems usually result in immediate account termination. If you want to plead your case, send a reply to the compliance email you received. Your best bet is to admit wrongdoing, accept the reversal of your leads/sales and ask permission to try again. In the rare circumstance that an affiliate is wrongly terminated from the network, they should reply to the compliance email and politely describe the problem and why they feel they are innocent.  </p>

                            <p className="font-weight-bold">My account was terminated but only a portion of my leads were fraudulently generated.   Will I get paid?</p>
                            <p>Partial fraud doesn't equate to partial payment. No payment will be issued to a terminated account.</p>

                            <h5>ADVERTISER</h5>

                            <p className="font-weight-bold">Who will run my campaign, where will the traffic come from?</p>

                            <p>MaxBounty has over 25,000 affiliates from across the globe in the network. Affiliates are able to choose campaigns that they are interested in promoting based on the fit with their audience or traffic type. MaxBounty Affiliate Managers will guide affiliates towards campaigns that would be suitable for them to promote.</p>

                            <p className="font-weight-bold">What if I don’t want certain traffic types used to promote my campaign? </p>

                            <p>Advertisers can however specify in advance whether they want to exclude certain types of traffic for their campaign. For example: ‘No paid search engine traffic,’ or ‘No social media traffic.’ </p>

                            <p className="font-weight-bold">Do I get contact with the affiliates running my ads?</p>
                            <p>There is no direct contact between affiliates and advertisers. All interaction goes through the network, as you will have a dedicated Account Manager as your day-to-day point of contact. Affiliates come to MaxBounty specifically for the benefit of having a single contact point for multiple promotional opportunities. </p>

                            <p className="font-weight-bold">How does tracking work?</p>
                            <p>MaxBounty uses postbacks to track actions. We provide the advertiser with a postback URL to place in their tracking platform, or directly to their website’s backend. When the user clicks through and completes the action, the postback calls back to our server to declare the action is valid. The server call credits the correct affiliate and correct campaign for the action generated.</p>

                            <p className="font-weight-bold">How much volume can you do on my campaign?</p>
                            <p>MaxBounty can’t predict exact traffic volumes on potential campaigns. There are many factors that must be considered - much depends on the conversion of the campaign, the target countries and demographics, the decided upon payout versus competitive campaigns, and the mix of affiliates who are actively promoting the campaign. Some campaigns can see hundreds of thousands of clicks and leads, while others might be promoted on a smaller scale. </p>

                            <p className="font-weight-bold">What can we do to ensure we get quality leads?</p>
                            <p>MaxBounty puts potential affiliates through an identity screening, and quality assurance and fraud checks before we allow them into the network. The internal Compliance Team also monitors traffic daily for spikes and trends in lead activity. This helps reduce the occurrence of fraud and bad leads.
                            <br /> <br />
                                However, Advertisers must also work at monitoring data and reporting problems as needed. MaxBounty does not have insight into the lead form data captured by the advertiser, or how conversions are monetizing for the advertiser.  Advertisers are passed the IDs of the affiliates generating traffic for their campaign. Advertisers should use this ID with their internal quality checks and reporting to analyze the profitability of the traffic they are receiving on an individual affiliate ID level. MaxBounty can help optimize the campaign with the Advertiser, provided there is the timely and accurate sharing of feedback.</p>

                            <p className="font-weight-bold">How do I find out if MaxBounty will take me on as an advertiser? </p>
                            <p>Send us some details about the campaign you'd like to bring to MaxBounty through the contact us page. Please include as much information as possible about your campaign including sample landing pages, rates or payouts, target countries and budgetary caps. We receive many requests so please do not omit this information. </p>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Faq;