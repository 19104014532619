import React, { Component } from 'react';
import { ChevronDown } from 'react-feather';
import { FaCheckCircle } from 'react-icons/fa';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import mbModalBackgroundImage from '../../../assets/images/maxbountytv-hero.jpg';
import mbModalLogo from '../../../assets/images/maxbounty_logo.jpg';
import CountryList from '../../../data/countrylist';

import './IntroModal.css';

class IntroModal extends Component {

    /*
    1. Hover effect on dropdown elements
    2. Change none selected to the list of selected elements
    3. Keep the selected items as it is when close and reopen the dropdown
    */

    state = {
        verticalList: [],           // Vertical options fetched from API
        showSurveySuccess: false,
        selectedCountries: [],      // Contains selected target countries
        selectedCountryCodes: [],
        selectedVerticals: [],      // Contains selected vertical countries
        selectedVerticalIds: [],
        countryDropdownOpen: false,
        verticalsDropdownOpen: false
    }

    countryBoxClasses = "form-check form-group";
    verticalBoxClasses = "form-check form-group";

    componentDidMount() {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/introSurvey/getVerticalList`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({
                        verticalList: result["vertical_list"]
                    });
                } else if (result["success"] === false) {
                    this.props.showSystemMessage("error", "An error occurred while fetching verticals.");
                } else {
                    this.props.showSystemMessage("error", result.errors.message);
                }
            }).catch(error => {
                this.props.showSystemMessage("error", error.message);
            });
    }

    surveySubmitHandler = () => {
        // probably sending the endpoint to store to db at somepoint
        // Add some validation for the form
        if (this.state.selectedCountryCodes.length !== 0 && this.state.selectedVerticalIds.length !== 0) {

            // country and targets not empty, at this point
            const authtoken = localStorage.getItem('mb-auth-token');

            let data = {
                countries: this.state.selectedCountryCodes,
                verticals: this.state.selectedVerticalIds
            };

            fetch(`${process.env.REACT_APP_API_URL}/introSurvey/saveTargets`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(result => {
                    // success -> show success Modal  this.setState({ showSurveySuccess: true });
                    // Failure -> Show error message 
                    // error -> Indication
                    if (result["success"] === true) {
                        this.setState({ showSurveySuccess: true }, () => {
                            this.props.showSystemMessage("success", result.message);
                        });
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", result.errors.message);
                    } else {
                        this.props.showSystemMessage("error", "An internal error occurred.");
                    }
                })
                .catch(error => {
                    this.props.showSystemMessage('error', error.message);
                });
        } else {
            // Show visual errors here
            this.countryBoxClasses = this.state.selectedCountryCodes.length === 0 ? "no-values-selected form-group form-check" : "form-check form-group";
            this.verticalBoxClasses = this.state.selectedVerticalIds.length === 0 ? "no-values-selected form-group form-check" : "form-check form-group";

            this.setState({ showSurveySuccess: false });
        }
    }

    countryDropdownToggle = () => {
        this.setState({ countryDropdownOpen: !this.state.countryDropdownOpen });
    }

    verticalsDropdownToggle = () => {
        this.setState({ verticalsDropdownOpen: !this.state.verticalsDropdownOpen });
    }

    countrySelectHandler = (event) => {
        let checked = event.target.checked;
        let selectedCountries = [...this.state.selectedCountries];
        let selectedCountryCodes = [...this.state.selectedCountryCodes];

        if (checked) {
            selectedCountries.push({ "country_code": event.target.value, "country_name": event.target.name });
            selectedCountryCodes.push(event.target.value);
        } else {
            selectedCountries = selectedCountries.filter(country => event.target.value !== country.country_code);
            selectedCountryCodes = selectedCountryCodes.filter(code => event.target.value !== code);
        }
        this.setState({ selectedCountries, selectedCountryCodes });
    }

    verticalSelectHandler = (event) => {
        let checked = event.target.checked;
        let selectedVerticals = [...this.state.selectedVerticals];
        let selectedVerticalIds = [...this.state.selectedVerticalIds];

        if (checked) {
            selectedVerticals.push({ "id": event.target.value, "vertical_name": event.target.name });
            selectedVerticalIds.push(parseInt(event.target.value));
        } else {
            selectedVerticals = selectedVerticals.filter(vertical => event.target.value !== vertical.id);
            selectedVerticalIds = selectedVerticalIds.filter(id => parseInt(event.target.value) !== id);
        }
        this.setState({ selectedVerticals, selectedVerticalIds });
    }

    render() {
        let successClass = "d-none";
        let surveyClass = "d-block";

        if (this.state.showSurveySuccess === true) {
            successClass = "d-block";
            surveyClass = "d-none";
        }

        // Update the country button text as per selection
        let countryButtonText = "";

        if (this.state.selectedCountries.length < 1) {
            countryButtonText = 'Select countries';
        } else {
            if (this.state.selectedCountries.length >= 4) {
                countryButtonText = `${this.state.selectedCountries.length} selected`;
            } else {
                this.state.selectedCountries.forEach(country => {
                    countryButtonText += country.country_name + ", ";
                });
            }
        }

        // Update verticals button text as per selection
        let verticalsButtonText = "";

        if (this.state.selectedVerticals.length < 1) {
            verticalsButtonText = 'Select verticals';
        } else {
            if (this.state.selectedVerticals.length >= 3) {
                verticalsButtonText = `${this.state.selectedVerticals.length} selected`;
            } else {
                this.state.selectedVerticals.forEach(vertical => {
                    verticalsButtonText += vertical.vertical_name + ", ";
                });
            }
        }

        return (
            <React.Fragment>
                <div className="modal-body IntroModal" style={{ padding: "0px", background: "white", borderRadius: "5px", textAlign: "center" }}>
                    <div style={{ backgroundImage: `URL(${mbModalBackgroundImage})`, backgroundSize: "cover", padding: "25px", textAlign: "center" }}>
                        <img width="150px" src={mbModalLogo} alt="Maxbounty Logo" />
                    </div>
                    <div className={surveyClass}>
                        <div style={{ padding: "25px", textAlign: "center", margin: "auto" }}>
                            <h4>Welcome to Maxbounty</h4>
                            <p>Let us know a bit about yourself so that we can better tailor your experience.</p>
                        </div>
                        <form>
                            <div className={this.countryBoxClasses} style={{ padding: "10px", margin: "15px", borderRadius: "3px", border: "1px solid #337ab7" }}>
                                <label htmlFor="form_q1" style={{ fontWeight: "normal", display: "block" }}>Which geographical areas will you be targeting?</label>

                                <Dropdown isOpen={this.state.countryDropdownOpen}
                                    toggle={this.countryDropdownToggle}>
                                    <DropdownToggle
                                        data-toggle="dropdown"
                                        aria-expanded={this.state.countryDropdownOpen}
                                        className="dropdown-toggle"
                                    >
                                        {countryButtonText} <ChevronDown size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div className="pl-4">
                                            {CountryList.countries.map(country => <div className="dropdown-label" key={country.code}>
                                                <input type="checkbox" value={country.code}
                                                    onChange={this.countrySelectHandler}
                                                    name={country.name} id={country.code}
                                                    checked={this.state.selectedCountryCodes.includes(country.code)}
                                                />
                                                <label className="dropdown-label pl-2" htmlFor={country.code}>
                                                    {country.name}
                                                </label>
                                            </div>
                                            )}
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                            <div className={this.verticalBoxClasses} style={{ padding: "10px", margin: "15px", borderRadius: "3px", border: "1px solid #337ab7" }}>
                                <label htmlFor="form_q1" style={{ fontWeight: "normal", display: "block" }}>Which verticals will you be focussing on?</label>
                                <Dropdown isOpen={this.state.verticalsDropdownOpen}
                                    toggle={this.verticalsDropdownToggle}>
                                    <DropdownToggle
                                        data-toggle="dropdown"
                                        aria-expanded={this.state.verticalsDropdownOpen}
                                        className="dropdown-toggle">
                                        {verticalsButtonText} <ChevronDown size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div className="pl-4">
                                            {this.state.verticalList.map(vertical =>
                                                <div className="dropdown-label" key={vertical.subcategory_id}>
                                                    <input type="checkbox" value={vertical.subcategory_id}
                                                        onChange={this.verticalSelectHandler}
                                                        id={vertical.subcategory_id}
                                                        name={`${vertical.category_name}- ${vertical.subcategory_name}`}
                                                        checked={this.state.selectedVerticalIds.includes(vertical.subcategory_id)}
                                                    />
                                                    <label className="dropdown-label pl-2" htmlFor={vertical.subcategory_id}>
                                                        {vertical.category_name} - {vertical.subcategory_name}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <button type="button" className="btn btn-primary btn-sm mb-3" onClick={this.surveySubmitHandler}>Get Started</button>
                        </form>
                    </div>
                    <div style={{ padding: "15px" }} className={successClass}>
                        <div className="survey-message-success">
                            <FaCheckCircle />
                            <span className="survey-message-text">Thank you for choosing MaxBounty!</span>
                        </div>
                        <button type="button" className="btn btn-sm btn-primary mb-3" onClick={this.props.modalClosed}>Close</button>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default IntroModal;