import React from 'react';

import './ResourceCardEmpty.css';

const resourceCardEmpty = () => (
    <div className="Resourcecard-empty">

    </div>
);

export default resourceCardEmpty;