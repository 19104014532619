import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Bell } from 'react-feather';
import moment from 'moment';

import './NewsDropdown.css';

class NewsDropdown extends Component {

    // newsItems will be fetched here from db/server
    // map through them each and wrap a single newsItem in it
    // All will go as in dropdown item
    state = {
        dropdownOpen: false,
        newsItems: [],
        emptyNewsMessage: ''
    };

    componentDidMount() {

        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/news`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({
                        newsItems: result["news_updates"]
                    });
                } else if (result["success"] === false) {
                    this.setState({ emptyNewsMessage: result.errors.message });
                }
            }).catch(error => {
                console.log('Error ', error.message);
            });
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    getFlair = (itemId) => {

        var currentItem = this.state.newsItems.find(obj => {
            return obj.id === itemId;
        });

        let flair = '';

        if (currentItem.type === 'alert') {
            flair = `<div class="badge badge-danger font-weight-bold">Alert</div><br>`;
        } else if (currentItem.type === 'reminder') {
            flair = `<div class="badge badge-info font-weight-bold">Reminder</div><br>`;
        }

        return flair;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState !== this.state;
    }

    render() {

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>

                <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    className="dropdown-toggle">
                    <Bell className="feather-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" />
                </DropdownToggle>

                {this.state.emptyNewsMessage
                    ?
                    <DropdownMenu className="news-dropdown-menu">
                        <DropdownItem header className="font-weight-bold text-dark">News</DropdownItem>
                        <DropdownItem divider className="m-0" />
                        <DropdownItem className="dropdown-news-item">
                            {this.state.emptyNewsMessage}
                        </DropdownItem>
                    </DropdownMenu>

                    :

                    <DropdownMenu className="news-dropdown-menu">
                        <DropdownItem header className="font-weight-bold text-dark">News & Updates</DropdownItem>

                        {this.state.newsItems.map(item => {

                            let formattedDate = "<b>" + moment(item.news_date).format("YYYY/MM/DD") + "</b>";
                            return (
                                <React.Fragment key={item.key_id}>
                                    <DropdownItem divider className="m-0" />
                                    {/* <DropdownItem key={item.id} tag="div" className="dropdown-news-item" dangerouslySetInnerHTML={{ __html: this.getFlair(item.id) + item.text }}></DropdownItem> */}
                                    <DropdownItem key={item.id} tag="div" className="dropdown-news-item"
                                        dangerouslySetInnerHTML={{ __html: formattedDate + "<br />" + item.news }}

                                    ></DropdownItem>
                                </React.Fragment>
                            )
                        })}

                    </DropdownMenu>}
            </Dropdown>
        );
    }
}

export default NewsDropdown;