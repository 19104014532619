import React, { Component } from 'react';
import HeaderTitle from '../../component/UI/HeaderTitle/HeaderTitle';

import _ from 'lodash';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';
import './Resources.css';
import Loading from '../../component/UI/Loading/Loading';
import ResourceCard from './ResourceCard/ResourceCard';
import ResourceCardEmpty from './ResourceCardEmpty/ResourceCardEmpty';

class Resources extends Component {

    state = {
        dataLoaded: false,
        resources: [],
        resource_categories: []
    }

    componentDidMount() {

        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/resources`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {

                                const resource_categories = _.map(_.uniqBy(result.resources, 'resource_category_id'), (item) => {
                                    return { id: item.resource_category_id, name: item.category_name, key_id: item.key_id }
                                })

                                this.setState({
                                    dataLoaded: true,
                                    resource_categories: resource_categories,
                                    resources: result.resources
                                });

                            } else if (result["success"] === false) {
                                this.props.showSystemMessage("error", result.errors.message);
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('error: ', error);
            this.props.showSystemMessage('error', "There was an error fetching the resources.");
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="seamless-header d-flex align-items-center">
                    <HeaderTitle title="Resources" />
                    <div className="ml-3 d-none d-sm-block">Check this page often for an updated list of affiliate resources.</div>
                </div>
                {this.state.dataLoaded === true ?
                    <div className="mt-2 text-left d-flex">
                        <div className="split-left">
                            {this.state.resource_categories.map(category => {
                                return (
                                    <React.Fragment key={category.key_id}>
                                        <div className="d-flex mb-2">
                                            <div className="card mr-2" style={{ borderBottom: '3px solid red' }}>
                                                <div className="card-body py-2 px-3 font-weight-bold" style={{ fontSize: '0.8rem' }}>{category.id}. {category.name}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap resources-container">
                                            {this.state.resources.map(resource => {
                                                let resourceElement;

                                                if (resource.resource_category_id === category.id) {
                                                    return resourceElement = <ResourceCard resource={resource} key={resource.key_id} />;
                                                }
                                                return resourceElement;
                                            })
                                            }
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                            <ResourceCardEmpty></ResourceCardEmpty>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                    // <React.Fragment>                        
                    //     

                    //             

                    //             <div className="d-flex flex-wrap resources-container mb-2">

                    //                 <div className="card m-1 resource-card">
                    //                     <img className="card-img-top" src={Voluum} alt="Card cap" />
                    //                     <div className="card-body d-flex flex-column justify-content-between">
                    //                         <h5 className="card-title">Voluum</h5>
                    //                         <p className="card-text flex-1">The world's leading Performance Marketing Tracking Software, with over $1.5 Billion revenue tracked annually. Voluum provides the most granular real-time reports in the industry.</p>
                    //                         <button className="btn btn-primary">Learn More</button>
                    //                     </div>
                    //                 </div>

                    //             </div>

                    //             <div className="d-flex mb-2">
                    //                 <div className="card mr-2" style={{ borderBottom: '3px solid blue' }}>
                    //                     <div className="card-body py-2 px-3 font-weight-bold" style={{ fontSize: '0.8rem' }}>2. Traffic Sources</div>
                    //                 </div>
                    //             </div>

                    //             <div className="d-flex flex-wrap resources-container">

                    //                 <div className="card m-1 resource-card">
                    //                     <img className="card-img-top" src={MGID} alt="Card cap" />
                    //                     <div className="card-body d-flex flex-column justify-content-between">
                    //                         <h5 className="card-title">MGID</h5>
                    //                         <p className="card-text flex-1">With Native advertising gaining so much in popularity, now is the time to get set up with one of the industry's best. MGID is your go-to resource for Native Ad buys, and with the success many affiliates are seeing, you shouldn't think twice about using them. Get your native game in gear now!</p>
                    //                         <div className="alert alert-success">25% bonus on initial deposit of $1000 or more.</div>
                    //                         <button className="btn btn-primary">Learn More</button>
                    //                     </div>
                    //                 </div>

                    //                 <div className="card m-1 resource-card">
                    //                     <img className="card-img-top" src={Taboola} alt="Card cap" />
                    //                     <div className="card-body d-flex flex-column justify-content-between">
                    //                         <h5 className="card-title">Taboola</h5>
                    //                         <p className="card-text flex-1">
                    //                             <b>Generate quality leads</b> - We analyze hundreds of real-time signals to promote your content to most relevant, interested audience.<br></br>
                    //                             <b>Increase Conversions and Sales</b> - Drive sign-ups and downloads from high-intent traffic<br></br>
                    //                             <b>Drive new traffic and awareness</b> - Taboola reaches 1.4B unique users globally every month
                    //                     </p>
                    //                         <div className="alert alert-success">Spend $100 to receive a $100 bonus in Taboola.</div>
                    //                         <button className="btn btn-primary">Learn More</button>
                    //                     </div>
                    //                 </div>

                    //                 <div className="card m-1 resource-card">
                    //                     <img className="card-img-top" src={Rtxplatform} alt="Card cap" />
                    //                     <div className="card-body d-flex flex-column justify-content-between">
                    //                         <h5 className="card-title">RTX Platform</h5>
                    //                         <p className="card-text flex-1">Maximize your ad revenue with a variety of captivating ad units, fully customizable campaigns, and traffic that converts at massive scale for virtually any offer.</p>
                    //                         <div className="alert alert-success">New signups earn $100 in RTX Platform promo bucks when they spend $75 within 7 days of signup.</div>
                    //                         <button className="btn btn-primary">Learn More</button>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>                
                    // </React.Fragment>
                    :
                    // Data is not loaded yet
                    <Loading />
                }
            </React.Fragment>
        );
    }
}

export default Resources;