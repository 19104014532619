import React from 'react';

const emailPreferencesWarning = (props) => (
    <div className="email-warning">
        <div className="modal-header">
            <h5>Help</h5>
            <button type="button" className="close" onClick={props.modalClosed}>
                <span aria-hidden="true">x</span>
            </button>
        </div>
        <div className="modal-body">
            <p>
                If you turn off the receipt of the campaign notices, you will not be informed of campaign rate changes, campaigns about to expire, or other campaign changes.
            <br />
                <br />
                We strongly recommend you keep notices enabled.
        </p>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.modalClosed}>Close</button>
        </div>
    </div>
);

export default emailPreferencesWarning;