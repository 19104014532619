import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TrafficTypeItem from '../TrafficTypeItem/TrafficTypeItem';
import { Star } from 'react-feather';
import AffiliateCampaignStatus from '../AffiliateCampaignStatus/AffiliateCampaignStatus';
import CountryList from '../../../data/countrylist.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CampaignInfo.css'

class CampaignInfo extends Component {

    _isMounted = false;

    state = {
        expandCountries: false,
        isCampaignBookmarked: false
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ isCampaignBookmarked: this.props.data.is_bookmarked });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTrafficTypes = () => {
        const { allowed_traffic_types } = this.props.data;
        let rows = [];

        for (var key in allowed_traffic_types) {
            rows.push(<TrafficTypeItem key={key} trafficType={key} allowed={allowed_traffic_types[key]} type="description" />)
        }

        return rows;
    }

    getcountryList = () => {
        // Get the entire list of countries from the endpoint
        // Also need to determine if we're on the last country in the array, if we are, we don't put a comma
        let list = '';
        let arrayLen = this.props.data.allowed_countries.length;
        let count = 0;
        let separator = ', ';
        this.props.data.allowed_countries.map(country_code => {
            count = count + 1;
            if (count === arrayLen) {
                separator = '';
            }
            return CountryList.countries.filter(country => (country.code === country_code))[0] &&
                (list = list + CountryList.countries.filter(country => (country.code === country_code))[0].name + separator);
        });
        return list;
    }

    expandCountries = () => {
        if (this._isMounted) {
            this.setState({
                expandCountries: !this.state.expandCountries
            });
        }
    }

    getOSlist = () => {

        const { details, os_list } = this.props.data;
        let rows = [];

        if (details.os_filtering === 'Y') {
            os_list.map(os => {
                let displayedOS;
                if (os === 'iOS') {
                    displayedOS = <FontAwesomeIcon icon={['fab', 'apple']} />;
                } else if (os === 'Android') {
                    displayedOS = 'Android';
                }
                return rows.push(<React.Fragment key={os}>{displayedOS} {os}</React.Fragment>)
            });
        } else {
            if (details.desktop_traffic !== 'Y' && details.mobile_traffic === 'Y') {
                rows = 'All mobile operating system allowed';
            } else if (details.desktop_traffic === 'Y' && details.mobile_traffic !== 'Y') {
                rows = 'All desktop operating system allowed';
            } else {
                rows = 'All device operating systems allowed';
            }
        }
        return rows;
    }

    bookmarkToggleHandler = () => {
        const authtoken = localStorage.getItem('mb-auth-token');

        if (this.state.isCampaignBookmarked) {
            fetch(`${process.env.REACT_APP_API_URL}/campaign/${this.props.data.campaign_id}/removeBookmark`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result["success"] === true) {
                        if (this._isMounted) {
                            this.setState({ isCampaignBookmarked: false });
                        }
                    } else if (result["success"] === false) {
                        if (this._isMounted) {
                            this.setState({ isCampaignBookmarked: this.state.isCampaignBookmarked });
                        }
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                    }
                }).catch(error => {
                    this.props.showSystemMessage("error", error);
                });
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/campaign/${this.props.data.campaign_id}/addBookmark`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result["success"] === true) {
                        if (this._isMounted) {
                            this.setState({ isCampaignBookmarked: true });
                        }
                    } else if (result["success"] === false) {
                        if (this._isMounted) {
                            this.setState({ isCampaignBookmarked: this.state.isCampaignBookmarked });
                        }
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                    }
                }).catch(error => {
                    this.props.showSystemMessage("error", error.message);
                });
        }
    }

    render() {

        const { campaign_id, allowed_countries, details, categories } = this.props.data;

        // The classes for the country section will change depending on whether it's expanded or not.
        let countryClasses = 'position-relative country-container';
        let countryOverlayClasses = 'position-absolute text-center country-overlay';
        let countryCountIsBig = 5;

        if (this.state.expandCountries) {
            countryClasses = 'position-relative country-container open';
            countryOverlayClasses = 'position-absolute text-center country-overlay open';
        } else {
            if (allowed_countries.length > countryCountIsBig) {
                countryClasses = 'position-relative country-container closed';
            } else {
                countryClasses = 'position-relative country-container closed short';
            }
            countryOverlayClasses = 'position-absolute text-center country-overlay closed';
        }

        let bookmarkButtonText = this.state.isCampaignBookmarked ? "Remove Bookmark" : "Bookmark";
        let bookmarkButtonClasses = this.state.isCampaignBookmarked ? "btn btn-sm btn-warning" : "btn btn-sm btn-outline-warning";

        return (
            <div className="card my-2">
                <div className="card-header d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        Campaign Information
                       </div>
                    <div>
                        <button type="button" className={bookmarkButtonClasses} onClick={this.bookmarkToggleHandler}>
                            <Star size={20} style={{ paddingRight: "3px", paddingBottom: "3px" }} />
                            {bookmarkButtonText}
                        </button>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="campaign-image border-bottom" >
                        <img
                            className="w-100"
                            src={`${details.thumbnail}`}
                            alt="Landing Page Screenshot" />
                    </div>
                    <div className="d-flex flex-wrap p-2">
                        <div className="flex-1"><span className="font-weight-bold">{details.name}</span> <span className="text-secondary">ID: {campaign_id}</span></div>
                        <div>
                            <Link to={`/search?st=&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=${categories.main_category_id}&c=0&af=false&ff=false&rf=false&as=true&ct=&pn=1`}>{categories.category} </Link>
                            -                            <Link to={`/search?st=&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=${categories.main_category_id}&c=${categories.subcategory_id}&af=false&ff=false&rf=false&as=true&ct=&pn=1`}>                        {categories.subcategory}</Link>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table table-card">
                                <tbody>
                                    <tr>
                                        <td>Allowed Traffic Types</td>
                                        <td>{this.getTrafficTypes()}</td>
                                    </tr>
                                    <tr>
                                        <td>Your Status</td>
                                        <td>
                                            <AffiliateCampaignStatus
                                                mode="text"
                                                toggleModal={this.props.toggleModal}
                                                data={this.props.data}
                                                showSystemMessage={this.props.showSystemMessage} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Your Daily Cap</td>
                                        <td>{this.props.data.details.cap}</td>
                                    </tr>
                                    <tr>
                                        <td>Countries Allowed</td>
                                        <td>
                                            <div className={countryClasses}>
                                                {allowed_countries.length > countryCountIsBig && (
                                                    <div className={countryOverlayClasses}>
                                                        <button className="btn btn-link btn-sm" onClick={this.expandCountries}>
                                                            {this.state.expandCountries ? <span>See Less</span> : <span>See More</span>}
                                                        </button>
                                                    </div>
                                                )}
                                                {details.geo_filtering === 'Y' ?
                                                    this.getcountryList()
                                                    :
                                                    <React.Fragment>Traffic allowed from all countries</React.Fragment>
                                                }
                                            </div>
                                        </td>{/* props.allowedCountries*/}
                                    </tr>
                                    <tr>
                                        <td>Devices</td>
                                        <td> {this.getOSlist()} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default CampaignInfo;
