import React, { Component } from 'react';
import queryString from 'query-string';

import FormAccount from './FormAccount/FormAccount';
import FormInfo from './FormInfo/FormInfo';
import FormContact from './FormContact/FormContact';
import FormAddress from './FormAddress/FormAddress';
import FormIdentification from './FormIdentification/FormIdentification';
import FormExperience from './FormExperience/FormExperience';
import FormTerms from './FormTerms/FormTerms';
import FormConfirmation from './FormConfirmation/FormConfirmation';
import FormProgressBar from './FormProgressBar/FormProgressBar';

import './RegistrationForm.css';

/* https://scotch.io/tutorials/creating-multistep-forms-with-react-and-semantic-ui */

class RegistrationForm extends Component {

    state = {
        step: 1,
        existing_account: '',
        firstname: '',
        lastname: '',
        company: '',
        password: '',
        password2: '',
        security_q1: '',
        security_a1: '',
        security_q2: '',
        security_a2: '',
        email: '',
        phone: '',
        mobile: '',
        timezone: '',
        contact_time: '',
        im_handle: '',
        hear_about_source: '',
        hear_about: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        non_state: '',
        country: '',
        zipcode: '',
        idFront: '',
        idBack: '',
        experience_level: '',
        experience_description: '',
        promotion: '',
        desired_offer: '',
        website: '',        
        other_affiliate_id: '',
        other_email: ''
    }

    componentDidMount() {
        let urlParams = queryString.parse(this.props.location.search);
        let appToken = urlParams.token ? urlParams.token.trim() : '';
        let newApp = (urlParams.new_app && urlParams.new_app === "true") ? true : false;

        if (newApp) {
            this.setState({ ...this.state, step: 2 });
        }

        if (appToken !== '') {
            this.getApplicantDetails(appToken)
        }
    }

    async getApplicantDetails(appToken) {
        await fetch(`${process.env.REACT_APP_API_URL}/register/reApplication?token=${appToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true && result["data_exists"] === true) {
                    let details = result.details;
                    this.setState({
                        email: details.e_mail, name: details.contact, company: details.name, address_1: details.street_address_1, address_2: details.street_address_2, city: details.city, state: details.state, country: details.country_code, zipcode: details.zip_code, phone: details.phone, mobile: details.mobile_phone, im_handle: details.im_id, experience_level: details.experience_level, experience_description: details.experience_description
                    })
                } else if (result["success"] === false) {
                    this.props.showSystemMessage("error", "There was an error fetching applicant data");
                }
            }).catch(error => {
                this.props.showSystemMessage('error', 'Connection Error');
            });
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        });
    }


    firstStep = (accExists) => {
        const { step } = this.state
        const isExistingAccount = accExists === "yes" ? true : false;
        if (isExistingAccount) {
            this.setState({
                step: step + 1
            });
        } else {
            this.setState({
                step: step + 2
            });
        }
    }

    prevStep = () => {
        const { step, existing_account } = this.state
        if (existing_account === 'yes' && step === 1) {
            this.setState({
                existing_account: 'no'
            });
        }
        else {
            this.setState({
                step: step - 1
            });
        }
    }

    handleChange = input => event => {

        if (input === 'phone' || input === 'mobile') {
            this.setState({ [input]: event });
        } else {
            this.setState({ [input]: event.target.value })
        }
    }

    handleFileChange = input => event => {
        this.setState({ [input]: event.target.files[0] })
    }

    trackApplication = async (token, email, details, name, contact, streetAddress1, streetAddress2, city, state, countryCode, zipCode, phone, mobile, im, experienceDescription, experienceLevel) => {
        const trackData = {
            token: token,
            email: email,
            details: details,
            name: name,
            contact: contact,
            streetAddress1: streetAddress1,
            streetAddress2: streetAddress2,
            city: city,
            state: state,
            countryCode: countryCode,
            zipCode: zipCode,
            phone: phone,
            mobile: mobile,
            im: im,
            experienceDescription: experienceDescription,
            experienceLevel: experienceLevel
        }

        // console.log(trackData)

        return await fetch(`${process.env.REACT_APP_API_URL}/register/trackApplication`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(trackData)
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const { step } = this.state;

        const { existing_account, firstname, lastname, company, password, password2, security_q1, security_a1, security_q2, security_a2, email, phone, mobile, timezone, contact_time, im_handle, hear_about, hear_about_source, address_1, address_2, city, state, non_state, country, zipcode, idFront, idBack, experience_level, experience_description, promotion, desired_offer, website, other_affiliate_id, other_email } = this.state;

        const values = { existing_account, step, firstname, lastname, company, password, password2, security_q1, security_a1, security_q2, security_a2, email, phone, mobile, timezone, contact_time, im_handle, hear_about, hear_about_source, address_1, address_2, city, state, non_state, country, zipcode, idFront, idBack, experience_level, experience_description, promotion, desired_offer, website, other_affiliate_id, other_email }
        let formStage;

        switch (step) {

            case 1: formStage = <FormAccount
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
                showSystemMessage={this.props.showSystemMessage}
            />
                break;

            case 2: formStage = <FormInfo
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
                appData={this.props.appData}
                trackApplication={this.trackApplication}
                showSystemMessage={this.props.showSystemMessage}
            />
                break;

            // case 3: formStage = <FormSecurity
            //     nextStep={this.nextStep}
            //     prevStep={this.prevStep}
            //     handleChange={this.handleChange}
            //     values={values}
            //     appData={this.props.appData}
            //     trackApplication={this.trackApplication}
            // />

            //     break;

            case 3: formStage = <FormContact
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
                appData={this.props.appData}
                trackApplication={this.trackApplication}
                showSystemMessage={this.props.showSystemMessage}
            />
                break;

            case 4: formStage = <FormAddress
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
                appData={this.props.appData}
                trackApplication={this.trackApplication}
            />
                break;

            case 5: formStage = <FormExperience
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
                appData={this.props.appData}
                trackApplication={this.trackApplication}
            />
                break;

            case 6: formStage = <FormIdentification
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                handleFileChange={this.handleFileChange}
                values={values}
                appData={this.props.appData}
                trackApplication={this.trackApplication}
            />
                break;

            case 7: formStage = <FormTerms /* This is where the form should be submitted */
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
                appData={this.props.appData}
                showSystemMessage={this.props.showSystemMessage}
                trackApplication={this.trackApplication}
            />
                break;

            case 8: formStage = <FormConfirmation />
                break;

            default:
                throw new Error('Invalid step');
        }

        return (
            <React.Fragment>
                <FormProgressBar values={values} />
                {formStage}
            </React.Fragment>
        )

    }

}

export default RegistrationForm;