import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../component/UI/Loading/Loading';
import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';
import { X } from 'react-feather';
import moment from 'moment';

class ReportConversions extends Component {

    state = {
        dataLoaded: false,
        report: [],
        campaign: []
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        })

        const mbToken = localStorage.getItem('mb-auth-token');

        // For the API, we need to convert this to the next day. As the query look at anything less than. This is to ensure we get midnight.
        let realEndDate = moment(this.props.endDate).add(1, "d").format('YYYY-MM-DD');

        // let page = this.props.page - 1;

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/conversions?startDate=${this.props.startDate}&endDate=${realEndDate}&page=all`;

        if (this.props.offerId) {
            fetchUrl = fetchUrl + `&oid=${this.props.offerId}`
        }        
        
        if (this.props.creativeId){
            fetchUrl = fetchUrl + `&cid=${this.props.creativeId}`;
        }

        if (this.props.landingPageId){
            fetchUrl = fetchUrl + `&lpid=${this.props.landingPageId}`;
        }

        if (this.props.subId){
            fetchUrl = fetchUrl + `&subid=${this.props.subId}`;
        }


        /* Get the affiliate object from the DB and populate the state information */

        verifyToken().then(result => {
            if (result === true) {
                fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                // Set the parent Reports.js state with the total pages for Pagination.
                                this.props.handleTotalPages(result.total_pages);

                                // Update the current report with the data.
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report,
                                    campaign: result.campaign,
                                    creative: result.creative,
                                    landingPage: result.landing_page,
                                    subId: result.subId
                                });
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportConversions: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    getReportOutput = () => {

        let result = this.state.report;

        return result.slice(this.props.startRow, this.props.endRow).map(item => {
            return (
                <tr key={item.key_id}>
                    <td>{moment.utc(item.date).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td><Link to={`/campaign/${item.campaign_id}`}>{item.name}</Link></td>
                    <td>{item.status}</td>
                    <td>{item.rate_type}</td>
                    <td>{item.ip_location.country}</td>
                    <td>{item.device.system}</td>
                    <td>{item.device.device}</td>
                    <td>{item.device.os}</td>
                    <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                    <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.earnings)}</td>
                </tr>
            )
        });
    }

    render() {

        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");

        // Because this is a report that may or may not have a campaign ID
        let campaignId = this.state.campaign ? this.state.campaign.campaign_id : 0;

        return (
            <React.Fragment>

                {this.state.dataLoaded && this.state.campaign &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Conversions Report for: <Link to={`/campaign/${this.state.campaign.campaign_id}`}>{this.state.campaign.name}</Link> &nbsp;
                    <Link to={`/reports/earnings?startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.creativeId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Creative: {this.state.creative} &nbsp;
                        <Link to={this.props.getCancelUrl('conversions', 'creative', campaignId, this.props.subId, 0, 0, formattedStartDate, formattedEndDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.landingPageId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Landing Page: {this.state.landingPage} &nbsp;
                        <Link to={this.props.getCancelUrl('conversions', 'landingPage', campaignId, this.props.subId, 0, 0, formattedStartDate, formattedEndDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.subId !== '' &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Sub ID 1: {this.state.subId} &nbsp;
                        <Link to={this.props.getCancelUrl('conversions', 'subId', campaignId, this.props.subId, this.props.creativeId, this.props.landingPageId, formattedStartDate, formattedEndDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                <table className="table table-striped table-card table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Campaign</th>
                            <th scope="col">Status</th>
                            <th scope="col">CPA Type</th>
                            <th scope="col">IP Country</th>
                            <th scope="col">System</th>
                            <th scope="col">Device</th>
                            <th scope="col">OS</th>
                            <th scope="col" className="text-right">Sales</th>
                            <th scope="col" className="text-right">Earnings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {this.state.report.length === 0 ?
                                    <tr>
                                        <td colSpan="10">No stats for this date range.</td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                        {this.getReportOutput()}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <tr>
                                <td colSpan="10"><Loading /></td>
                            </tr>
                        }

                    </tbody>
                </table>

            </React.Fragment>
        );
    }
}

export default ReportConversions;