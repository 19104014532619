import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CryptoJS from 'crypto-js';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import PersonalInformation from './SettingsSection/PersonalInformation';
import PhoneInformation from './SettingsSection/PhoneInformation';
import Address from './SettingsSection/Address';
import PromotionalMethods from './SettingsSection/PromotionalMethods';
import EmailPreferences from './SettingsSection/EmailPreferences';
import Security from './SettingsSection/Security';
import TwoFactorAuthentication from './SettingsSection/TwoFactorAuthentication';
import Payment from './SettingsSection/Payment';
import Loading from '../../component/UI/Loading/Loading';
import VerificationModal from './SettingsSection/VerificationModal'
import { clearLocalStorageAndReload, verifyToken, verifyOTPToken } from '../../assets/JS/functions';

class Settings extends Component {

    state = {
        dataLoaded: false,
        affiliate: [],
        showVerificationModal: false,
        t: false,
        code: false,
        message: ''
    }

    componentWillMount() {
        // Check to see if localstorage has been set
        // THIS SHOULD BE SET UPON LOGIN AT api/authenticationApp
        const mbToken = localStorage.getItem('mb-auth-token');
        // const affiliateID = localStorage.getItem('mb-aid');    

        /* Get the affiliate object from the DB and populate the state information */
        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/affiliate?log=true`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                this.setState({
                                    dataLoaded: true,
                                    affiliate: result
                                });
                            } else if (result["success"] === false) {
                                if (result.code === 401) {
                                    clearLocalStorageAndReload();
                                } else {
                                    this.props.showSystemMessage("error", "There was an error signing in. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => this.props.showSystemMessage("error", error.message));
    }

    /*
    Send code
    Show prompt
    */
    handleEditRequest = async (e) => {
        e.preventDefault();
        this.setState({
            showVerificationModal: true
        });
    }

    /* When OTP prompt is dismissed without entering code */
    cancelEditRequest = (e) => {
        e.preventDefault();
        this.setState({ editing: false, showVerificationModal: false, message: '' });
    }

    /* Invoked when code is entered */
    handleCodeVerification = async (code) => {

        // Calls the verification endpoint
        await verifyOTPToken(code).then(result => {
            if (result["success"]) {
                this.setState({ ...this.state, showVerificationModal: false, code: code });
            } else {
                this.setState({ message: result.errors.message });
            }
        });
    }

    checkEditStatus = () => {
        let token = this.state.t;
        let decrypted = '';

        if (token) {
            decrypted = CryptoAES.decrypt(token, CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoENC);
            const code = decrypted.substr(0, decrypted.indexOf('-'));

            if (code === this.state.code) {
                return true
            }
        }

        return false;

    }

    setTokenT = (t) => {
        this.setState({ t: t });
    }

    render() {

        const { affiliate_personal_information, affiliate_address, promotional_methods, security, email_preferences, otp_details, affiliate_flags } = this.state.affiliate;

        return (
            <React.Fragment>

                {!this.checkEditStatus() &&
                    <div className="text-right"><button className="btn btn-warning btn-sm mt-2" type="submit" onClick={this.handleEditRequest}>Edit</button></div>
                }

                <Modal className="modal-md" isOpen={this.state.showVerificationModal} toggle={this.cancelEditing}>
                    <VerificationModal
                        modalClosed={this.cancelEditRequest}
                        verifyCode={this.handleCodeVerification}
                        errorMessage={this.state.message}
                        showSystemMessage={this.props.showSystemMessage}
                        affiliateInfo={affiliate_personal_information}
                        setToken={this.setTokenT}
                    />
                </Modal>

                {this.state.dataLoaded ?

                    <div className="row">

                        <div className="col-lg-7 split-left">

                            <PersonalInformation personalInfo={affiliate_personal_information} editName={this.props.editName}
                                editMobile={this.props.editMobile} showSystemMessage={this.props.showSystemMessage} showEdit={this.checkEditStatus()} />

                            <EmailPreferences preferences={email_preferences}
                                affiliateFlags={affiliate_flags}
                                email={affiliate_personal_information.email}
                                updateEmailFlag={this.props.removeInvalidEmailFlag} showSystemMessage={this.props.showSystemMessage}
                                showEdit={this.checkEditStatus()} />

                            <PhoneInformation
                                phone={affiliate_personal_information.phone}
                                mobile={affiliate_personal_information.mobile_phone}
                                editMobile={this.props.editMobile} showSystemMessage={this.props.showSystemMessage} showEdit={this.checkEditStatus()}
                            />

                            <Address address={affiliate_address} showSystemMessage={this.props.showSystemMessage} showEdit={this.checkEditStatus()} />

                        </div>

                        <div className="col-lg-5 split-right">

                            <Payment affiliate={this.state.affiliate} showSystemMessage={this.props.showSystemMessage}
                                showEdit={this.checkEditStatus()} />

                            <Security security={security} showSystemMessage={this.props.showSystemMessage}
                                showEdit={this.checkEditStatus()} />

                            <TwoFactorAuthentication otpDetails={otp_details} showSystemMessage={this.props.showSystemMessage}
                                showEdit={this.checkEditStatus()} />

                            <PromotionalMethods methods={promotional_methods} showSystemMessage={this.props.showSystemMessage} showEdit={this.checkEditStatus()} />
                        </div>
                    </div>
                    :
                    // Data is not loaded yet
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Settings;