import React, { Component } from 'react';

import { X } from 'react-feather';

class SingleAlert extends Component {

    render() {
        return (
            <div className="alertPop rounded">
                {/* <div className="alertPop-header d-flex justify-content-between">
                    <div>{this.props.alert.title}</div>
                </div> */}
                <div className="alertPop-body">
                    <div dangerouslySetInnerHTML={{ __html: this.props.alert.news }} className="mr-2" />
                    <div className="align-items-right" onClick={() => this.props.popClosed(this.props.alert.news_id)}><X size={14} /></div>
                    {/* <div className="mt-2" onClick={() => this.props.popClosed(this.props.alert.news_id)}><Link to={this.props.alert.url} className="btn btn-primary btn-sm">{this.props.alert.urltext}</Link></div> */}
                </div>
            </div>
        );
    }
}

export default SingleAlert;