import React, { Component } from 'react';
import { withRouter } from 'react-router';

import queryString from 'query-string';
import Checkbox from '../Checkbox/Checkbox';
import Countries from '../../../component/FormElements/Countries';

import { clearLocalStorageAndReload, verifyToken } from '../../../assets/JS/functions';
import './AdvanceSearch.css';

// May have to conver this to container to handler selection and click events in this file itself
// OR have to figure out a way to pass state and props through Redux

class AdvanceSearch extends Component {

    state = {
        categories: [],
        subcategories: [],
        searchCheckboxes: {
            ep: true,
            cp: true,
            bp: true,
            sp: true,
            ip: true,
            lp: true,
            np: true,
            mp: true,
            bbp: true,
            af: false,
            ff: false,
            rf: false
        },
        selectedCategory: 0,
        selectedSubCategory: 0,
        gr: "",
        grstrict: 0,
        trafficstrict: 0,
        cpaType: ""
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentDidMount() {
        let authtoken = localStorage.getItem('mb-auth-token');

        // Fetch all the categories

        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/search/categories`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    }
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.setState({
                                categories: result.categories
                            });
                        } else if (result["success"] === false) {
                            if (result.code !== 401) {
                                this.props.showSystemMessage("error", "An error occurred while fetching categories.");
                            }
                        } else {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage("error", error.message);
        });

        // if search query is set in location props
        if (this.props.location.search) {

            let urlParams = queryString.parse(this.props.location.search);
            this.setState({
                searchCheckboxes: { ep: JSON.parse(urlParams.ep), cp: JSON.parse(urlParams.cp), bp: JSON.parse(urlParams.bp), lp: JSON.parse(urlParams.lp), ip: JSON.parse(urlParams.ip), mp: JSON.parse(urlParams.mp), np: JSON.parse(urlParams.np), sp: JSON.parse(urlParams.sp), bbp: JSON.parse(urlParams.bbp), af: JSON.parse(urlParams.af), ff: JSON.parse(urlParams.ff), rf: JSON.parse(urlParams.rf) }, grstrict: JSON.parse(urlParams.grstrict), trafficstrict: JSON.parse(urlParams.tstrict),
                selectedCategory: JSON.parse(urlParams.m), selectedSubCategory: JSON.parse(urlParams.c), gr: urlParams.gr, cpaType: urlParams.ct
            }, () => {
                if (this.state.selectedCategory > 0) {
                    this.fetchSubCategories(this.state.selectedCategory);
                }
            });
        }
    }

    fetchSubCategories = (mainCategoryId) => {
        let authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/search/subcategories/${mainCategoryId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({
                        subcategories: result.subcategories
                    });
                } else if (result["success"] === false) {
                    if (result.code === 401) {
                        clearLocalStorageAndReload();
                    } else {
                        this.props.showSystemMessage("error", "An error occurred while fetching the subcategories.");
                    }
                } else {
                    this.props.showSystemMessage("error", result.error)
                }
            })
            .catch(error => {
                this.props.showSystemMessage("error", error.message);
            });
    }

    dropdownChangeHandler = (event) => {
        switch (event.target.name) {
            case "m":
                this.setState({ selectedCategory: event.target.value }, () => {
                    this.fetchSubCategories(this.state.selectedCategory);
                });
                break;
            case "c":
                this.setState({ selectedSubCategory: event.target.value });
                break;
            case "gr":
                this.setState({ gr: event.target.value });
                break;
            case "ct":
                this.setState({ cpaType: event.target.value });
                break;
            case "trafficstrict":
                this.setState({ trafficstrict: event.target.value });
                break;
            case "grstrict":
                this.setState({ grstrict: event.target.value });
                break;
            default:
                throw new Error("Invalid input");
        }
    }

    checkboxChangeHandler = (event) => {

        this.setState({ searchCheckboxes: { ...this.state.searchCheckboxes, [event.target.name]: event.target.checked } }, () => {
            this.props.checkboxSelected(this.state.searchCheckboxes);
        });
    }

    render() {
        return (<div className="AdvanceSearch mb-bg-white rounded-bottom px-2 border-top">
            <table className="table table-card">
                <tbody>
                    <tr>
                        <th scope="row" className="no-border">Traffic Type</th>
                        <td className="no-border" align="left">
                            <Checkbox id="Email" name="ep" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.ep}
                            />
                            <Checkbox id="Contextual" name="cp" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.cp}
                            />
                            <Checkbox id="Display" name="bp" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.bp}
                            />
                            <Checkbox id="Search" name="sp" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.sp}
                            />
                            <Checkbox id="Incentive" name="ip" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.ip}
                            />
                            <Checkbox id="Social Media" name="lp" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.lp}
                            />
                            <Checkbox id="Native" name="np" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.np}
                            />
                            <Checkbox id="Mobile" name="mp" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.mp}
                            />
                            <Checkbox id="Brand Bid" name="bbp" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.bbp}
                            />
                        </td>
                        <td className="no-border">
                            <select className="custom-select" name="trafficstrict" onChange={this.dropdownChangeHandler} value={this.state.trafficstrict}>
                                <option defaultValue value="0">Includes selected traffic types</option>
                                <option value="1">ONLY Selected traffic types</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row">Geography</th>
                        <td align="left">
                            <select className="custom-select max-width-250" name="gr" value={this.state.gr} onChange={this.dropdownChangeHandler}>
                                <option defaultValue value="">Any Country</option>
                                <option defaultValue value="ALL">Allow All Countries</option>
                                <Countries />
                            </select>
                        </td>
                        <td>
                            <select className="custom-select" name="grstrict" onChange={this.dropdownChangeHandler} value={this.state.grstrict}>
                                <option defaultValue value="0">Includes selected country</option>
                                <option value="1">ONLY selected country</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row">Categories</th>
                        <td>
                            <select className="custom-select max-width-250" name="m" onChange={this.dropdownChangeHandler} value={this.state.selectedCategory}>
                                <option defaultValue value="0">All categories</option>

                                {this.state.categories.map(category =>
                                    <option value={category.main_category_id} key={category.main_category_id} >{category.main_category_name} ({category.offs_count})</option>
                                )}

                            </select>
                            &nbsp;
                            <select className="custom-select max-width-250" name="c" value={this.state.selectedSubCategory} onChange={this.dropdownChangeHandler}>
                                <option defaultValue value="0">All sub categories</option>

                                {this.state.subcategories.length > 0 ? this.state.subcategories.map(sub => <option key={sub.subcategory_id} value={sub.subcategory_id}>{sub.subcategory_name} ({sub.campaign_count})</option>) : null}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row">Filters</th>
                        <td align="left">
                            <Checkbox id="Approved to run" name="af" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.af}
                            />
                            <Checkbox id="Bookmarked" name="ff" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.ff}
                            />
                            <Checkbox id="Never Promoted Before" name="rf" checkboxChanged={this.checkboxChangeHandler}
                                isChecked={this.state.searchCheckboxes.rf}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">CPA Type</th>
                        <td>
                            <select className="custom-select max-width-250" name="ct" onChange={this.dropdownChangeHandler} value={this.state.cpaType}>
                                <option defaultValue value="">Any</option>
                                <option value="F">$ per lead</option>
                                <option value="P">Revshare</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);
    }
}

export default withRouter(AdvanceSearch);