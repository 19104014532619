import React, { Component } from 'react';

import './CampaignCardEmpty.css';

class CampaignCardEmpty extends Component {

    render() {
        return  <div className="Campaigncard-empty"></div>

    }
}

export default CampaignCardEmpty;