import React, { Component } from 'react';
import QRCode from 'qrcode.react';

import maxbountyLogoSquare from '../../../assets/images/MaxBounty_Square.png';
import googleStoreImg from '../../../assets/images/googleplay.png';
import appleStoreImg from '../../../assets/images/applestore.png';
import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';

/* 
    There are two places this page can be called from: 
        1. In the Login flow whem user opts to setup 2-FA
            - 'Skip for now' text
            - Fetching affiliateData through reloadThePage prop to App.js
        2. From the Setting page when user clicks on 'Set up
            - 'Cancel' text to dismiss the setup
            - window.location.reload() to refresh the page on cancellation

            this.props.fromProfile determined where the page 
            is loading from. 

*/

class OTPsetup extends Component {

    state = {
        otpToken: '',
        otpInput: 0,
        secret: ''
    }

    componentWillUnmount() {
        // this._isMounted = false;
        window.removeEventListener('beforeunload', this.onUnload);
    }

    onUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
    }

    componentDidMount() {
        this.getOTPsetupData();
        window.addEventListener('beforeunload', this.onUnload);
    }

    handleOTPcancel = (event) => {
        event.preventDefault();

        this.props.fromProfile ?
            this.props.setupClosed() : this.props.pageReloaded();
    }

    handleOTPInput = (event) => {
        this.setState({ otpInput: event.target.value });
    }

    handleOTPactivation = (event) => {
        event.preventDefault();

        // If setting page has been stale for some time then we need to check if the token is still valid before making request for OTPactivationData

        // if (this.props.fromProfile) {
        verifyToken().then(result => {
            if (result === true) {
                this.otpActivationFetchCall(event);
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('error while activating 2FA: ', error);
            this.props.showSystemMessage('error', "Error while activating 2-factor authentication. Please try again later.");
        });
    }

    // This function is called after verifyToken if request is coming from settings page, otherwise its called directly from handleOTPactivation
    otpActivationFetchCall = (event) => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/authenticationApp/otpActivate`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                secret: this.state.secret,
                otp: this.state.otpInput
            })
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    if (result.isTokenValid) {
                        this.props.tokenVerified(result.isTokenValid);
                    } else {
                        // Stay on the same screen, change the code, throw error
                        this.setState({ otpInput: 0 }, () => {
                            this.props.showSystemMessage('error', 'The value you entered was incorrect. Please scan this new code and try again.');
                            this.getOTPsetupData();
                        });
                    }
                } else {
                    console.log('There was an error verifying your code. Please try again.');
                }
            });
    }

    getOTPsetupData = () => {
        verifyToken().then(result => {
            if (result === true) {
                this.fetchOTPkeyURI();
            } else {
                localStorage.removeItem('mb-aid');
                localStorage.removeItem('mb-auth-token');
                this.props.pageReloaded();
            }
        }).catch(err => { this.props.showSystemMessage('error', 'Error while fetching QR code.') });
    }

    // if a request is coming from settings page, this function will be called after a verifyToken call else called directly from getOTPsetupData
    fetchOTPkeyURI = () => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/authenticationApp/otpsetup`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({ otpToken: result.token, secret: result.secret });
                } else {
                    console.log('An error occurred while fetching OTP code.');
                }
            })
            .catch(err => err);
    }

    render() {

        return (
            <React.Fragment>
                {!this.props.fromProfile && <div className="panel">
                    <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="" width="100" />
                </div>}
                <div className="card text-left">
                    <div className="card-header font-weight-bold">
                        2-step Verification Setup
                    </div>
                    <div className="card-body">
                        <div className="mb-4">
                            1. On your mobile device, download the Google Authenticator app.
                            <div className="d-flex mt-2">
                                <div className="pl-3">
                                    <a target="_blank" href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="noopener noreferrer"><img alt='Get it on Google Play' src={googleStoreImg} style={{ maxWidth: '150px', width: '100%' }} /></a>
                                </div>
                                <div className="pl-2" style={{ paddingTop: '1px' }}>
                                    <a target="_blank" href='https://apps.apple.com/ca/app/google-authenticator/id388497605' rel="noopener noreferrer"><img alt='Download on the App Store' src={appleStoreImg} style={{ maxWidth: '150px', width: '100%' }} /></a>
                                </div>
                            </div>
                        </div>
                        <div className="my-4">
                            2. Open the app and scan this QR code. <br />
                            <div className="pl-3 my-2">
                                <QRCode value={this.state.otpToken} size={200} />
                            </div>
                        </div>
                        <div className="mt-4">
                            3. Enter the 6-digit code from Google Authenticator. <br />
                            <form className="pl-3 my-2" method="post" onSubmit={this.handleOTPactivation}
                                autoComplete="off"
                            >
                                <input type="hidden" name="secret" value="" />
                                <div className="form-group">
                                    <input className="form-control" type="text" name="token" onChange={this.handleOTPInput} />
                                </div>
                                <div className="form-group">
                                    <input className="btn btn-primary" type="submit" value="Activate" />
                                    {/* <button className="btn btn-link" onClick={(e) => this.handleOTPcancel(e)}>{this.props.fromProfile ? 'Cancel' : 'Skip for now'}</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OTPsetup;