import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FormInfo extends Component {

    constructor(props) {
        super(props);
        this.formInfo = React.createRef();
        this.firstname = React.createRef();
        this.lastname = React.createRef();
        this.email = React.createRef();
        this.company = React.createRef();
        this.password = React.createRef();
        this.password2 = React.createRef();
    }

    state = {
        dataLoaded: true,
        appRestrictToken: ''
    }

    validate = async () => {

        this.setState({
            dataLoaded: false
        });

        /* Check required fields */
        const input_firstname = this.firstname.current;
        const input_lastname = this.lastname.current;
        const input_email = this.email.current;
        const input_company = this.company.current;
        const input_password = this.password.current;
        const input_password2 = this.password2.current;
        let emailCheck = 1;
        /* Reset any previous custom validity settings to valid */
        input_email.setCustomValidity("");
        input_firstname.setCustomValidity("");
        input_lastname.setCustomValidity("");
        input_company.setCustomValidity("");
        input_password.setCustomValidity('');
        input_password2.setCustomValidity('');

        /* Check if the email follows the rules */
        const emailAccount = input_email.value.split("@")[0] + "@";
        const emailDomain = "@" + input_email.value.split("@")[1];
        const abuseCheck = emailAccount.includes("abuse");
        const bounceCheck = emailAccount.includes("bounce");
        const blockCheck = emailAccount.includes("block");
        const spamCheck = input_email.value.includes("spam");
        const swearCheck = input_email.value.includes("fuck");

        emailCheck = await this.checkEmail(input_email.value);

        const validatePassword = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{7,30}$/.test(input_password.value);

        this.setState({
            dataLoaded: true
        });

        if (input_firstname.checkValidity() === false || input_lastname.checkValidity() === false || input_email.checkValidity() === false) {
            return false
        }
        let input_name = input_firstname.value + " " + input_lastname.value;

        if (input_name.length > 100) {
            input_firstname.setCustomValidity('Your name must be less than 100 characters.');
            return false
        }

        if (input_company.value.length > 100) {
            input_company.setCustomValidity('Your company name must be less than 100 characters.');
            return false
        }

        if (emailAccount.length === 0 || emailDomain.length === 1 || abuseCheck || bounceCheck || blockCheck || spamCheck || swearCheck) {
            input_email.setCustomValidity("This email address is not allowed.");
            return false;
        }

        if (emailCheck === 1) {
            input_email.setCustomValidity("This email address is unavailable.");
            return false;
        }

        if (input_password.checkValidity() === false || input_password2.checkValidity() === false) {
            return false;
        }
        else if (this.props.values.password !== this.props.values.password2) {
            input_password2.setCustomValidity('Passwords do not match.');
            return false;
        }
        else if (!validatePassword && input_password.value.length < 8) {
            input_password.setCustomValidity('Your new password must be between 8 and 12 characters long and have letters, numbers and special characters.');
            return false;
        }
        return true;
    }

    checkEmail = async (email) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/register/checkEmail?e=${email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        return result["taken"];
                    } else {
                        return 1;
                    }
                },
                (error) => {
                    this.props.showSystemMessage("error", "There was an issue connecting to our network. Please try turning off any ad-blocking extensions and refresh the page. If this issue persists, please contact ryanm@maxbounty.com.")
                    return 1;
                }
            );
    }

    saveAndContinue = async (e) => {
        e.preventDefault()

        let validate = await this.validate();

        if (validate) {
            let name = this.props.values.firstname + " " + this.props.values.lastname;
            await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, 'Initiated', this.props.values.company, name, this.props.values.address_1, this.props.values.address_2, this.props.values.city, this.props.values.state, this.props.values.country, this.props.values.zipcode, this.props.values.phone, this.props.values.mobile, this.props.values.im_handle, this.props.values.experience_description, this.props.values.experience_level)
            this.props.nextStep()
        } else {
            /* Forces html5 form validation */
            const formInfo = this.formInfo.current;
            formInfo.reportValidity();
        }
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    changeHandler = (e) => {
        this.props.handleChange('email')(e);
        this.email.current.setCustomValidity('');
    }

    render() {

        const { values } = this.props
        return (
            <form ref={this.formInfo} method="POST">
                <div className="form-row mb-3 text-white">
                    <div className="col text-left">
                        <label htmlFor="firstname">First Name*</label>
                        <input
                            ref={this.firstname}
                            onChange={(e) => {
                                this.firstname.current.setCustomValidity("")
                                this.props.handleChange('firstname')(e);
                            }}
                            placeholder="First Name"
                            defaultValue={values.firstname}
                            className="form-control"
                            required />
                    </div>
                    <div className="col text-left">
                        <label htmlFor="lastname">Last Name*</label>
                        <input
                            ref={this.lastname}
                            onChange={(e) => {
                                this.lastname.current.setCustomValidity("")
                                this.props.handleChange('lastname')(e);
                            }}
                            placeholder="Last Name"
                            defaultValue={values.lastname}
                            className="form-control"
                            required />
                    </div>
                </div>
                <div className="form-row mb-3 text-white">
                    <div className="col text-left">
                        <label htmlFor="company">Company Name</label>
                        <input
                            ref={this.company}
                            onChange={(e) => {
                                // this.company.current.setCustomValidity("")
                                this.props.handleChange('company')(e);
                            }}
                            placeholder="Company Name"
                            defaultValue={values.company}
                            className="form-control" />
                    </div>
                </div>
                <div className="form-group text-left text-white">
                    <label htmlFor="email">Email*</label>
                    <input
                        ref={this.email}
                        onChange={(e) => this.changeHandler(e, 'email')}
                        placeholder="Email"
                        defaultValue={values.email}
                        className="form-control"
                        type="email"
                        required />
                </div>
                <div className="form-row mb-3 text-white">
                    <div className="col text-left">
                        <label htmlFor="Password">Password*</label>
                        <input
                            ref={this.password}
                            onChange={this.props.handleChange('password')}
                            placeholder="Password"
                            defaultValue={values.password}
                            className="form-control"
                            type="password" required />
                    </div>
                    <div className="col text-left">
                        <label htmlFor="Password">Re-Enter Password*</label>
                        <input
                            ref={this.password2}
                            onChange={this.props.handleChange('password2')}
                            placeholder="Re-Enter Password"
                            defaultValue={values.password2}
                            className="form-control"
                            type="password"
                            required />
                    </div>
                </div>
                {this.state.dataLoaded ?
                    <div className="d-flex flex-row-reverse">
                        <div className="pl-2 flex-1">
                            <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Next</button>
                        </div>
                        <div className="flex-1">
                            <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                        </div>
                    </div>
                    :
                    <div className="d-flex flex-row-reverse">
                        <div className="pl-2 flex-1">
                            <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        </div>
                        <div className="flex-1">
                            <button className="btn btn-lg btn-secondary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        </div>
                    </div>
                }
            </form>
        );
    }
}

export default FormInfo;