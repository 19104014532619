import React from 'react';
import { Phone, Mail } from 'react-feather';
import { FaSkype } from 'react-icons/fa';

const contactModal = (props) => (
    <div className="ContactModal">
        <div className="modal-body text-center">
            <h5 className="mb-3 pb-3 border-bottom font-weight-bold">{props.affiliate.affiliate_manager.name}</h5>
            <p><Phone size={20} style={{ paddingRight: "5px" }} />{props.affiliate.affiliate_manager.phone}</p>
            <p><a href={`mailto:${props.affiliate.affiliate_manager.email}`}><Mail size={20} style={{ paddingRight: "5px" }} />{props.affiliate.affiliate_manager.email}</a></p>
            <p><FaSkype size={20} style={{ paddingRight: "5px" }} />{props.affiliate.affiliate_manager.im_handle}</p>
        </div>
    </div>
);


export default contactModal;