import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AlertTriangle } from 'react-feather';

export default function TipaltiMissingDocs({modalClosed}){

    const [confirm, setConfirm] = useState(false)

    const acknowledgeHandler = () => {               
            const authtoken = localStorage.getItem('mb-auth-token');

            fetch(`${process.env.REACT_APP_API_URL}/tipaltiMissingDocs`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                }
            })
                .then(res => res.json())
                .then(result => {
                   modalClosed()
                })
                .catch(error => {
                    console.log(error.message)
                });
    }
  
    return (
        <div>
            <div className="modal-header">
                <h6 className="modal-title">Missing Tipalti Documents</h6>
                <button type="button" className="close" onClick={modalClosed}>
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div className="modal-body">
                <div>
                    <div className="text-center mb-3"><AlertTriangle color="red" size={25} /></div>
                    <div className="alert alert-danger text-center">Your Tipalti account has NOT been verified.</div>
                    <p style={{ fontSize: "14px" }}>We have been notified by our payment processor Tipalti that they are still waiting for important information from you to complete your registration.</p>
                    <p style={{ fontSize: "14px" }}>If they do not get this information from you, your account will be blocked in Tipalti and MaxBounty will not be able to make any payments to you through Tipalti. Please get in touch with them immediately to address this issue.</p>
                    <p style={{ fontSize: "14px" }}>Please check your email for a request for documents from Tipalti.</p>
                    <p style={{ fontSize: "14px" }}>If you cannot find the email from Tipalti, please contact them immediately at <a href="mailto:support@tipalticompliance.zendesk.com">support@tipalticompliance.zendesk.com</a> - be sure to include your affiliate ID in your email to them.</p>
                    <p style={{ fontSize: "14px" }}>Please do not send ANY documents to Maxbounty compliance. Your documents MUST be sent to Tipalti directly.</p>
                </div>
                <div className="text-center">
                    {
                        !confirm ?
                            <button className="btn btn-sm btn-info" onClick={()=>setConfirm(true)}>I have submitted my documents. Don't show this again.</button>
                        :
                            <button className="btn btn-sm btn-info px-5" onClick={acknowledgeHandler}>Confirm</button>
                    }
                    <br></br>
                    <button className="btn btn-sm btn-warning mt-3" onClick={modalClosed}>Remind me later</button>
                </div>
            </div>
        </div>
    );
    
}