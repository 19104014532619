import React, { Component } from 'react';
import TrafficTypeItem from '../CampaignPage/TrafficTypeItem/TrafficTypeItem';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';

import mbStacked from '../../assets/images/maxbounty_stacked.png';
import mbHorizontal from '../../assets/images/MaxBounty_Horizontal.png';
import referralProgram from '../../assets/images/referral/referral_program.jpg';
import referralProgram1 from '../../assets/images/referral/maxbountyreferral-160x600.jpg';
import referralProgram2 from '../../assets/images/referral/maxbountyreferral-300x250.jpg';
import referralProgram3 from '../../assets/images/referral/maxbountyreferral-300x600.jpg';
import referralProgram4 from '../../assets/images/referral/maxbountyreferral-728x90.jpg';

import './Refer.css'

class Refer extends Component {
    
    state = {
        dataLoaded: false,
        referral_count: 0
    }

    componentDidMount() {

        const mbToken = localStorage.getItem('mb-auth-token');

        /* Get the affiliate object from the DB and populate the state information */
        fetch(`${process.env.REACT_APP_API_URL}/referrer`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {                       
                        this.setState({
                            dataLoaded: true,
                            referral_count: result.referral_count
                        });                        
                    } else if (result["success"] === false) {
                        console.log(result["message"]);
                    } else {
                        console.log(result.errors.message);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )           

    }

    render() {
        return (
            <div className="py-2">
                <div className="split-left">
                    <div className="card my-2">
                        <div className="card-header d-flex align-items-center">
                            Referral Program
                        </div>
                        <div className="card-body">
                            <h4>Earn Even More with MaxBounty's Get 10% Give 10% Affiliate Referral Program</h4>
                            <hr></hr>
                            <p>You can start earning additional income at MaxBounty right now by participating in our Affiliate Referral Program — available to ALL active MaxBounty affiliates!</p>
                            <div className="border rounded p-3 d-inline-block mb-3"><h5 className="m-0 p-0"><strong>Get a +10% Bonus, Give a +10% Bonus</strong></h5></div>
                            <p><strong>What you get:</strong> For every new approved affiliate you refer to our network, we will give you a monthly <strong><div className="badge badge-danger">+10% bonus</div> directly to your MaxBounty account</strong> equal to 10% of your referred affiliates' future MaxBounty earnings. This bonus will remain active for (6) months after each of your referred affiliates' accounts are approved.</p>
                            <p><strong>What your referred affiliates get:</strong> To help you entice new sign ups to MaxBounty, we will also be giving your referred affiliates a <strong><div className="badge badge-danger">+10% bonus</div> on their first payment</strong> amount, which we encourage you to actively promote.</p>
                            <p>Start referring affiliates today to double up your MaxBounty income streams and maximize your earnings.</p>

                            <hr></hr>

                            <div className="d-flex align-items-center">
                                <div><strong>Your referral link: </strong></div>
                                <div className="alert alert-success mb-0 ml-2 p-2">
                                    {`${process.env.REACT_APP_PORTAL_URL}/register?referrer=${this.props.affiliate.affiliate_id}`}
                                </div>
                            </div>

                            <img src={referralProgram} alt="MaxBounty Referral Program" style={{ width: '100%', maxWidth: '800px' }} />                            

                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header d-flex align-items-center">
                            Refer 10 Bonus
                        </div>
                        <div className="card-body">
                            <h4>Receive an Added $100 Bonus</h4>
                            <hr></hr>
                            <p>Not only is there no limit to how many affiliates you can refer and how much you can earn through our referral program, but you can also receive an <strong>additional $100 bonus</strong> to your account for referring ten approved affiliates!</p>
                            {
                                this.state.referral_count < 10 ?
                                <>
                                    <p>The progress bar below will automatically track how close you are to receiving your bonus.</p>
                                    <div className="text-center font-weight-bold">
                                        <div className="progress mx-auto position-relative" style={{maxWidth:'700px', height: '25px', fontSize: '1rem'}}>
                                            <div className="progress-goal"><div className="badge badge-success">$100</div></div>
                                            <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" style={{width: `${this.state.referral_count/10*100}%`}} aria-valuenow={this.state.referral_count} aria-valuemin="0" aria-valuemax="10"></div>
                                        </div>
                                        <p>Affiliates Referred: {this.state.referral_count}/10</p>
                                    </div>
                                </>
                            :
                                <div className="alert alert-success">
                                    Congratulations, you have earned the added $100 bonus for referring ten approved affiliates! Your bonus will be applied within two weeks of hitting the referral goal.
                                </div>
                            }                            
                        </div>
                    </div>
                    <div className="card my-2">
                        <div className="card-header d-flex align-items-center">
                            Creatives
                        </div>
                        <div className="card-body">
                            <p>Use the below elements to enhance the promotion of your referral link on your website, in your forum signature, or in your review of MaxBounty.</p>                            
                            <hr></hr>
                            <div className="font-weight-bold mb-3">
                                <u>Logos</u>
                            </div>
                            <p>Right click + "Save Image As" to save a copy of creative.</p>
                            <div className="d-flex flex-wrap">                                
                                <div className="p-3" style={{minWidth:'100px'}}>
                                    1. Stacked MaxBounty Logo:<br></br>
                                    <img src={mbStacked} alt="MaxBounty Stacked Logo" style={{ width: '100%', maxWidth: '250px' }} />
                                </div>
                                <div className="p-3" style={{minWidth:'100px'}}>
                                    2. Horizontal MaxBounty Logo:<br></br>
                                    <img src={mbHorizontal} alt="MaxBounty Horizontal Logo" style={{ width: '100%', maxWidth: '500px' }} />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="font-weight-bold mb-3">
                                <u>Banners</u>
                            </div>
                            <p>Right click + "Save Image As" to save a copy of creative.</p>
                            <div className="d-flex flex-wrap">                                
                                <div className="p-3" style={{minWidth:'100px'}}>
                                    1. 160x600:<br></br>
                                    <img src={referralProgram1} alt="MaxBounty Stacked Logo" style={{ width: '100%', maxWidth: '160px' }} />
                                </div>
                                <div className="p-3">
                                    2. 300x250:<br></br>
                                    <img src={referralProgram2} alt="MaxBounty Horizontal Logo" style={{ width: '100%', maxWidth: '500px' }} />
                                </div>
                                <div className="p-3">
                                    3. 300x600:<br></br>
                                    <img src={referralProgram3} alt="MaxBounty Horizontal Logo" style={{ width: '100%', maxWidth: '500px' }} />
                                </div>
                                <div className="p-3">
                                    4. 728x90:<br></br>
                                    <img src={referralProgram4} alt="MaxBounty Horizontal Logo" style={{ width: '100%', maxWidth: '500px' }} />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="font-weight-bold mb-3">
                                <u>Text</u>
                            </div>
                            <div>
                                1. Short text:<br></br>
                                <div className="alert alert-secondary">
                                    <a href={`${process.env.REACT_APP_PORTAL_URL}/register?referrer=${this.props.affiliate.affiliate_id}`}>Sign up</a> to MaxBounty—the #1 ranked CPA network—and receive an automatic 10% bonus on your first affiliate payment!
                                </div>
                            </div>
                            <div>
                                2. Long text:<br></br>
                                <div className="alert alert-secondary">
                                    As a MaxBounty affiliate, you can look forward to:<br></br><br></br>
                                    
                                    • Access to their largest collection of campaigns EVER in diverse verticals such as Finance, Insurance, Health, Education, Market Research, E-Commerce and more.<br></br>
                                    • Access to MaxBounty's Owned and Operated campaigns that offer greater flexibility, profitability, and control.<br></br>
                                    • Frequent performance rewards and bonuses that allow you to maximize your earnings.<br></br>
                                    • Dedicated support from your MaxBounty Affiliate Manager.<br></br>
                                    • Always on-time, weekly affiliate payments.<br></br><br></br>

                                    <a href={`${process.env.REACT_APP_PORTAL_URL}/register?referrer=${this.props.affiliate.affiliate_id}`}>Sign up</a> to their network through this link to also receive an automatic 10% bonus on your first affiliate payment!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Refer;