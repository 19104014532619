import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoJS from 'crypto-js';
import CryptoAES from 'crypto-js/aes';
import moment from 'moment';

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png';
import dotPattern from '../../assets/images/dot-pattern.png';
import loginVideo from '../../assets/images/login.mp4';
import OTPprompt from './OTP/OTPprompt';
import OTPsetup from './OTP/OTPsetup';
import OTPsuccess from './OTP/OTPsuccess';
import OTPcheck from './OTP/OTPcheck';
import LoginOTP from './OTP/LoginOTP';

import './Login.css';
import { verifyOTPToken } from '../../assets/JS/functions';

class Login extends Component {

    constructor(props) {
        super(props);
        this.formLogin = React.createRef();
        this.email = React.createRef();
        this.password = React.createRef();
    }

    state = {
        dataLoaded: true,
        otpPrompt: false,
        showSetup: false,
        otpVerified: false,
        otpEnabled: false,
        showLoginOTP: false,
        isMobileAdded: false,
        aid: 0,
        ld: 0,
        oid: 0,
        z: 0,
        p: 10,
        code: false,
        message: '',
        t: false
    }

    _isMounted;

    validate = () => {

        /* Check required fields */
        const input_email = this.email.current;
        const input_password = this.password.current;

        if (input_email.checkValidity() === false || input_password.checkValidity() === false) {
            return false;
        }
        else {
            return true;
        }
    }

    login = (e) => {
        e.preventDefault();

        if (this.validate()) {
            /* This is where we do the login */
            this.setState({
                dataLoaded: false
            });

            let original_affiliate_id;
            let logins_from_device;
            // let otpEnabled;

            localStorage.getItem('mb-oid') ? original_affiliate_id = localStorage.getItem('mb-oid') : original_affiliate_id = 0
            localStorage.getItem('mb-ld') ? logins_from_device = localStorage.getItem('mb-ld') : logins_from_device = 0

            const data = {
                "email": e.target.email.value,
                "password": e.target.password.value,
                "ld": logins_from_device,
                "oid": original_affiliate_id
            }

            fetch(`${process.env.REACT_APP_API_URL}/authenticationApp`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(
                    async (result) => {

                        if (result["success"] === true) {

                            /* Set the localStorage for authentication and authorization */
                            const token = result["mb-app-token"];
                            const affiliateID = result["mb-aid"];
                            const loginsFromDevice = result["mb-ld"];
                            const originalAffiliateId = result["mb-oid"];
                            const priority = result["mb-p"];
                            const otpFlag = result["mb-o"];
                            const otpEnabled = result["otp_enabled"];
                            const t = CryptoAES.encrypt(result["mb-app-token"], CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString();

                            const otpPromptDate = result["otp_prompt_date"];
                            const countryVerified = result["country_verified"];

                            const now = moment();
                            const difference = now.diff(moment(otpPromptDate), 'days');

                            localStorage.setItem('mb-ld', loginsFromDevice);
                            localStorage.setItem('mb-oid', originalAffiliateId);
                            localStorage.setItem('mb-auth-token', token);

                            if (otpEnabled) {
                                //Render OTP input screen                                
                                this.setState({ otpEnabled: true, aid: affiliateID, z: t });
                            } else if (!otpEnabled) {
                                // If log in request is from different country and 2FA is not set, generate and verify OTP
                                if (!countryVerified) {
                                    return this.setState({ aid: affiliateID, z: t, showLoginOTP: true, isMobileAdded: result["is_mobile_added"] });
                                }

                                if (otpFlag === 123 || priority > 2) {
                                    // If priority is NOT high or medium, or they have the flag we show the prompt based on the prompt date, but they are technically logged in wih mb-t
                                    localStorage.setItem('mb-aid', affiliateID);
                                    localStorage.setItem('mb-t', t);
                                    if (difference > 30) {
                                        this.setState({ otpPrompt: true, aid: affiliateID, p: priority, o: otpFlag, z: t });
                                    } else {
                                        this.setState({ aid: affiliateID }, () => {
                                            this.props.pageReloaded();
                                        });
                                    }
                                } else {
                                    // If priority is high or medium, force prompt
                                    this.setState({ otpPrompt: true, aid: affiliateID, p: priority, o: otpFlag, z: t });
                                }

                            }
                        } else {
                            // if (result["allow_access"] === false) {
                            //     window.location.replace('https://maxbounty.com/');
                            // } else if(result["allow_access"] === true){
                            this.props.showSystemMessage("error", result.errors.message);
                            // }
                        }
                        if (this._isMounted) {
                            this.setState({
                                dataLoaded: true
                            });
                        }
                    },
                    (error) => {
                        this.props.showSystemMessage("error", "Connection error.");
                        this.setState({
                            dataLoaded: true
                        });
                    }
                )

        } else {
            /* Forces html5 form validation */
            const formLogin = this.formLogin.current;
            formLogin.reportValidity();
        }
    }

    handleOTPpromptChange = (passedValue) => {
        // Value coming from OTP option selection 'y'/'n' -- Doesn't matter what user chose but the prompt_date should be recorded

        passedValue === 'y' ? this.setState({ showSetup: true }) : this.setState({ showSetup: false }, () => {
            localStorage.setItem('mb-aid', this.state.aid);
            this.recordThePrompt();
            this.props.pageReloaded();
        });
    }

    recordThePrompt = () => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/authenticationApp/savePromptDate`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    console.log('prompt date is saved');
                } else {
                    console.log('Unable to record the prompt: ', result.errors.message);
                }
            })
            .catch(err => {
                console.log('An internal error occurred while recording prompt date: ', err);
            });
    }

    handleOTPactivation = (success) => {
        localStorage.setItem('mb-aid', this.state.aid);
        localStorage.setItem('mb-t', this.state.z);
        this.setState({ otpVerified: success, showSetup: false, otpPrompt: false });
    }

    handleOTPverification = () => {
        localStorage.setItem('mb-aid', this.state.aid);
        localStorage.setItem('mb-t', this.state.z);
        window.location.reload();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    reloadThePage = () => {
        this.props.pageReloaded();
    }

    closeloginOTP = () => {
        this.setState({ showLoginOTP: false, dataLoaded: true })
    }

    handleCodeVerification = async (code) => {
        await verifyOTPToken(code).then(result => {
            if (result["success"]) {
                // this.setState({ ...this.state, showLoginOTP: false, code: code }, () => {
                this.handleOTPverification();
                // });
            } else {
                this.setState({ message: result.errors.message });
            }
        });
    }

    render() {

        const basicLogin = (
            <form
                onSubmit={this.login}
                className="form-signin"
                ref={this.formLogin}>
                <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="" width="100" />
                <p className="font-italic text-white pb-2">Affiliate Login</p>
                <label htmlFor="inputEmail" className="sr-only">Email address</label>
                <input
                    ref={this.email}
                    name="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    autoFocus=""
                    type="email"
                    required />
                <label htmlFor="inputPassword" className="sr-only">Password</label>
                <input
                    ref={this.password}
                    name="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    type="password"
                    required />
                {this.state.dataLoaded ?
                    <button className="btn btn-lg btn-primary btn-block">Sign in</button>
                    :
                    <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                }
                <Link to="/register">Don't have an account? Register now.</Link><br></br>
                <Link to="/forgot">Forgot your password?</Link>
                <p className="mt-5 mb-3 text-muted">MaxBounty Inc. [{window.globalConfig.wsid || 0}]</p>
            </form>
        );

        let activeScreen;

        if (this.state.otpEnabled) {
            activeScreen = <OTPcheck otpVerified={this.handleOTPverification} showSystemMessage={this.props.showSystemMessage} />
        } else if (this.state.otpPrompt && !this.state.showSetup) {
            activeScreen = <OTPprompt optionChanged={this.handleOTPpromptChange} showSystemMessage={this.props.showSystemMessage}
                pageReloaded={this.reloadThePage} p={this.state.p} o={this.state.o}
            />
        } else if (this.state.showSetup === true) {
            activeScreen = <OTPsetup
                tokenVerified={this.handleOTPactivation}
                showSystemMessage={this.props.showSystemMessage}
                pageReloaded={this.reloadThePage}
                forced={this.props.forced} />
        } else if (this.state.showLoginOTP) {
            activeScreen = <LoginOTP showLoginOTP={this.state.showLoginOTP}
                isMobileAdded={this.state.isMobileAdded}
                verifyCode={this.handleCodeVerification}
                aid={this.state.aid}
                errorMessage={this.state.message}
                modalClosed={this.closeloginOTP}
            />
        } else if (this.state.otpVerified) {
            console.log('success');
            activeScreen = <OTPsuccess />
        } else {
            activeScreen = basicLogin;
        }

        return (
            <React.Fragment>
                <section className="login-container text-center d-flex align-items-center" style={{ background: 'RGBA(0,0,0,0.9)' }}>

                    {/* Background Video */}
                    <div style={{ backgroundImage: `URL(${dotPattern})`, backgroundRepeat: 'repeat', height: '100%', width: '100%', position: 'absolute', zIndex: '9' }}></div>
                    <video autoPlay muted loop className="login-video">
                        <source src={loginVideo} type="video/mp4" />
                    </video>

                    <div className="form-signin" style={{ maxWidth: "500px" }}>
                        {activeScreen}
                    </div>

                </section>

            </React.Fragment>
        );
    }
}

export default withRouter(Login);