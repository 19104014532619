import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../component/UI/Loading/Loading';
import { clearLocalStorageAndReload, verifyToken } from '../../../assets/JS/functions';
import moment from 'moment';

class ReportReversals extends Component {

    state = {
        dataLoaded: false,
        report: [],
        campaign: []
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        });

        const mbToken = localStorage.getItem('mb-auth-token');

        // For the API, we need to convert this to the next day. As the query look at anything less than. This is to ensure we get midnight.
        let realEndDate = moment(this.props.endDate).add(1, "d").format('YYYY-MM-DD');

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/reversals?startDate=${this.props.startDate}&endDate=${realEndDate}&page=all`

        /* Get the affiliate object from the DB and populate the state information */
        verifyToken().then(result => {
            if (result === true) {
                fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                // Set the parent Reports.js state with the total pages for Pagination.
                                this.props.handleTotalPages(result.total_pages);
                                // Update the current report with the data.
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report
                                });
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportReversals: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    getReportOutput = () => {

        let result = this.state.report;

        return result.slice(this.props.startRow, this.props.endRow).map(item => {
            return (
                <tr>
                    <td>{moment.utc(item.reverse_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{item.status}</td>
                    <td><Link to={`/campaign/${item.campaign_id}`}>{item.name}</Link></td>
                    <td>{moment.utc(item.lead_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{item.rate_type}</td>
                    <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                    <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.earnings)}</td>
                </tr>
            )
        });
    }

    render() {

        return (
            <table className="table table-striped table-card table-hover">
                <thead>
                    <tr>
                        <th scope="col">Reverse Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Campaign</th>
                        <th scope="col">Lead Date</th>
                        <th scope="col">CPA Type</th>
                        <th scope="col" className="text-right">Sales</th>
                        <th scope="col" className="text-right">Earnings</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.dataLoaded ?
                        <React.Fragment>
                            {this.state.report.length === 0 ?
                                <tr>
                                    <td colSpan="7">No stats for this date range.</td>
                                </tr>
                                :
                                <React.Fragment>
                                    {this.getReportOutput()}
                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <tr>
                            <td colSpan="10"><Loading /></td>
                        </tr>
                    }

                </tbody>
            </table>
        );
    }
}

export default ReportReversals;