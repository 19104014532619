import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Logo from './Logo/Logo';
import SearchCampaigns from './SearchCampaign/SearchCampaigns';
import AccountDropdown from '../DropdownMenus/AccountDropdown/AccountDropdown'
import NewsDropdown from '../DropdownMenus/NewsDropdown/NewsDropdown'

import maxmoneyCoin from '../../../assets/images/maxmoney/maxmoney-coin.png';

import './Toolbar.css';

class Toolbar extends Component {

    render() {
        return (
            <nav className="navbar Toolbar fixed-top">
                <Link className="navbar-brand mb-icon" to="/dashboard" style={{ marginRight: "0!important", textAlign: "center!important", size: '1rem !important' }}>
                    {/* MacBounty Logo */}
                    <Logo />
                </Link>
                <div className="SearchCampaigns" ><SearchCampaigns showSystemMessage={this.props.showSystemMessage} /></div>

                <div className="desktopOnly pt-1">
                    <Link to="/maxmoney" className="maxmoney-badge-link">
                        <div className="badge badge-danger mr-3 maxmoney-badge d-flex">
                            <div className="mr-1 d-flex align-items-center">{parseFloat(this.props.affiliate.maxmoney).toFixed(2)}</div>
                            <img src={maxmoneyCoin} style={{ maxWidth: '15px', width: '100%' }} alt="MaxMoney Coin" />
                        </div>
                    </Link>
                </div>

                <div className="desktopOnly">
                    <NewsDropdown showSystemMessage={this.props.showSystemMessage} />
                </div>

                <div className="desktopOnly">
                    <AccountDropdown />
                </div>

            </nav>
        );
    }

}

export default Toolbar;