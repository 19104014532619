import React, { Component } from 'react';
import Cookies from 'js-cookie';

class OttLogin extends Component {
    
    state = { 
        _mounted:false,
        token: Cookies.get('OTT-AUTH-TOKEN'),
        aid: Cookies.get('OTT-AID'),
        t: Cookies.get('OTT-T')
    }

    componentDidMount(){
        if (this.state.token !== '' && this.state.aid !== '' && this.state.t !== ''){
            localStorage.setItem('mb-auth-token', this.state.token);
            localStorage.setItem('mb-aid', this.state.aid);
            localStorage.setItem('mb-t', this.state.t);
            this.setState({
                ...this.state,
                _mounted: true
            })
        }        
    }

    refreshApp = () => {
        window.location.href = process.env.REACT_APP_PORTAL_URL;
    }

    render() {
        return ( 
            this.state._mounted ?
                <div>{this.refreshApp()}</div>
            :
                <div>Logging in</div>
        );
    }

}
 
export default OttLogin;