import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AlertTriangle } from 'react-feather';

class SelectPaymentModal extends Component {
    render() {
        return (<div>
            <div className="modal-header">
                <h6 className="modal-title">Select Payment Method</h6>
                <button type="button" className="close" onClick={this.props.modalClosed}>
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div className="modal-body">
                <div>
                    <div className="text-center mb-3"><AlertTriangle color="red" size={25} /></div>
                    <p style={{ fontSize: "14px" }}>You have not yet selected a payment method. You can do this in Settings by clicking the button below. If you do not set a payment method, you will not be paid when you hit the payment threshold.</p>
                </div>

                <div className="text-center">
                    <Link to='/settings' className="btn btn-sm btn-info">Go to Settings</Link>
                </div>
            </div>
        </div>);
    }
}

export default SelectPaymentModal;