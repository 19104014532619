import React, { Component } from 'react';
import { ExternalLink } from 'react-feather';
import { Modal } from 'reactstrap';

class CampaignLandingPages extends Component {

    state = {
        landingPageModalOpen: false,
        landingPageModalImage: ''
    }

    handlePreview = (e, url) => {
        e.preventDefault()

        this.setState({
            landingPageModalOpen: true,
            landingPageModalImage: url
        })
    }

    modalCloseHandler = () => {
        this.setState({
            landingPageModalOpen: false,
            landingPageModalImage: ''
        })
    }
    

    render() {
        return (
            <div className="card my-2">
                <div className="card-header card-header-no-border">
                    Landing Pages
                </div>
                <div className="table-responsive">
                    <Modal isOpen={this.state.landingPageModalOpen} toggle={this.modalCloseHandler} className="modal-lg">
                        <div className="card">
                            <div className="card-body">
                                <img src={this.state.landingPageModalImage} className="w-100" />
                            </div>
                        </div>
                    </Modal>
                    <table className="table">
                        <tbody>
                            {this.props.landingPageSample !== "" &&
                                <tr>
                                    <td>Sample</td>
                                    <td align="right"><a target="_blank" rel="noopener noreferrer" href={this.props.landingPageSample}>Preview <ExternalLink size="14" /></a></td>
                                </tr>
                            }
                            {this.props.landingPages.map(lp => (
                                <tr key={lp.landing_page_id}>
                                    <td>{lp.name} {lp.default_lp === 'Y' && <span>(Default)</span>}</td>
                                    <td align="right"><a href="#" onClick={(e)=>this.handlePreview(e, lp.full)}>Image Preview <ExternalLink size="14" /></a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default CampaignLandingPages;