import React, { Component } from 'react';
import Toolbar from '../../component/Navigation/Toolbar/Toolbar';
import LeftNavbar from '../../component/Navigation/LeftNavigationBar/LeftNavigationBar';
import BottomToolbar from '../../component/Navigation/BottomToolbar/BottomToolbar';
import RightSidebar from '../../component/RightSideBar/RightSideBar';
import AlertPop from '../../component/UI/AlertPop/AlertPop';

import './Layout.css';

class Layout extends Component {

    render() {
        return (
            <React.Fragment>
                {/* Toolbar component */}
                <Toolbar affiliate={this.props.affiliate} showSystemMessage={this.props.showSystemMessage} />
                {/* leftDrawer component */}
                <LeftNavbar affiliate={this.props.affiliate} />
                {/* rightDrawer component */}
                <div><RightSidebar showSystemMessage={this.props.showSystemMessage} affiliate={this.props.affiliate} /></div>

                {/* <BottomNavigationBar /> */}
                <BottomToolbar />
                <AlertPop showSystemMessage={this.props.showSystemMessage} />
                {/*  show children -- the main area */}
                <main className="Content">
                    {this.props.children}
                </main>
            </React.Fragment>
        );
    }

}

export default Layout;