import React, { Component } from 'react';
import Timezones from '../../FormElements/Timezones';
import ContactTime from '../../FormElements/ContactTime';

import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-input-2/lib/style.css';

class FormContact extends Component {

    constructor(props) {
        super(props);
        this.formContact = React.createRef();
        this.phone = React.createRef();
        this.mobile = React.createRef();
        this.im_handle = React.createRef();
        this.contact_time = React.createRef();
        this.timezone = React.createRef();
    }

    state = {
        dataLoaded: true,
        appRestrictToken: ''
    }

    validate = async () => {

        this.setState({
            dataLoaded: false
        });

        /* Check required fields */

        const input_im_handle = this.im_handle.current;
        const input_contact_time = this.contact_time.current;
        const input_timezone = this.timezone.current;
        const mobile = this.props.values.mobile;

        if (mobile.length < 8) {
            this.props.showSystemMessage('error', 'Mobile number must be at least 10 digits long');
            this.setState({
                dataLoaded: true
            });
            return false;
        }

        input_im_handle.setCustomValidity("");

        this.setState({
            dataLoaded: true
        });

        if (input_im_handle.checkValidity() === false || input_contact_time.checkValidity() === false || input_timezone.checkValidity() === false) {
            this.setState({
                dataLoaded: true
            });
            return false;
        }
        if (input_im_handle.value.length > 50) {
            input_im_handle.setCustomValidity('Your Skype handle must be less than 50 characters.');
            this.setState({
                dataLoaded: true
            });
            return false;
        }        

        return true;
    }

    saveAndContinue = async (e) => {
        e.preventDefault()
        let validate = await this.validate();
        if (validate) {
            let name = this.props.values.firstname + " " + this.props.values.lastname;
            await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, 'Contact Info', this.props.values.company, name, this.props.values.address_1, this.props.values.address_2, this.props.values.city, this.props.values.state, this.props.values.country, this.props.values.zipcode, this.props.values.phone, this.props.values.mobile, this.props.values.im_handle, this.props.values.experience_description, this.props.values.experience_level)
            this.props.nextStep()
        } else {
            /* Forces html5 form validation */
            const formContact = this.formContact.current;
            formContact.reportValidity();
        }
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    changeHandler = (event, input) => {
        this.props.handleChange(input)(event);
    }

    render() {
        const { values } = this.props

        return (
            <form ref={this.formContact}>
                <div className="form-row mb-3">
                    <div className="text-left col mb-3">
                        <label htmlFor="mobile" className="text-white">Mobile Phone Number*</label>
                        <PhoneInput
                            inputProps={{
                                required: true
                            }}
                            placeholder="Mobile Number"
                            country={'us'}
                            value={values.mobile}
                            onChange={(e) => this.changeHandler(e, 'mobile')}
                            countryCodeEditable={false}
                        />
                    </div>
                    <div className="text-left col">
                        <label htmlFor="phone" className="text-white">Alternate Phone Number (home, work etc.)</label>
                        <PhoneInput
                            country={'us'}
                            value={values.phone}
                            placeholder="Phone Number"
                            onChange={(e) => this.changeHandler(e, 'phone')}
                            countryCodeEditable={false}
                        />
                    </div>
                </div>


                {/* </div> */}
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="timezone">Your Time Zone*</label>
                    <select
                        ref={this.timezone}
                        onChange={this.props.handleChange('timezone')}
                        defaultValue={values.timezone}
                        className="form-control"
                        required>
                        <option value=""></option>
                        <Timezones />
                    </select>
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="contact_time">When can you be reached?*</label>
                    <select
                        ref={this.contact_time}
                        onChange={this.props.handleChange('contact_time')}
                        defaultValue={values.contact_time}
                        className="form-control"
                        required>
                        <option value=""></option>
                        <ContactTime />
                    </select>
                </div>
                <div className="form-group text-left text-white">
                    <label htmlFor="im_handle">What is your Skype handle?</label>
                    <input
                        ref={this.im_handle}
                        onChange={(e) => {
                            this.im_handle.current.setCustomValidity("")
                            this.props.handleChange('im_handle')(e);
                        }}
                        placeholder="Skype handle"
                        defaultValue={values.im_handle}
                        className="form-control w-50"
                        type="text" />
                </div>
                {this.state.dataLoaded ?
                    <div className="d-flex flex-row-reverse">
                        <div className="pl-2 flex-1">
                            <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Next</button>
                        </div>
                        <div className="flex-1">
                            <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                        </div>
                    </div>
                    :
                    <div className="d-flex flex-row-reverse">
                        <div className="pl-2 flex-1">
                            <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        </div>
                        <div className="flex-1">
                            <button className="btn btn-lg btn-secondary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        </div>
                    </div>
                }
            </form>
        );
    }
}

export default FormContact;