import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'reactstrap';

import Macro from './Macro/Macro';
import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';

class GlobalPostback extends Component {

    state = {
        postbackLoaded: true,
        isHelpModalOpen: false,
        helpText: ''
    }

    // This API call solely verifies if the token is still valid while the component renders
    componentDidMount() {
        verifyToken().then(result => {
            if (result !== true) {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage('error', error.message);
        });
    }

    savePostback = (e) => {
        e.preventDefault();

        this.setState({
            postbackLoaded: false
        });

        // Submit the data        
        const authtoken = localStorage.getItem('mb-auth-token');

        const postbackBody = {
            "type": e.target.postback_type.value,
            "code": e.target.postback_code.value
        }

        // Submit the data
        fetch(`${process.env.REACT_APP_API_URL}/affiliate/globalPostback`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify(postbackBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        this.props.editGlobalPostback(postbackBody.type, postbackBody.code, "success", result.message);
                        this.setState({
                            postbackLoaded: true
                        })
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", result.errors.message);
                        this.setState({
                            postbackLoaded: true
                        })
                    } else {
                        this.props.showSystemMessage("error", "There was an error saving your postback. Please try again.");
                        this.setState({
                            postbackLoaded: true
                        })
                    }
                })
            .catch(error => {
                this.props.showSystemMessage("error", error.message)
                this.setState({
                    postbackLoaded: true
                });
            });
    }

    macroModalHandler = () => {
        this.setState({ isHelpModalOpen: !this.state.isHelpModalOpen });
    }


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.isHelpModalOpen} toggle={this.macroModalHandler} className="modal-lg">
                    <Macro
                        modalClosed={this.macroModalHandler}
                        showSystemMessage={this.props.showSystemMessage}
                    />
                </Modal>
                <div className="row">

                    <div className="col-lg-7 split-left">

                        <div className="card my-2">
                            <div className="card-header justify-content-between d-flex">
                                Global Postback
                        </div>
                            <div className="card-body" style={{ margin: "15px" }}>
                                <div>
                                    <form onSubmit={this.savePostback}>
                                        <div className="alert alert-info">The global postback will be fired every time you generate a lead. If you have defined custom postbacks on specific campaigns, you can choose to fire that one instead in the campaign settings.</div>
                                        <select
                                            name="postback_type"
                                            className="custom-select custom-select-sm mb-3"
                                            defaultValue={this.props.globalPostback.type}>
                                            <option value="">No default set</option>
                                            <option value="L">URL (any campaign tracking type)</option>
                                            <option value="I">HTML (iframe and server campaign tracking only)</option>
                                            {
                                                this.props.globalPostback.type === 'S' &&
                                                <option value="S">Server-Server URL (any campaign tracking type)</option>
                                            }
                                        </select>
                                        <br />
                                        <textarea
                                            name="postback_code"
                                            className="form-control p-2"
                                            rows="3"
                                            defaultValue={this.props.globalPostback.code}></textarea>
                                        {/* <a href="#0">See available macros</a> */}
                                        <button type="button" className="btn btn-sm btn-link" onClick={this.macroModalHandler}>See available macros</button>
                                        <br />
                                        <br />
                                        {this.state.postbackLoaded ?
                                            <button className="btn btn-primary">Save Global Postback</button>
                                            :
                                            <button className="btn btn-primary" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-5 campaign-right">
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default GlobalPostback;