import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import CampaignCard from '../CampaignCards/CampaignCard/CampaignCard'
import CampaignCardLoading from '../CampaignCards/CampaignCardLoading/CampaignCardLoading'
import CampaignCardEmpty from '../CampaignCards/CampaignCardEmpty/CampaignCardEmpty';
import Loading from '../UI/Loading/Loading';
import Listview from '../../container/Browse/Listview/Listview';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';

class CampaignSet extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        infiniteLoaded: true,
        campaignCards: []
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadContent(this.props.page, this.props.limit);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.page !== this.props.page) {

            if (this._isMounted) {
                this.setState({
                    infiniteLoaded: false
                });
            }
            this.loadContent(this.props.page, this.props.limit);
        }
    }

    loadContent = (page, limit) => {
        // Get the affiliate's API token
        const mbToken = localStorage.getItem('mb-auth-token');

        /* Get the affiliate object from the DB and populate the state information, only after verifying the token validity */
        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/campaigns/${this.props.category}?page=${page}&limit=${limit}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {

                            // This line keeps the existing campaigncards while adding more creating duplication. Remove it if not absolutely necessary
                            // let campaignCardList = [...result.campaigns];

                            let campaignCardList = [...this.state.campaignCards, ...result.campaigns];

                            if (this._isMounted) {
                                this.setState({
                                    dataLoaded: true,
                                    infiniteLoaded: true,
                                    campaignCards: campaignCardList
                                });
                            }

                        } else if (result["success"] === false) {

                            if (result.code !== 401) {
                                this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");

                                if (this._isMounted) {
                                    this.setState({
                                        dataLoaded: true,
                                        infiniteLoaded: true
                                    });
                                }
                            }
                        } else {
                            this.props.showSystemMessage("error", result.errors.message);

                            if (this._isMounted) {
                                this.setState({
                                    dataLoaded: true,
                                    infiniteLoaded: true
                                });
                            }
                        }
                    });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage("error", "Error while fetching campaigns.");
            console.log(error.message);

            if (this._isMounted) {
                this.setState({
                    dataLoaded: true,
                    infiniteLoaded: true
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let selectedView, infinityLoading;
        if (this.state.dataLoaded && this.state.campaignCards.length === 0) {
            return (
                <div style={{ fontSize: "16px", margin: "10px" }}>No campaigns found for this category.</div>
            );
        }
        if (this.props.view) {
            if (this.props.view === 'gridView' || !this.props.view) {
                selectedView = this.state.campaignCards.map(campaign =>
                    (
                        <CampaignCard
                            key={campaign.campaign_id}
                            campaignId={campaign.campaign_id}
                            name={campaign.name}
                            rate={campaign.rate_text}
                            rateType={campaign.rate_type}
                            thumbnail={campaign.thumbnail}
                            highlighted={campaign.highlight} />
                    )
                )
            } else if (this.props.view === 'listView') {
                selectedView = <Listview campaigns={this.state.campaignCards} />;
            }
        } else {
            selectedView = this.state.campaignCards.map(campaign =>
                (
                    <CampaignCard
                        key={campaign.campaign_id}
                        campaignId={campaign.campaign_id}
                        name={campaign.name}
                        rate={campaign.rate_text}
                        rateType={campaign.rate_type}
                        thumbnail={campaign.thumbnail}
                        highlighted={campaign.highlight} />
                )
            )
        }

        if (!this.state.infiniteLoaded) {
            if (this.props.view === 'gridView' || !this.props.view) {
                infinityLoading = <React.Fragment>
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                    <CampaignCardLoading />
                </React.Fragment>
            } else {
                infinityLoading = <button className="btn btn-lg btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
            }
        }

        let divContainerClasses;

        if (this.props.view === 'gridView' || !this.props.view) {
            divContainerClasses = "mb-campaign-container d-flex flex-wrap";
        } else if (this.props.view === 'listView') {
            divContainerClasses = "mb-campaign-container flex-wrap";
        }

        return (
            <React.Fragment>
                {this.state.dataLoaded
                    ?
                    <div className={divContainerClasses}>
                        {/* Instead of passing same component multiple times, we will loop through all the campaigns here after fetchign them */}

                        {selectedView}

                        {infinityLoading}

                        {/* For flexbox formatting */}
                        {(this.props.view === 'gridView' || !this.props.view) && <React.Fragment>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                            <CampaignCardEmpty></CampaignCardEmpty>
                        </React.Fragment>}
                    </div>
                    :
                    <Loading />
                }
            </React.Fragment>
        );
    }
}

export default withRouter(CampaignSet);