import React, { Component } from 'react';

import './FormProgressBar.css';

class FormProgressBar extends Component {

    render() { 

        const { values } = this.props

        let progress = `${((values.step-1) / 6)*100}%`;

        return ( 
            <div className="progress signup-progress mb-3">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width: progress}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        );
    }

}
 
export default FormProgressBar;