export default {
    times: [
        {
            value: 6,
            text: "Anytime"
        },
        {
            value: 1,
            text: "Early Morning"
        },
        {
            value: 2,
            text: "Late Morning"
        },
        {
            value: 3,
            text: "Early Afternoon"
        },
        {
            value: 4,
            text: "Late Afternoon"
        },
        {
            value: 5,
            text: "Evening"
        }
    ]
}