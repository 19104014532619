import React, { Component } from 'react';
import { Calendar } from 'react-feather';
import { Link } from 'react-router-dom';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CheckCircle } from 'react-feather';
import Moment from 'react-moment';

import maxmoneySymbol from '../../../assets/images/maxmoney/maxmoney-symbol.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MaxMoneyPromo extends Component {

    state = {
        registerLoaded: true,
        campaignListModal: false,
        registerStatus: false,
        registerStep: false
    }

    constructor(props) {
        super(props);
        if (props.data.registered === true) {
            this.state.registerStatus = true;
        } else {
            this.state.registerStatus = false;
        }
    }

    toggleCampaignListModal = () => {
        this.setState({
            campaignListModal: !this.state.campaignListModal
        });
    }

    getTitle = () => {
        if (this.props.data.promo_name) {
            // If there is a name, display the name
            return this.props.data.promo_name;
        } else {
            // If there is no name, check to see the type
            if (this.props.data.promo_type === "NEVER_RUN") {
                // If the type is never_run, show this default message:
                return "Run any offer you've never promoted before."
            } else if (this.props.data.promo_type === "OFFER_SPECIFIC") {
                // If the type is offer_specific, show a list of the offers
                if (this.props.data.campaigns.length > 0) {
                    return this.props.data.campaigns.map(campaign => (
                        <React.Fragment>
                            <Link className="d-block" to={`/campaign?oid=${campaign.campaign_id}`} target="_blank">{campaign.name}</Link>
                        </React.Fragment>
                    ));
                }
            } else if (this.props.data.promo_type === "BEAT_YOUR_BEST"){
                return "Beat your previous earnings!"
            }
        }
    }

    getCampaignList = () => {
        if (this.props.data.promo_type === "OFFER_SPECIFIC") {
            if (this.props.data.promo_name) {
                return (
                    <React.Fragment>
                        <a href="#0" className="dark d-block" onClick={this.toggleCampaignListModal}>Eligible campaigns</a>
                        <Modal isOpen={this.state.campaignListModal} toggle={this.toggleCampaignListModal} size='md'>
                            <ModalHeader toggle={this.toggleCampaignListModal}>Eligible Campaigns</ModalHeader>
                            <ModalBody className="p-0">
                                <table className="table table-hover m-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">ID #</th>
                                            <th scope="col">Campaign Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.data.campaigns.map(campaign => (
                                            <tr key={campaign.campaign_id}>
                                                <td className="text-left">{campaign.campaign_id}</td>
                                                <td className="text-left">
                                                    <Link to={`/campaign/${campaign.campaign_id}`} title={campaign.name}>{campaign.name}</Link>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggleCampaignListModal}>Close</Button>
                            </ModalFooter>
                        </Modal>
                    </React.Fragment>
                );
            }
        }
    }

    getRewards = (promo) => {
        if (this.props.data.promo_type !== "BEAT_YOUR_BEST") {
            return (
                <div className="font-weight-bold" style={{ color: '#d0a85b' }}>Earn {promo.reward * 100}% MaxMoney</div>
            )
        } else {
            // We need to use BYB data. Also check if it's ONGOING or if it's FUTURE
            let {rewards, best_period, current_earnings } = this.props.data.byb_rewards;            

            if (this.props.type === 'ongoing'){

                let textArray = [
                    `You've currently earned $${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(current_earnings)} during this promo:`
                ]
                let congratsString = ''

                rewards.forEach( reward => {
                    let amount_remaining = ((1+reward.reward_threshold)*best_period) - current_earnings;
                    if (amount_remaining <= 0){
                        congratsString = `Congratulations! You are now earning ${reward.reward_pct*100}% in MaxMoney on all your earnings during this promo period!`
                    }
                });

                textArray.push(congratsString);

                rewards.forEach( reward => {
                    let amount_remaining = ((1+reward.reward_threshold)*best_period) - current_earnings;
                    if (amount_remaining >= 0 ){
                        textArray.push(`Earn $${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(((1+reward.reward_threshold)*best_period) - current_earnings)} more during the promo period to get ${reward.reward_pct * 100}% in MaxMoney`)
                    }
                })

                return textArray.map( text => (
                    <div className="font-weight-bold" style={{ color: '#d0a85b' }}>{text}</div>
                ))                               

            } else if (this.props.type === 'past'){

                let textArray = [
                    `You earned $${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(current_earnings)} during this promo:`
                ]
                let congratsString = ''

                rewards.forEach( reward => {
                    let amount_remaining = ((1+reward.reward_threshold)*best_period) - current_earnings;
                    if (amount_remaining <= 0){
                        congratsString = `Congratulations! You earned ${reward.reward_pct*100}% in MaxMoney on all your earnings during this promo period!`
                    }
                });

                textArray.push(congratsString);

                rewards.forEach( reward => {
                    let amount_remaining = ((1+reward.reward_threshold)*best_period) - current_earnings;
                    if (amount_remaining >= 0 ){
                        textArray.push(`You needed to earn $${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(((1+reward.reward_threshold)*best_period) - current_earnings)} more during the promo period to get ${reward.reward_pct * 100}% in MaxMoney`)
                    }
                })

                return textArray.map( text => (
                    <div className="font-weight-bold" style={{ color: '#d0a85b' }}>{text}</div>
                ))         

            } else {
                return rewards.map( reward => (
                    <div className="font-weight-bold" style={{ color: '#d0a85b' }}>Earn more than ${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format((1+reward.reward_threshold)*best_period)} during the promo period to get {reward.reward_pct * 100}% in MaxMoney</div>
                ))
            }
        }
    }

    registerStep = () => {
        this.setState({
            campaignListModal: false,
            registerStatus: false,
            registerStep: true
        });
    }

    register = () => {
        /* Send request to register to the promotion */
        const mbToken = localStorage.getItem('mb-auth-token');
        const registerBody = {
            'promo_id': this.props.data.promo_id,
            'promo_type': this.props.data.promo_type
        }

        this.setState({
            registerLoaded: false
        });

        /* Get the affiliate object from the DB and populate the state information */
        fetch(`${process.env.REACT_APP_API_URL}/maxmoney/register`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            },
            body: JSON.stringify(registerBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        this.setState({
                            registerLoaded: true,
                            campaignListModal: false,
                            registerStatus: true,
                            registerStep: this.state.registerStep
                        })
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", result.errors.message);
                        this.setState({
                            registerLoaded: true
                        })
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                        this.setState({
                            registerLoaded: true
                        })
                    }
                },
                (error) => {
                    this.props.showSystemMessage("error", "Connection error.");
                    this.setState({
                        registerLoaded: true
                    });
                }
            );
    }

    render() {

        const promo = this.props.data;

        return (
            <React.Fragment>

                <div className="maxmoney-event d-flex">
                    <div className="d-flex align-items-center text-center font-weight-bold text-success maxmoney-event-date">
                        <Calendar size="14" /> &nbsp;
                        <Moment format="MMM DD">{promo.start_date}</Moment> &nbsp; - &nbsp; <Moment format="MMM DD">{promo.end_date}</Moment>
                    </div>

                    <div className="maxmoney-item d-flex align-items-center">
                        <div className="text-left">
                            <strong className="d-block">
                                {this.getTitle()}
                            </strong>
                            {this.getCampaignList()}
                            {this.getRewards(promo)}                            
                        </div>
                    </div>

                    {
                        promo.top_earnings > promo.top_earnings_threshold && promo.promo_type !== 'BEAT_YOUR_BEST' && (
                            <div className="maxmoney-promo-earnings d-flex align-items-center">
                                <div>
                                    Earnings by Top Affiliate<br></br>
                                    <img className="maxmoney-promo-symbol" src={maxmoneySymbol} alt="MaxMoney Symbol" /> <span className="maxmoney-gold">{parseFloat(promo.top_earnings).toFixed(2)}</span>
                                </div>
                            </div>
                        )
                    }

                    {
                        promo.total_points > 0 && (
                            <div className="maxmoney-promo-earnings d-flex align-items-center">
                                <div>
                                    Your Earnings<br></br>
                                    <img className="maxmoney-promo-symbol" src={maxmoneySymbol} alt="MaxMoney Symbol" /> <span className="maxmoney-gold">{parseFloat(promo.total_points).toFixed(2)}</span>
                                </div>
                            </div>
                        )
                    }

                    <div className="maxmoney-optin d-flex align-items-center">
                        {
                            !this.state.registerStep && !this.state.registerStatus && this.props.type !== 'past' && (
                                <button className="btn btn-primary btn-sm" onClick={this.registerStep}>Register</button>
                            )
                        }
                        {
                            this.state.registerStep && !this.state.registerStatus && (
                                <React.Fragment>
                                    {this.state.registerLoaded ?
                                        <button className="btn btn-primary btn-sm" onClick={this.register}>Agree to Terms</button>
                                        :
                                        <button className="btn btn-primary btn-sm" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                    }
                                </React.Fragment>

                            )
                        }
                        {
                            this.state.registerStatus && (
                                <CheckCircle size="20" className="text-info" data-tip="Registered" />
                            )
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default MaxMoneyPromo;