import React, { Component } from 'react';

import { Modal } from 'reactstrap';
import IntroModal from './IntroModal/IntroModal';
import SelectPaymentModal from './SelectPaymentModal/SelectPaymentModal';
import InvalidEmailModal from './InvalidEmailModal/InvalidEmailModal';
import MissingMobileModal from './MissingMobileModal/MissingMobileModal';
import TipaltiMissingDocs from './TipaltiMissingDocs/TipaltiMissingDocs';

class Announcements extends Component {

    state = {
        showIntroSurvey: false,
        showPaymentModal: false,
        showInvalidEmailModal: false,
        showMissingMobileModal: false,
        showTipaltiMissingDocksModal: false,
    }

    componentDidMount() {
        // Skipping to call verifyToken for now as this component will load right after user logs in. Will add later if required.

        // Verify if paymentmodal needs to be shown
        if (this.props.paymentInformation.tmp_payoneer === 0) {
            if (this.props.paymentInformation.method === 'None') {
                if (!this.props.paymentInformation.platform) {
                    this.setState({ showPaymentModal: true });
                }
            }
        }

        if (this.props.affiliateFlags && this.props.affiliateFlags.includes(121)) {
            this.setState({ showInvalidEmailModal: true });
        }

        if (this.props.affiliateFlags && this.props.affiliateFlags.includes(148)) {
            this.setState({ showTipaltiMissingDocksModal: true });
        }

        if (!this.props.mobilePhone) {
            this.setState({ ...this.state, showMissingMobileModal: true });
        }

        // Checking if target country and target verticals are set
        const authToken = localStorage.getItem('mb-auth-token');
        fetch(`${process.env.REACT_APP_API_URL}/introSurvey/targetCountryAndVerticalStatus`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result['success'] === true) {
                    if (result['target_country_survey_complete'] === false && result["target_vertical_survey_complete"] === false) {
                        this.setState({ showIntroSurvey: true });
                    }
                } else if (result["success"] === false) {
                    this.props.showSystemMessage("error", result.errors.message);
                }
            })
            .catch(error => {
                console.log('error at Dashboard inside: ', error);
                this.props.showSystemMessage("error", "Error while fetching modals");
            });
    }

    introModalCloseHandler = () => {
        this.setState({ showIntroSurvey: false });
    }

    paymentModalCloseHandler = () => {
        this.setState({ showPaymentModal: false });
    }

    invalidEmailModalCloseHandler = () => {
        this.setState({ showInvalidEmailModal: false });
    }

    mobileCloseHandler = () => {
        this.setState({ showMissingMobileModal: false });
    }

    tipaltiMissingDocksCloseHandler = () => {
        this.setState({ showTipaltiMissingDocksModal: false });
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.showIntroSurvey} toggle={this.introModalCloseHandler}>
                    <IntroModal showSystemMessage={this.props.showSystemMessage}
                        modalClosed={this.introModalCloseHandler} />
                </Modal>
                <Modal isOpen={this.state.showPaymentModal} toggle={this.paymentModalCloseHandler}>
                    <SelectPaymentModal showSystemMessage={this.props.showSystemMessage}
                        modalClosed={this.paymentModalCloseHandler} />
                </Modal>
                <Modal isOpen={this.state.showInvalidEmailModal} toggle={this.invalidEmailCloseHandler}>
                    <InvalidEmailModal modalClosed={this.invalidEmailModalCloseHandler} />
                </Modal>
                <Modal isOpen={this.state.showMissingMobileModal} toggle={this.mobileCloseHandler}>
                    <MissingMobileModal modalClosed={this.mobileCloseHandler} />
                </Modal>
                <Modal isOpen={this.state.showTipaltiMissingDocksModal} toggle={this.tipaltiMissingDocksCloseHandler}>
                    <TipaltiMissingDocs modalClosed={this.tipaltiMissingDocksCloseHandler} />
                </Modal>
            </div>
        );
    }
}

export default Announcements;