import React, { Component } from 'react';

import maxbountyLogoSquare from '../../../assets/images/MaxBounty_Square.png';

class OTPSuccess extends Component {

    clickHandler = () => {
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                {!this.props.fromProfile && <div className="panel">
                    <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="" width="100" />
                </div>}
                <div className="card text-left">
                    <div className="card-header font-weight-bold">
                        2-step Verification Setup
                    </div>
                    <div className="card-body">
                        <div className="alert alert-success">
                            Your Google 2-factor Authentication has been Verified. From now on, You will require this 6-digit code whenever you log in. 2-Factor Authentication settings can be found on the profile page within your MaxBounty dashboard. If you ever lose your code/phone, contact your Affiliate Manager.
                        </div>
                        <button className="btn btn-primary" onClick={this.clickHandler}>Take me to my dashboard</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OTPSuccess;