import React, { Component } from 'react';
import { Prompt } from 'react-router';

import { yesNo } from '../../../assets/JS/functions.js';

class PromotionalMethods extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.promotion = React.createRef();        
        // this.incentive_description = React.createRef();
        this.state = {
            editing: false,
            isFormHalfFilled: false,    // To track halfFilled unsaved forms
            promotionalMethods: {
                promotion: this.props.methods.promotion,
                website: this.props.methods.website,
                incentive: {
                    value: this.props.methods.incentive,
                    text: yesNo(this.props.methods.incentive)
                }
                // ,
                // incentive_description: this.props.methods.incentive_description
            }
        }
    }

    validate = () => {
        const input_promotion = this.promotion.current;        
        // const input_incentive_description = this.incentive_description.current;

        if (input_promotion.value.length < 50) {
            input_promotion.setCustomValidity('Your description must be at least 50 characters.');
        }
        // else if (this.state.promotionalMethods.incentive.value === 'Y' && input_incentive_description.value === '') {
        //     input_incentive_description.setCustomValidity('You must describe the incentives you use.');
        //     return false;
        // }
        else {
            return true;
        }
    }

    setEditing = (e) => {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
            promotionalMethods: this.state.promotionalMethods,
            isFormHalfFilled: false
        });
    }

    inputChangeHandler = () => {
        this.setState({ isFormHalfFilled: true });
    }

    saveSettings = (e) => {
        e.preventDefault();

        if (this.validate()) {
            /* Do form submission */
            this.setState({
                editing: !this.state.editing,
                promotionalMethods: {
                    promotion: e.target.promotion.value,
                    website: e.target.website.value,          
                    incentive: this.state.promotionalMethods.incentive
                    // ,          
                    // incentive_description: e.target.incentive_description.value
                }
            }, () => {
                const authtoken = localStorage.getItem('mb-auth-token');

                fetch(`${process.env.REACT_APP_API_URL}/affiliate/promotionalDetails`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    },
                    body: JSON.stringify({
                        promotion: this.state.promotionalMethods.promotion,
                        website: this.state.promotionalMethods.website
                        // ,                        
                        // incentive_description: this.state.promotionalMethods.incentive_description
                    })
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.props.showSystemMessage("success", result.message);
                        } else if (result["success"] === false) {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    })
                    .catch(error => {
                        this.props.showSystemMessage("error", error.message);
                    });
            });
        } else {
            /* Forces html5 form validation */
            const form = this.form.current;
            // const input_incentive_description = this.incentive_description.current;
            const input_promotion = this.promotion.current;

            form.reportValidity();

            /* Reset any previous custom validity settings to valid. If you don't do this the form will remain invalid. This is different than
            the registration form because we're doing this on form submit rather than on button click. */
            input_promotion.setCustomValidity('');
            // input_incentive_description.setCustomValidity('');
        }
    }

    render() {
        return (
            <div className="card my-2 max-width-750">
                <Prompt
                    when={this.state.isFormHalfFilled}
                    message="Are you sure you want to leave? Changes you made may not be saved."
                />
                <form
                    ref={this.form}
                    onSubmit={this.saveSettings}>
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Promotional Methods</div>
                        {this.props.showEdit &&
                            <div className="text-right">
                                {this.state.editing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                                }
                            </div>
                        }
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td className="no-border">How do you promote campaigns?</td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {this.state.editing === false ?
                                            <div>{this.state.promotionalMethods.promotion}</div>
                                            :
                                            <React.Fragment>
                                                <textarea
                                                    ref={this.promotion}
                                                    name="promotion"
                                                    className="form-control"
                                                    defaultValue={this.state.promotionalMethods.promotion}
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </textarea>
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Your website URL</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.promotionalMethods.website}</div>
                                            :
                                            <React.Fragment>
                                                <input
                                                    name="website"
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={this.state.promotionalMethods.website}
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </input>
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Does your website provide users with rewards to sign up for campaigns?*</td>
                                    <td>                                        
                                        <div>{this.state.promotionalMethods.incentive.text} - Contact your affiliate manager if you need to change this setting</div>                                           
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>If yes, describe the incentive</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.promotionalMethods.incentive_description}</div>
                                            :
                                            <React.Fragment>
                                                <textarea
                                                    ref={this.incentive_description}
                                                    name="incentive_description"
                                                    className="form-control"
                                                    defaultValue={this.state.promotionalMethods.incentive_description}
                                                    onChange={this.inputChangeHandler}>
                                                </textarea>
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default PromotionalMethods;