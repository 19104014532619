import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import Loading from '../../component/UI/Loading/Loading';

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png';
import dotPattern from '../../assets/images/dot-pattern.png';
import loginVideo from '../../assets/images/login.mp4';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.password = React.createRef();
        this.password2 = React.createRef();
        this.otp = React.createRef();
        this.timer = 0;
    }

    state = {
        dataLoaded: false,
        token: 0,
        submitted: false,
        disableSendCode: false,
        isMobileAdded: false,
        affiliateId: 0,
        managerEmail: '',
        seconds: 60
    }

    componentDidMount() {
        // Get the token

        if (this.props.location.search) {
            let urlParams = queryString.parse(this.props.location.search);

            if (urlParams.token) {
                let token = urlParams.token;

                // If the token exists, fetch data from API
                fetch(`${process.env.REACT_APP_API_URL}/resetPassword?token=${token}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {

                                const { is_mobile_added, manager_email } = result["data"];

                                this.setState({
                                    dataLoaded: true,
                                    token: token,
                                    submitted: false,
                                    isMobileAdded: is_mobile_added,
                                    managerEmail: manager_email
                                });
                            } else {
                                this.setState({
                                    dataLoaded: true,
                                    token: 0,
                                    submitted: false
                                });
                            }
                        },
                        (error) => {
                            this.props.showSystemMessage("error", "Connection error.")
                            this.setState({
                                dataLoaded: true,
                                token: 0,
                                submitted: false
                            });
                        }
                    ).catch(error => {
                        this.props.showSystemMessage("error", error)
                        this.setState({
                            dataLoaded: true,
                            token: 0,
                            submitted: false
                        });
                    });
            }
        }
    }

    validate = (password, password2, code) => {

        /* Reset any previous custom validity settings to valid */
        password.setCustomValidity('');
        password2.setCustomValidity('');
        code.setCustomValidity('');

        // Validate the password
        const validatePassword = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{7,30}$/.test(password);

        if (password.checkValidity() === false || password2.checkValidity() === false) {
            return false;
        } else if (password.value !== password2.value) {
            password2.setCustomValidity('Passwords do not match.');
            return false;
        } else if (!validatePassword && password.value.length < 8) {
            password.setCustomValidity('Your new password must be between 8 and 12 characters long and have letters, numbers and special characters.');
            return false;
        } else if (code.checkValidity() === false || !code.value) {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = (e) => {

        e.preventDefault();

        const token = this.state.token;
        const code = this.otp.current;
        const password = this.password.current;
        const password2 = this.password2.current;

        if (this.validate(password, password2, code)) {
            // Form was validated            
            this.setState({
                submitted: true
            });
            // Make the request to update the password
            const data = {
                "password": password.value,
                "code": code.value,
                "token": token
            }

            fetch(`${process.env.REACT_APP_API_URL}/resetPassword?token=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result["success"] === true) {
                            this.setState({
                                dataLoaded: true,
                                token: 0,
                                submitted: true,
                                success: true
                            })
                        } else {
                            this.setState({
                                dataLoaded: true,
                                submitted: false,
                                success: false
                            }, () => this.props.showSystemMessage('error', 'Incorrect code. Try again.'));
                        }
                    },
                    (error) => {
                        // this.props.showSystemMessage("error", "Connection error.")
                        this.setState({
                            dataLoaded: true,
                            token: 0
                        });
                    }
                )

        } else {
            /* Forces html5 form validation */
            const form = this.form.current;
            form.reportValidity();
        }
    }

    handleSendCode = (event) => {
        event.preventDefault();

        fetch(`${process.env.REACT_APP_API_URL}/resetPassword/generateCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: this.state.token })
        })
            .then(res => res.json())
            .then(result => {
                if (result.success === true) {
                    this.setState({ disableSendCode: true }, () => {
                        this.startCountDown()
                    });
                }
            });
    }

    startTimer = () => {
        let second = this.state.seconds - 1;

        this.setState({ ...this.state, seconds: second });

        if (second === 0) {
            clearInterval(this.timer);
            this.setState({ disableSendCode: false, seconds:60 }, () => this.timer = 0)
        }
    }

    startCountDown = () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.startTimer, 1000);
        }
    }

    render() {

        const newPasswordScreen = (<form
            className="form-signin"
            ref={this.form}>
            <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="MaxBounty" width="100" />

            {this.state.dataLoaded ?
                <React.Fragment>
                    {this.state.token !== 0 ?
                        this.state.isMobileAdded ?
                            <React.Fragment>
                                <p className="text-white pb-2">Please choose a new password.</p>
                                <input
                                    ref={this.password}
                                    name="password"
                                    className="form-control mb-3"
                                    placeholder="New Password"
                                    autoFocus=""
                                    type="password"
                                    required
                                    style={{ borderRadius: '4px' }} />
                                <input
                                    ref={this.password2}
                                    name="password2"
                                    className="form-control mb-3"
                                    placeholder="Re-type Password"
                                    autoFocus=""
                                    type="password"
                                    required
                                    style={{ borderRadius: '4px' }} />
                                <div className='d-flex mb-3'>
                                    <input
                                        ref={this.otp}
                                        name="otp"
                                        className="form-control mr-2"
                                        placeholder="Enter Code"
                                        autoFocus=""
                                        type="text"
                                        required
                                        style={{ borderRadius: '4px', width: "65%" }} />
                                    <button className={this.state.disableSendCode ? `btn btn-secondary btn-sm` :`btn btn-primary btn-sm`}
                                        type='button'
                                        onClick={this.handleSendCode}
                                        disabled={this.state.disableSendCode}
                                        style={{width: "35%"}}
                                    >
                                        {this.state.disableSendCode ? `Sent (${this.state.seconds})` : 'Send Code to Your Phone'}
                                    </button>
                                </div>

                                {this.state.submitted ?
                                    <button className="btn btn-primary btn-block btn-lg m-auto" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                    :
                                    <button
                                        onClick={this.handleSubmit}
                                        type="submit"
                                        className="btn btn-primary btn-block btn-lg m-auto
                                    ">Reset Password</button>
                                }

                            </React.Fragment>
                            :
                            <p className="alert alert-danger">Please contact your affiliate manager at <a href={`mailto:${this.state.managerEmail}`}>{this.state.managerEmail}</a> to reset password</p>

                        :
                        <React.Fragment>
                            {(this.state.submitted && this.state.success) ?
                                <p className="alert alert-success">Your password has been changed.</p>
                                : (this.state.submitted && !this.state.success) ?
                                    <p className="alert alert-danger">There was an error processing your request. Please try again</p>
                                    :
                                    <p className="text-white pb-2">The link you have provided has expired. Please make another request to change your password.</p>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <div className="loading-page-container">
                    <Loading />
                </div>
            }

            <Link to="/">Back to Login</Link><br></br>
            <p className="mt-5 mb-3 text-muted">MaxBounty Inc. </p>
        </form>);

        return (
            <React.Fragment>

                <section className="login-container text-center d-flex align-items-center" style={{ background: 'RGBA(0,0,0,0.9)' }}>

                    {/* Background Video */}
                    <div style={{ backgroundImage: `URL(${dotPattern})`, backgroundRepeat: 'repeat', height: '100%', width: '100%', position: 'absolute', zIndex: '9' }}></div>
                    <video autoPlay muted loop className="login-video">
                        <source src={loginVideo} type="video/mp4" />
                    </video>


                    {newPasswordScreen}
                </section>

            </React.Fragment>
        );
    }
}

export default ResetPassword;