import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FormAccount extends Component {

    constructor(props) {
        super(props);
        this.formAccount = React.createRef();
        this.otherAffiliateId = React.createRef();
        this.otherEmail = React.createRef();
        this.state = {
            dataLoaded: true,
            accountExists: 'no'
        }
    }

    handleAccountOption = (e) => {
        e.preventDefault();

        // Adding existing_Account state to help navigate back and forth
        this.props.handleChange("existing_account")(e);

        if (e.target.value === 'no') {
            this.props.nextStep();
        }
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        let validate = this.validate();

        if (validate) {
            this.props.nextStep();
        } else {
            this.formAccount.reportValidity();
        }
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    validate = async () => {
        this.setState({ dataLoaded: false });

        const otherEmail = this.otherEmail.current;
        otherEmail.setCustomValidity("");

        /* Check if the email follows the rules */
        const emailAccount = otherEmail.value.split("@")[0] + "@";
        const emailDomain = "@" + otherEmail.value.split("@")[1];
        const abuseCheck = emailAccount.includes("abuse");
        const bounceCheck = emailAccount.includes("bounce");
        const blockCheck = emailAccount.includes("block");
        const spamCheck = otherEmail.value.includes("spam");
        const swearCheck = otherEmail.value.includes("fuck");
        const validateEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(otherEmail.value.toLowerCase());

        this.setState({ dataLoaded: true });

        if (otherEmail.checkValidity() === false) {
            return false;
        } else if (emailAccount.length === 0 || emailDomain.length === 0 || abuseCheck || bounceCheck || blockCheck || spamCheck || swearCheck || !validateEmail) {
            otherEmail.setCustomValidity("This email address is invalid.");
            return false;
        } else {
            return true;
        }
    }

    render() {

        const { values } = this.props;

        return (
            <form ref={this.formAccount}>
                {/* <p className="text-white pb-2">Do you already have an account with MaxBounty? If yes, please fill out the following information. If no, click "Next"</p> */}

                {values.existing_account === 'yes' ?
                    <div>
                        <div className="form-row mb-3 text-white">
                            <div className="col text-left">
                                <label htmlFor="other_affiliate_id">Your affiliate ID:</label>
                                <input
                                    ref={this.otherAffiliateId}
                                    onChange={this.props.handleChange('other_affiliate_id')}
                                    placeholder="Your affiliate ID"
                                    defaultValue={values.other_affiliate_id}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-row mb-3 text-white">
                            <div className="col text-left">
                                <label htmlFor="other_email">The E-mail associated with that account:</label>
                                <input
                                    ref={this.otherEmail}
                                    onChange={this.props.handleChange('other_email')}
                                    placeholder="Email"
                                    defaultValue={values.other_email}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        {this.state.dataLoaded ?
                            <div className="d-flex flex-row-reverse">
                                <div className="pl-2 flex-1">
                                    <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Next</button>
                                </div>
                                <div className="flex-1">
                                    <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                                </div>
                            </div>
                            :
                            <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        }
                    </div>
                    :

                    <div>
                        <p className="text-white pb-2">Do you already have an account with MaxBounty? </p>
                        <div className="form-row mb-3 text-white">
                            <div className="col text-left">
                                <button onClick={this.handleAccountOption} className="btn btn-lg btn-secondary btn-block" value="yes">Yes</button>
                            </div>
                            <div className="col text-left">
                                <button onClick={this.handleAccountOption} className="btn btn-lg btn-primary btn-block" value="no">No</button>
                            </div>
                        </div>
                    </div>

                }
            </form>
        );
    }
}

export default FormAccount;