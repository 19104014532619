import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Popover, PopoverBody } from 'reactstrap';

import { FaEye } from 'react-icons/fa';

class SearchResults extends Component {

    state = {
        isPopoverOpen: false
    }

    toggle = () => {
        this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
    }

    render() {
        return (
            <React.Fragment>
                <tr>
                    <td>{this.props.campaignId}</td>
                    <td>
                        <i id={`popover${this.props.campaignId}`} onMouseOver={this.toggle} onMouseOut={this.toggle} style={{ textAlign: "right" }}>
                            <FaEye />
                        </i>
                        <Popover placement="top" isOpen={this.state.isPopoverOpen} target={`popover${this.props.campaignId}`} boundariesElement="window">
                            <PopoverBody><img src={this.props.thumbnail} style={{ width: "250px", height: "150px", overflow: "visible" }} alt="" /></PopoverBody>
                        </Popover>

                    </td>
                    <td style={{ textAlign: "left" }}>
                        <Link to={`/campaign/${this.props.campaignId}`}>{this.props.name}</Link>
                    </td>
                    <td style={{ textAlign: "left" }}>{this.props.rateType}</td>
                    <td className="text-right">{this.props.affiliateRate}</td>
                    <td className="text-right">{(this.props.epc) > 0 ? `$${(this.props.epc).toFixed(2)}` : 'New'}</td>
                    <td className="text-right">
                        {this.props.allowedTrafficTypes}
                    </td>
                </tr>
            </React.Fragment>);
    }
}

// Add tooltip to fa-icons - reactToolTips usage throws warning

export default SearchResults;