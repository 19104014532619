import React, { Component } from 'react';
import { Prompt } from 'react-router';

import StatesProvinces from '../../../component/FormElements/StatesProvinces';
import Countries from '../../../component/FormElements/Countries';

import StateList from '../../../data/statelist.js';
import CountryList from '../../../data/countrylist';

class Address extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.stateName = React.createRef();
        this.country = React.createRef();
        this.state = {
            editing: false,
            address: {
                address1: this.props.address.address1,
                address2: this.props.address.address2,
                city: this.props.address.city,
                state: this.getState(this.props.address.country_code),
                non_state: this.getNonState(this.props.address.country_code),
                country: {
                    value: this.props.address.country_code,
                    text: this.getCountry(this.props.address.country_code)
                },
                zipcode: this.props.address.zipcode
            },
            dataUpdated: false,
            isFormHalfFilled: false
        }
    }

    getState = (countryCode) => {
        let state;
        if (countryCode === 'US' || countryCode === 'CA') {
            state = {
                value: StateList.states.filter(state => state.value === this.props.address.state)[0].value,
                text: this.props.address.state
            }
        } else {
            state = {
                value: '',
                text: ''
            }
        }
        return state;
    }

    getNonState = (countryCode) => {
        let state;
        if (countryCode !== 'US' && countryCode !== 'CA') {
            state = this.props.address.state;
        } else {
            state = '';
        }
        return state;
    }

    getCountry = (countryCode) => {
        const country = CountryList.countries.filter(country => country.code === countryCode)[0].name;
        return country;
    }

    validate = () => {
        const input_state = this.stateName.current;
        const input_country = this.country.current;

        if ((input_country.value === 'CA' || input_country.value === 'US') && input_state.value === '') {
            input_state.setCustomValidity('You must select a state / province.');
            return false;
        }
        else {
            return true;
        }
    }

    setEditing = (e) => {
        e.preventDefault();

        this.setState({
            editing: !this.state.editing,
            address: this.state.address,
            isFormHalfFilled: false
        });
    }

    inputChangeHandler = () => {
        this.setState({ isFormHalfFilled: true });
    }

    saveSettings = (e) => {

        e.preventDefault();

        if (this.validate()) {
            /* Do form submission */
            this.setState({
                editing: !this.state.editing,
                address: {
                    address1: e.target.address1.value,
                    address2: e.target.address2.value,
                    city: e.target.city.value,
                    state: {
                        value: e.target.state.value,
                        text: e.target.state.options[e.target.state.selectedIndex].text
                    },
                    non_state: e.target.non_state.value,
                    country: {
                        value: e.target.country.value,
                        text: e.target.country.options[e.target.country.selectedIndex].text
                    },
                    zipcode: e.target.zipcode.value
                }
            }, () => {
                const authtoken = localStorage.getItem('mb-auth-token');

                fetch(`${process.env.REACT_APP_API_URL}/affiliate/addressDetails`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    },
                    body: JSON.stringify({
                        address1: this.state.address.address1,
                        address2: this.state.address.address2,
                        city: this.state.address.city,
                        state: this.state.address.state.value,
                        non_state: this.state.address.non_state,
                        country: {
                            text: this.state.address.country.text,
                            key: this.state.address.country.value
                        },
                        zipcode: this.state.address.zipcode
                    })
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.setState({ dataUpdated: true }, () => {
                                this.props.showSystemMessage("success", result.message);
                            });
                        } else if (result["success"] === false) {
                            this.setState({ dataUpdated: false }, () => {
                                // this.props.showSystemMessage("error", result.errors.message);

                                this.props.showSystemMessage("error", "There was an error updating your profile.");
                            });
                        } else {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    })
                    .catch(error => {
                        this.props.showSystemMessage("error", error.message);
                    });
            });
        } else {
            /* Forces html5 form validation */
            const form = this.form.current;
            const input_state = this.stateName.current;
            form.reportValidity();

            /* Reset any previous custom validity settings to valid. If you don't do this the form will remain invalid. This is different than
            the registration form because we're doing this on form submit rather than on button click. */
            input_state.setCustomValidity('');
        }
    }

    render() {
        return (
            <div className="card my-2 max-width-750">
                <Prompt
                    when={this.state.isFormHalfFilled}
                    message="Are you sure you want to leave? Changes you made may not be saved."
                />
                <form
                    ref={this.form}
                    onSubmit={this.saveSettings}>
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Address</div>
                        {this.props.showEdit &&
                            <div className="text-right">
                                {this.state.editing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                                }
                            </div>}
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td className="no-border">Address</td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {this.state.editing === false ?
                                            <React.Fragment>
                                                <div>{this.state.address.address1}</div>
                                                {this.state.address.address2 !== '' &&
                                                    <div>{this.state.address.address2}</div>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <input
                                                    name="address1"
                                                    className="form-control mb-2"
                                                    type="text"
                                                    defaultValue={this.state.address.address1}
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </input>
                                                <input
                                                    name="address2"
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={this.state.address.address2}
                                                    onChange={this.inputChangeHandler}>
                                                </input>
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.address.city}</div>
                                            :
                                            <input
                                                name="city"
                                                className="form-control"
                                                type="text"
                                                defaultValue={this.state.address.city}
                                                onChange={this.inputChangeHandler}
                                                required>
                                            </input>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.address.state.text}</div>
                                            :
                                            <select
                                                ref={this.stateName}
                                                name="state"
                                                className="form-control"
                                                defaultValue={this.state.address.state.value}
                                                onChange={this.inputChangeHandler}>
                                                <option value=""></option>
                                                <StatesProvinces />
                                            </select>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>State (Not USA or Canada)</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.address.non_state}</div>
                                            :
                                            <input
                                                name="non_state"
                                                className="form-control"
                                                type="text"
                                                defaultValue={this.state.address.non_state}
                                                onChange={this.inputChangeHandler}>
                                            </input>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.address.country.text}</div>
                                            :
                                            <select
                                                ref={this.country}
                                                name="country"
                                                className="form-control"
                                                defaultValue={this.state.address.country.value}
                                                onChange={this.inputChangeHandler}
                                                required>
                                                <option value=""></option>
                                                <Countries />
                                            </select>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Zipcode</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.address.zipcode}</div>
                                            :
                                            <input
                                                name="zipcode"
                                                className="form-control"
                                                type="text"
                                                defaultValue={this.state.address.zipcode}
                                                onChange={this.inputChangeHandler}
                                                required>
                                            </input>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default Address;