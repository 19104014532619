import React from 'react';
import CampaignSet from '../../../component/CampaignSet/CampaignSet';

const SuggestedCampaigns = (props) => {
    return (
        <CampaignSet category={props.category} page="0" limit={props.limit} showSystemMessage={props.showSystemMessage}
            view={props.view} />
    );
}

export default SuggestedCampaigns;