import React from 'react';
import { Link } from 'react-router-dom';
import './CampaignCategoryItem.css';

const campaignCategoryItem = (props) => {

    let categoryClass = "card d-inline-block mr-2 ";
    let tab = '';

    if (props.campaignCategory === "Popular Campaigns") {
        categoryClass += "Popular";
        tab = 'popular';
    } else if (props.campaignCategory === "Suggested For You") {
        categoryClass += "Suggested";
        tab = 'suggested';
    } else if (props.campaignCategory === "New Campaigns") {
        categoryClass += "New";
        tab = 'new';
    }

    return (<div className="d-flex mb-2 align-items-center category">
        <div className={categoryClass}>
            <div className="card-body py-2 px-3 font-weight-bold" style={{fontSize:".8rem"}}>
                {props.campaignCategory}
            </div>
        </div>
        {/* Do not show "see more" link for popular campaigns */}
        {props.campaignCategory !== "Popular Campaigns" &&
            <Link to={`/browse/${tab}`}>See More</Link>
        }
    </div>);
}

export default campaignCategoryItem;