import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './UserStats.css';

class UserStats extends Component {

    _isMounted = false;

    state = {
        todayEarnings: '-',
        yesterdayEarnings: '-',
        thisMonthEarnings: '-',
        thisYearEarnings: '-'
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchTodayStats();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchTodayStats = () => {

        const mbToken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/affiliatestats`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"]) {
                    if (this._isMounted) {
                        this.setState({
                            todayEarnings: result.earnings_today,
                            yesterdayEarnings: result.earnings_yesterday,
                            thisMonthEarnings: result.this_month,
                            thisYearEarnings: result.this_year,
                            dataLoaded: true
                        });
                    }
                } else if (result["success"] === false) {
                    if (result.code !== 401) {
                        this.props.showSystemMessage("error", result.message);
                    }
                }
            }).catch(error => {
                this.props.showSystemMessage("error", error.message);
            });
    }

    render() {

        let todayDate = moment().startOf("day").format("YYYY-MM-DD");
        let yesterdayDate = moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD");
        let monthStartDate = moment().startOf("month").format("YYYY-MM-DD");
        let monthEndDate = moment().endOf("month").format("YYYY-MM-DD");

        return (
            <React.Fragment>
                {/* Need to fetch the username, id, earning stats from database*/}
                <div className="UserDetails">
                    <div className="text-primary font-weight-bold text-uppercase d-inline-block">{this.props.affiliate.name}</div>
                    <div className="text-muted d-inline-block" style={{ paddingLeft: "4px" }}> | ID# {this.props.affiliate.affiliate_id}</div>
                </div>

                {/* AS OF YET, showing/hiding comps with CSS but will change it to JSX later when implement wrapping component */}
                {/* COMP - 1 */}

                <div id="stats-flush" className="EarningStats mb-bottom-rounded" style={{ backgroundColor: "#f9f9f9", flexWrap: "wrap", display: "flex" }}>
                    <div
                        style={{
                            backgroundColor: "#f4f4f4", textAlign: "center",
                            paddingLeft: "3rem", paddingRight: "3rem",
                            paddingBottom: ".5rem", paddingTop: ".5rem"
                        }}
                        className="font-weight-bold">
                        Earnings Summary
                        </div>
                    <div className="py-2 px-3 border-right">
                        Today:  <Link to={`/reports/earnings?startDate=${todayDate}&endDate=${todayDate}`} className="text-primary">${Intl.NumberFormat('en-GB', {minimumFractionDigits: 2}).format(this.state.todayEarnings)}</Link>
                    </div>
                    <div className="py-2 px-3 border-right">
                        Yesterday:  <Link to={`/reports/earnings?startDate=${yesterdayDate}&endDate=${yesterdayDate}`} className="text-primary">${Intl.NumberFormat('en-GB', {minimumFractionDigits: 2}).format(this.state.yesterdayEarnings)}</Link>
                    </div>
                    <div className="py-2 px-3">
                        This month:  <Link to={`/reports/earnings?startDate=${monthStartDate}&endDate=${monthEndDate}`} className="text-primary">${Intl.NumberFormat('en-GB', {minimumFractionDigits: 2}).format(this.state.thisMonthEarnings)}</Link>
                    </div>
                </div>

                {/* COMP - 2 */}
                <div id="stats-card">
                    <div className="card" style={{ marginTop: ".5rem" }}>
                        <div className="card-header">
                            Earnings Summary
                        </div>
                        <div className="card-body" style={{ padding: ".25rem" }}>
                            <div className="py-2 px-3 d-inline-block">
                                Today:  <Link to={`/reports/earnings?startDate=${todayDate}&endDate=${todayDate}`} className="text-primary">${this.state.todayEarnings}</Link>
                            </div>
                            <div className="py-2 px-3 d-inline-block">
                                Yesterday:  <Link to={`/reports/earnings?startDate=${yesterdayDate}&endDate=${yesterdayDate}`} className="text-primary">${this.state.yesterdayEarnings}</Link>
                            </div>
                            <div className="py-2 px-3 d-inline-block">
                                This month:  <Link to={`/reports/earnings?startDate=${monthStartDate}&endDate=${monthEndDate}`} className="text-primary">${this.state.thisMonthEarnings}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserStats;