import React, { Component } from 'react';
import { Zap, Copy } from 'react-feather';
import { CSSTransition } from 'react-transition-group';
import { Modal, UncontrolledTooltip } from 'reactstrap';
//import queryString from 'query-string'; (For getting url params)
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';

import RadioButton from './RadioButton/RadioButton';
import CampaignInfo from './CampaignInfo/CampaignInfo';
import CampaignRates from './CampaignRates/CampaignRates';
import CampaignLandingPages from './CampaignLandingPages/CampaignLandingPages';
import CampaignCreatives from './CampaignCreatives/CampaignCreatives';
import TrackingSettings from './TrackingSettings/TrackingSettings';
import AffiliateCampaignStatus from './AffiliateCampaignStatus/AffiliateCampaignStatus';
import Banner from './Banner/Banner';
import CreativeTextLink from './CreativeTextLink/CreativeTextLink';
import CreativeEmailHTML from './CreativeEmailHTML/CreativeEmailHTML';
import CreativeEmailText from './CreativeEmailText/CreativeEmailText';
import Macro from '../GlobalPostback/Macro/Macro';

import './CampaignPage.css';
import Loading from '../../component/UI/Loading/Loading';
import TrafficTypeItem from './TrafficTypeItem/TrafficTypeItem';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RequestModal from './RequestModal/RequestModal';
import SuppressionListModal from './SuppressionListModal/SuppressionListModal';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';

class CampaignPage extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        // For copy to clipboard functionality
        this.trackingLink = React.createRef();
    }

    state = {
        dataLoaded: false,
        campaignData: '',
        postbackLoaded: true,
        startTracking: false,           // Make BuildTrackingLink button disappear
        showTrackingOptions: false,     // To Show Landing Page and Sub ID options

        isBannerOpen: false,            // Show/Hide banner modal
        isCreativeTextOpen: false,      // show/hide creativeText modal
        isCreativeEmailOpen: false,     // show/hide creative Email modal
        isMacroModalOpen: false,
        bannerModalMode: '',
        textModalMode: '',
        emailModalMode: {
            mode: '',
            type: ''
        },

        generateLinks: {                // Variables as state to generate tracking link for selected options
            trafficType: '',
            creativeType: '',
            creativeId: '',
            landingPage: '',        // if default page is selected
        },
        subIds: '',
        isTrackingLinkReady: false,
        isCreativeSelected: false,
        creativeTextDisplay: '',            // To display the selected text creative below creative Type radio buttons
        requestApprovalModal: false,
        trackingLink: '',
        suppressionLists: [],
        isSuppressionListModalOpen: false,
        safesearch: false
    }

    componentDidMount() {
        this._isMounted = true;
        verifyToken().then(result => {
            if (result === true) {
                this.getCampaignData(this.props);
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage("error", error.message);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getCampaignData = () => {
        // Get the campaign data
        // const urlParams = queryString.parse(this.props.location.search); This was for using regular url params
        const urlParams = this.props.campaign_id;
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/campaign/${urlParams}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        if (this._isMounted) {
                            this.setState({
                                dataLoaded: true,
                                campaignData: result,
                                generateLinks: {
                                    trafficType: '',
                                    creativeType: '',
                                    creativeId: '',
                                    landingPage: result.landing_pages.filter(landingPage => landingPage.default_lp === 'Y')[0].landing_page_id
                                }
                            });
                        }
                    } else if (result["success"] === false) {
                        if (result.code !== 401) {
                            if (this._isMounted) {
                                this.setState({
                                    dataLoaded: true
                                });
                            }
                        }
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                        if (this._isMounted) {
                            this.setState({
                                dataLoaded: true
                            });
                        }
                    }
                }).catch(error => {
                    console.log('Error on CampaignPage: ', error.message);
                    this.props.showSystemMessage("error", "Connection error.")
                    if (this._isMounted) {
                        this.setState({
                            dataLoaded: true
                        });
                    }
                });
    }

    // If this.state.startTracking!== false => set classname to show button and blur the background
    // else set css classes to hide button and focus the background forms

    startTrackingHandler = () => {
        this.setState({ startTracking: true });
    }

    // 1. Whenever a radio button option is changed, this method will be called.
    // 2. The logic here is to show landing page and Sub ID options only when trafficType and creativeType radio buttons are selected
    // 3. Using states to verify if both traffic and creative types are selected

    // Have to come up with a separated function for the redundant part of the function - line 55-58

    radioChangedHandler = (event) => { //1
        if (event.target.name === 'trafficType') { //3
            this.setState({ generateLinks: { ...this.state.generateLinks, trafficType: event.target.value } }, () => {
                this.showTrackingOptionsHandler();
                this.getTrackingLink();
            });
        } else if (event.target.name === 'creativeType') {
            if (event.target.value === 'B') { this.setState({ isBannerOpen: true, bannerModalMode: 'tracking' }); }
            if (event.target.value === 'T') { this.setState({ isCreativeTextOpen: true, textModalMode: 'tracking' }); }
            if (event.target.value === 'H' || event.target.value === 'E') {
                this.setState({ isCreativeEmailOpen: true, emailModalMode: { mode: 'tracking', type: '' } });
            }
            this.setState({ generateLinks: { ...this.state.generateLinks, creativeType: event.target.value }, isCreativeSelected: false }, () => {
                this.showTrackingOptionsHandler();
                this.getTrackingLink();
            });
        }
    }

    // Every time a radio button is changed, we will fire the following method to check if both are selected or not. If they are, we change state of trackingOptions to true
    showTrackingOptionsHandler = () => { //2
        if (this.state.generateLinks.trafficType !== '' && this.state.generateLinks.creativeType !== '') {
            this.setState({ showTrackingOptions: true });
        }
    }

    handleSafesearch = (e) => {
        const checked = e.target.checked
        this.setState({safesearch: checked}, () => {
            this.getTrackingLink();
        })
    }

    // Called only when closing the banner modal without any selection

    modalCloseHandler = () => {

        if (this.state.bannerModalMode === 'view' || this.state.emailModalMode.mode === 'view' || this.state.textModalMode === 'view') {
            this.setState({
                isBannerOpen: false,
                isCreativeTextOpen: false,
                isCreativeEmailOpen: false
            });
        } else {
            if (this.state.generateLinks.creativeType === 'B') {
                this.setState({ isBannerOpen: false, generateLinks: { ...this.state.generateLinks, creativeId: '' } }, () => {
                    this.getTrackingLink();
                });
            } else if (this.state.generateLinks.creativeType === 'T') {
                this.setState({ isCreativeTextOpen: false, generateLinks: { ...this.state.generateLinks, creativeId: '' } }, () => {
                    this.getTrackingLink();
                });
            } else if (this.state.generateLinks.creativeType === 'H' || this.state.generateLinks.creativeType === 'E') {
                this.setState({ isCreativeEmailOpen: false, generateLinks: { ...this.state.generateLinks, creativeId: '' } }, () => {
                    this.getTrackingLink();
                });
            }
        }
    }

    macroModalHandler = () => {
        this.setState({ isMacroModalOpen: !this.state.isMacroModalOpen });
    }

    creativeSelectedHandler = (dataFromModal) => {
        if (this.state.generateLinks.creativeType === 'B') {
            this.setState({ isBannerOpen: false });
        } else if (this.state.generateLinks.creativeType === 'T') {
            this.setState({ isCreativeTextOpen: false });
        } else if (this.state.generateLinks.creativeType === 'H') {
            this.setState({ isCreativeEmailOpen: false });
        } else if (this.state.generateLinks.creativeType === 'E') {
            this.setState({ isCreativeEmailOpen: false });
        }
        this.setState({ generateLinks: { ...this.state.generateLinks, creativeId: dataFromModal.selectedCreativeId }, isCreativeSelected: true }, () => {
            this.getTrackingLink();
        });
    }

    // Gets landing page ID where user has clicked
    trackingSettingHandler = (lpId) => {
        this.setState({ generateLinks: { ...this.state.generateLinks, landingPage: lpId } }, () => {
            this.getTrackingLink();
        });
    }

    submitSubIdHandler = (subIdObj) => {
        this.setState({ subIds: subIdObj }, () => {
            this.getTrackingLink();
        });
    }

    getTrackingLink = () => {

        let tempCreativeId = '';
        const authtoken = localStorage.getItem('mb-auth-token');

        if (this.state.generateLinks.creativeType === 'S') {
            tempCreativeId = 918273;
        } else if (this.state.generateLinks.creativeType === 'C') {
            tempCreativeId = 918271;
        } else if (this.state.generateLinks.creativeType === 'R') {
            tempCreativeId = 918277;
        } else if (this.state.generateLinks.creativeType === 'B' || this.state.generateLinks.creativeType === 'T' || this.state.generateLinks.creativeType === 'H' || this.state.generateLinks.creativeType === 'E') {
            tempCreativeId = this.state.generateLinks.creativeId;
        }

        fetch(`${process.env.REACT_APP_API_URL}/trackinglink`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                campaign_id: this.props.campaign_id,
                traffic_type: this.state.generateLinks.trafficType,
                creative_id: tempCreativeId,
                lpid: this.state.generateLinks.landingPage,
                sub1: this.state.subIds.sub1,
                sub2: this.state.subIds.sub2,
                sub3: this.state.subIds.sub3,
                sub4: this.state.subIds.sub4,
                sub5: this.state.subIds.sub5,
                safesearch: this.state.safesearch
            })
        })
            .then(res => res.json())
            .then(result => {
                // show trackinglink only if both trafficType and creativeType are selected
                if (result["success"] === true) {
                    if (result["withTrafficAndCreative"] === true) {
                        if (this._isMounted) {
                            this.setState({ isTrackingLinkReady: true, trackingLink: result["tracking-link"], creativeTextDisplay: result["tracking-link"], dataLoaded: true });
                        }
                    } else {
                        if (this._isMounted) {
                            this.setState({
                                creativeTextDisplay: result["tracking-link"],
                                dataLoaded: true
                            });
                        }
                    }
                }
                else if (result["success"] === false) {
                    if (this._isMounted) {
                        this.setState({
                            dataLoaded: true,
                            isTrackingLinkReady: false
                        });
                    }
                } else {
                    this.props.showSystemMessage("error", result.errors.message);
                    if (this._isMounted) {
                        this.setState({
                            dataLoaded: true,
                            isTrackingLinkReady: false
                        });
                    }
                }
            }).catch(error => {
                this.props.showSystemMessage("error", error.message);
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true
                    });
                }
            });
    }

    requestApproval = () => {
        /* Send the request for approval */

    }

    toggleRequestApprovalModal = () => {
        if (this._isMounted) {
            this.setState({
                requestApprovalModal: !this.state.requestApprovalModal
            });
        }
    }

    toggleSuppressionListModal = () => {
        if (this._isMounted) {
            this.setState({
                isSuppressionListModalOpen: !this.state.isSuppressionListModalOpen
            });
        }
    }


    getTrafficButtons = () => {

        const trafficTypes = this.state.campaignData.allowed_traffic_types;
        let rows = [];

        for (var key in trafficTypes) {
            rows.push(<TrafficTypeItem key={key} trafficType={key} allowed={trafficTypes[key]} type="radio" action={this.radioChangedHandler} />);
        }
        return rows;
    }

    getCreativeButtons = () => {
        const uniqueCreativeTypes = [...new Set(this.state.campaignData.creatives.map(creative => creative.type))];
        let rows = [];

        uniqueCreativeTypes.forEach(creativeType => {
            if (creativeType === 'banner') {
                rows.push(<RadioButton key={creativeType} buttonName="Banners" id="banners" value='B' type="creative" changed={this.radioChangedHandler} />)
            } else if (creativeType === 'text') {
                rows.push(<RadioButton key={creativeType} buttonName="Text link" id="text-link" value='T' type='creative' changed={this.radioChangedHandler} />)
            } else if (creativeType === 'textEmail') {
                rows.push(<RadioButton key={creativeType} buttonName="Solo e-mail (text)" id="creative-email-text" value='E' type="creative" changed={this.radioChangedHandler} />)
            } else if (creativeType === 'htmlEmail') {
                rows.push(<RadioButton key={creativeType} buttonName="Solo e-mail (html)" id="creative-email-html" value='H' type="creative" changed={this.radioChangedHandler} />)
            }
        });
        return rows;
    }

    savePostback = (e) => {
        e.preventDefault();

        this.setState({
            postbackLoaded: false
        });

        const urlParams = this.props.campaign_id;
        const authtoken = localStorage.getItem('mb-auth-token');

        const postbackBody = {
            'type': e.target.postback_type.value,
            'code': e.target.postback_code.value
        }

        // Submit the data
        fetch(`${process.env.REACT_APP_API_URL}/campaign/${urlParams}/postback`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify(postbackBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        this.props.showSystemMessage("success", result.message);
                        if (this._isMounted) {
                            this.setState({
                                postbackLoaded: true
                            });
                        }
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", result.message);
                        if (this._isMounted) {
                            this.setState({
                                postbackLoaded: true
                            });
                        }
                    } else {
                        this.props.showSystemMessage("error", "There was an error saving your postback. Please try again.");
                        if (this._isMounted) {
                            this.setState({
                                postbackLoaded: true
                            });
                        }
                    }
                }
            ).catch((error) => {
                this.props.showSystemMessage("error", error.message);
                if (this._isMounted) {
                    this.setState({
                        postbackLoaded: true
                    });
                }
            });
    }

    showBannerCreatives = () => {
        if (this._isMounted) {
            this.setState({
                isBannerOpen: !this.state.isBannerOpen,
                bannerModalMode: 'view'
            });
        }
    }

    showEmailCreatives = (emailType) => {
        if (this._isMounted) {
            this.setState({
                isCreativeEmailOpen: !this.state.isCreativeEmailOpen,
                emailModalMode: {
                    mode: 'view',
                    type: emailType
                }
            });
        }
    }

    showTextCreatives = () => {
        if (this._isMounted) {
            this.setState({
                isCreativeTextOpen: !this.state.isCreativeTextOpen,
                textModalMode: 'view'
            });
        }
    }

    getSuppressionList = () => {

        const offerId = this.props.campaign_id;
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/emailSuppression?m=${this.state.campaignData.details.advertiser_id}&o=${offerId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then((result) => {
                if (result["success"] === true) {
                    this.setState({ ...this.state, suppressionLists: result.urls, isSuppressionListModalOpen: true });
                } else if (result["success"] === false) {
                    this.props.showSystemMessage('error', result.errors.message);
                }
            })
            .catch(error => {
                console.log('Error on CampaignPage: ', error.message);
                this.props.showSystemMessage("error", "Connection error.")
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true
                    });
                }
            });
    }

    render() {

        let trackingLinkButton = (
            <div id="campaign-tracking-blur" className="pt-5 text-center">
                <button id="campaign-tracking-start" className="btn btn-warning shadow" onClick={this.startTrackingHandler}>
                    <Zap size={20} />Build tracking link
            </button>
            </div>);

        let copyButton = (
            <CopyToClipboard text={this.state.trackingLink}>
                <button id="tracking-ready" className="btn btn-outline-primary btn-sm">
                    <Copy size={16} /><span style={{ paddingLeft: "5px", paddingTop: "50px" }}>Copy Link</span>
                </button>
            </CopyToClipboard>
        );

        let trackingLinkPlaceHolder;

        if ((this.state.generateLinks.creativeType === 'B' && !this.state.generateLinks.creativeId) || (this.state.generateLinks.creativeType === 'T' && !this.state.generateLinks.creativeId) ||
            (this.state.generateLinks.creativeType === 'H' && !this.state.generateLinks.creativeId) ||
            (this.state.generateLinks.creativeType === 'E' && !this.state.generateLinks.creativeId)
        ) {
            trackingLinkPlaceHolder = 'Invalid creative specified';
        } else {
            trackingLinkPlaceHolder = 'Select a traffic type and creative type to get started...';
        }

        return (
            <React.Fragment>
                {this.state.dataLoaded && this.state.campaignData ?
                    // This campaign exists
                    <React.Fragment>
                        <Modal isOpen={this.state.isBannerOpen} toggle={this.modalCloseHandler} className="modal-lg">
                            <Banner
                                bannerSelected={this.creativeSelectedHandler}
                                modalClosed={this.modalCloseHandler}
                                mode={this.state.bannerModalMode}
                                banners={this.state.campaignData.creatives.filter(creative => creative.type === 'banner')}
                                merchantId={this.state.campaignData.details.advertiser_id}
                                offerId={this.state.campaignData.campaign_id}
                            />
                        </Modal>
                        <Modal isOpen={this.state.isCreativeTextOpen} className="modal-lg" toggle={this.modalCloseHandler}>
                            <CreativeTextLink
                                creativeSelected={this.creativeSelectedHandler}
                                modalClosed={this.modalCloseHandler}
                                mode={this.state.textModalMode}
                                creativeLinks={this.state.campaignData.creatives.filter(creative => creative.type === 'text')}
                                selectedTrafficType={this.state.generateLinks.trafficType}
                                campaign_id={this.state.campaignData.campaign_id}
                                showSystemMessage={this.props.showSystemMessage}
                            />
                        </Modal>
                        <Modal isOpen={this.state.isCreativeEmailOpen}
                            toggle={this.modalCloseHandler}
                            className="modal-lg">
                            {(this.state.generateLinks.creativeType === 'H' || this.state.emailModalMode.type === 'H') &&

                                <CreativeEmailHTML
                                    creativeSelected={this.creativeSelectedHandler}
                                    modalClosed={this.modalCloseHandler}
                                    mode={this.state.emailModalMode}
                                    creatives={this.state.campaignData.creatives.filter(creative => creative.type === 'htmlEmail')}
                                    showSystemMessage={this.props.showSystemMessage}
                                    campaign_id={this.state.campaignData.campaign_id}
                                ></CreativeEmailHTML>}

                            {(this.state.generateLinks.creativeType === 'E' || this.state.emailModalMode.type === 'E') &&

                                <CreativeEmailText
                                    modalClosed={this.modalCloseHandler}
                                    campaign_id={this.state.campaignData.campaign_id}
                                    mode={this.state.emailModalMode}
                                    creativeSelected={this.creativeSelectedHandler}
                                    creatives={this.state.campaignData.creatives.filter(creative => creative.type === 'textEmail')}
                                ></CreativeEmailText>}

                        </Modal>
                        <Modal isOpen={this.state.isMacroModalOpen} toggle={this.macroModalHandler} className="modal-lg">
                            <Macro
                                modalClosed={this.macroModalHandler}
                                showSystemMessage={this.props.showSystemMessage}
                            />
                        </Modal>
                        <Modal isOpen={this.state.isSuppressionListModalOpen} toggle={this.toggleSuppressionListModal} className="modal-md"><SuppressionListModal list={this.state.suppressionLists} /></Modal>
                        <div className="row SingleCampaign">
                            <div className="col-lg-5 split-left">
                                <CampaignInfo
                                    modalStatus={this.state.requestApprovalModal}
                                    toggleModal={this.toggleRequestApprovalModal}
                                    data={this.state.campaignData}
                                    showSystemMessage={this.props.showSystemMessage} />
                                <CampaignRates
                                    rates={this.state.campaignData.commission} />
                                <CampaignLandingPages
                                    landingPageSample={this.state.campaignData.details.landing_page_sample}
                                    landingPages={this.state.campaignData.landing_pages} />
                                <CampaignCreatives
                                    offerId={this.state.campaignData.campaign_id}
                                    merchantId={this.state.campaignData.details.advertiser_id}
                                    creatives={this.state.campaignData.creatives}
                                    showBannerCreatives={this.showBannerCreatives}
                                    showEmailCreatives={this.showEmailCreatives}
                                    showTextCreatives={this.showTextCreatives} />

                                <div className="card my-2">
                                    <div className="card-header card-header-no-border">
                                        Details
                                </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr >
                                                        <td>Network EPC</td>
                                                        <td>
                                                            {this.state.campaignData.details.epc > 0 ?
                                                                `$${parseFloat(this.state.campaignData.details.epc).toFixed(2)}` : 'New'}</td>{/* props */}
                                                    </tr>
                                                    <tr>
                                                        <td>Expiry Date</td>
                                                        <td>
                                                            {this.state.campaignData.details.expiry_date !== 'No expiry' ?
                                                                <Moment utc format="MMM DD, YYYY">{this.state.campaignData.details.expiry_date}</Moment>
                                                                :
                                                                <span>No expiry</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tracking Type</td>
                                                        <td>
                                                            {(this.state.campaignData.details.tracking_type === 'S' || this.state.campaignData.details.tracking_type === 'M') ?
                                                                <span>Server-to-server tracking</span>
                                                                :
                                                                this.state.campaignData.details.tracking_type === 'L' ?
                                                                    <span>Pixel tracking</span>
                                                                    :
                                                                    this.state.campaignData.details.tracking_type === 'I' ?
                                                                        <span>iFrame tracking</span>
                                                                        :
                                                                        this.state.campaignData.details.tracking_type === 'A' ?
                                                                            <span>API tracking</span>
                                                                            :
                                                                            this.state.campaignData.details.tracking_type === 'G' ?
                                                                                <span>Global tracking</span>
                                                                                :
                                                                                this.state.campaignData.details.tracking_type === 'H' ?
                                                                                    <span>Host and Post</span>
                                                                                    :
                                                                                    <span>Unknown tracking</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 split-right">

                                <div className="card my-2">
                                    <div className="card-header justify-content-between d-flex">
                                        Campaign Description
                                </div>
                                    <div className="card-body">
                                        <p
                                            className="m-0"
                                            style={{ padding: "15px" }}
                                            dangerouslySetInnerHTML={{ __html: this.state.campaignData.details.description }}>
                                        </p>
                                    </div>
                                </div>

                                {this.state.campaignData.allowed_traffic_types.search_traffic === 'Y' && this.state.campaignData.search_restrictions !== "" && (
                                    <div className="card my-2">
                                        <div className="card-header justify-content-between d-flex">
                                            Search Restrictions
                                        </div>
                                        <div className="card-body">
                                            <p className="m-0" style={{ padding: "15px" }}>{this.state.campaignData.search_restrictions}</p>
                                        </div>
                                    </div>
                                )}

                                <div className="card my-2">
                                    <div className="card-header justify-content-between d-flex">
                                        Tracking Link Builder
                                </div>

                                    <div className="card-body position-relative" style={{ margin: "15px" }}>

                                        <React.Fragment>

                                            {/* Track Link Button will be disappreared when clicked once */}

                                            {
                                                // If the campaign is Approved
                                                this.state.campaignData && this.state.campaignData.details.affiliate_campaign_status === 'Approved' ?

                                                    <CSSTransition
                                                        in={!this.state.startTracking}
                                                        // appear={true}
                                                        timeout={500}
                                                        classNames="fade-overlay"
                                                        unmountOnExit
                                                    >
                                                        {trackingLinkButton}
                                                    </CSSTransition>

                                                    :

                                                    // If the campaign is anything other than Approved
                                                    <div id="campaign-tracking-blur" className="pt-5 text-center">
                                                        {this.state.campaignData &&
                                                            <React.Fragment>
                                                                <AffiliateCampaignStatus
                                                                    mode="button"
                                                                    toggleModal={this.toggleRequestApprovalModal}
                                                                    data={this.state.campaignData}
                                                                    showSystemMessage={this.props.showSystemMessage} />
                                                                <RequestModal
                                                                    modalStatus={this.state.requestApprovalModal}
                                                                    toggleModal={this.toggleRequestApprovalModal}
                                                                    data={this.state.campaignData}
                                                                    updateCampaignData={this.getCampaignData}
                                                                    affiliateId={this.props.affiliate_id}
                                                                    showSystemMessage={this.props.showSystemMessage}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                            }

                                            <div id="tracking-link-floater" className="form-group d-flex border rounded tracking-link-floating" style={{ height: "100px", zIndex: 2 }}>
                                                <div className="p-2 min-width-150 text-center d-flex flex-column justify-content-between">
                                                    <span>Your tracking link</span>
                                                    {this.state.isTrackingLinkReady ? copyButton : null}
                                                    { this.props.flags.includes(175) &&
                                                        <>
                                                            <div className="d-flex align-items-center border rounded p-1 justify-content-center" id="safesearch-tooltip">
                                                                <label htmlFor="safesearch" className="p-0 m-0" style={{fontSize: '0.75rem'}}>Search-Safe</label>
                                                                <input name="safesearch" id="safesearch" className="p-0 m-0 ml-2" type="checkbox" onChange={e=>this.handleSafesearch(e)}/>                                                            
                                                                
                                                            </div>
                                                            {/* We are using reacstrap uncontrolledtooltip because react-tooltips was causing a maximum depth issue randomly when used here */}
                                                            <UncontrolledTooltip placement="top" target="safesearch-tooltip" >                                                                
                                                                Check this box if you will be running this campaign on search engines.
                                                            </UncontrolledTooltip>
                                                        </>
                                                    }
                                                    
                                                </div>
                                                <textarea
                                                    ref={this.trackingLink}
                                                    className="form-control border-0 rounded-0 p-2"
                                                    id="trackingLink"
                                                    rows="2"
                                                    style={{ background: "rgb(225, 225, 225)", fontSize: "14px", height: "100%" }}
                                                    value={this.state.isTrackingLinkReady ? this.state.trackingLink : trackingLinkPlaceHolder}
                                                    readOnly
                                                ></textarea>
                                            </div>

                                            <div id="tracking-link-floater-spacer" style={{ height: "77px" }} className="p-2 min-width-125 d-none"></div>
                                            <hr />

                                            <div className="py-3">
                                                <h5 className="pb-2 d-flex" >
                                                    <strong>1</strong>
                                                    <div style={{ paddingLeft: "8px" }}>What type of traffic will you be sending?</div>
                                                </h5>

                                                {/* Passing the only allowed traffic types as props to this component */}

                                                {this.getTrafficButtons()}

                                            </div>
                                            <hr />
                                            <div className="py-3">
                                                <h5 className="pb-2 d-flex">
                                                    <strong>2</strong>
                                                    <div style={{ paddingLeft: "8px" }}>Select a creative</div>
                                                </h5>

                                                {/* These 3 creatives are always available */}
                                                <RadioButton buttonName="Raw link" id="raw" value="R" type="creative" changed={this.radioChangedHandler} />
                                                <RadioButton buttonName="Search" id="search" value="S" type="creative" changed={this.radioChangedHandler} />
                                                <RadioButton buttonName="Contextual" id="contextual" value="C" type="creative" changed={this.radioChangedHandler} />

                                                {/* Get the rest of the creatives based on the creatives available for this campaign */}
                                                {this.getCreativeButtons()}

                                                {(this.state.isCreativeSelected && this.state.generateLinks.creativeType === 'B') &&
                                                    (<div className="card my-2 banner-card" id={this.state.generateLinks.creativeId}>
                                                        <div className="card-body d-flex" style={{ padding: "1.25rem" }}>
                                                            <div className="d-flex align-items-center max-width-100">
                                                                <img className="w-100" src={`${process.env.REACT_APP_RESOURCE_URI}/getimage.asp?m=${this.state.campaignData.details.advertiser_id}&o=${this.state.campaignData.campaign_id}&i=${this.state.generateLinks.creativeId}.dat`} alt="banner" />
                                                            </div>
                                                            <div className="flex-1 d-flex align-items-center p-3">
                                                                <div>
                                                                    Banner_{this.state.generateLinks.creativeId}
                                                                    <br />
                                                                    <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_RESOURCE_URI}/getimage.asp?m=${this.state.campaignData.details.advertiser_id}&o=${this.state.campaignData.campaign_id}&i=${this.state.generateLinks.creativeId}.dat`}>Download</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                {(this.state.isCreativeSelected && this.state.generateLinks.creativeType === 'T') &&
                                                    <div className="card my-2 banner-card" id={this.state.generateLinks.creativeId}>
                                                        <div className="card-body d-flex" style={{ padding: "1.25rem" }}>
                                                            <div className="d-flex align-items-center">
                                                                <div style={{ textDecoration: "underline" }} dangerouslySetInnerHTML={{ __html: this.state.creativeTextDisplay }} />
                                                            </div>
                                                        </div>
                                                    </div>}

                                                {/* show email rules if selected */}

                                                {(this.state.generateLinks.trafficType === 'E') &&

                                                    // <div className="card my-2" id="emailRules">
                                                    <div className="card-body">
                                                        <table id="emailrules" className="card my-2 p-3">
                                                            <tbody>
                                                                <tr className="d-flex">
                                                                    <td className="rules-title">
                                                                        Restrictions:
                                                                        </td>
                                                                    <td style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: this.state.campaignData.email_restrictions.rules }}>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ height: "14px" }}>
                                                                    <td colSpan="3"></td>
                                                                </tr>
                                                                {this.state.campaignData.email_restrictions.suppression === 'Y' &&
                                                                    (<tr className="d-flex">
                                                                        <td className="rules-title">
                                                                            Suppression List:
                                                                            </td>
                                                                        <td>
                                                                            <button onClick={this.getSuppressionList}
                                                                                className="btn btn-sm btn-warning"
                                                                                target="_blank"
                                                                                hidefocus="true"
                                                                                rel="noopener noreferrer"
                                                                            >Get Suppression List </button>
                                                                        </td>
                                                                    </tr>)}
                                                                <tr style={{ height: "14px" }}>
                                                                    <td colSpan="3"></td>
                                                                </tr>
                                                                <tr className="d-flex">
                                                                    <td className="rules-title">
                                                                        Allowed Subject Lines:
                                                                        </td>
                                                                    <td style={{ fontSize: "13px", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: this.state.campaignData.email_restrictions.subject_lines }}>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ height: "14px" }}>
                                                                    <td colSpan="3"></td>
                                                                </tr>
                                                                <tr className="d-flex">
                                                                    <td className="rules-title">
                                                                        Allowed From Names:
                                                                        </td>
                                                                    <td style={{ fontSize: "13px", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: this.state.campaignData.email_restrictions.from_lines }}>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </div>

                                            {/* Show search rules if the search traffic type is selected */}

                                            {(this.state.generateLinks.trafficType === 'R') &&
                                                <div className="card my-2">
                                                    <div className="card-header justify-content-between d-flex">
                                                        Search Restrictions
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="m-0" style={{ padding: "15px" }}>{this.state.campaignData.search_restrictions}</p>
                                                    </div>
                                                </div>
                                            }

                                            {/* Only show the banner code when creativeId is not empty and creativeType is selected as a banner */}

                                            {this.state.showTrackingOptions &&
                                                <TrackingSettings
                                                    clicked={this.trackingSettingHandler}
                                                    submitted={this.submitSubIdHandler}
                                                    active={this.state.generateLinks.landingPage}
                                                    landingPages={this.state.campaignData.landing_pages}
                                                    offerId={this.state.campaignData.campaign_id} />
                                            }

                                        </React.Fragment>
                                    </div>
                                </div>

                                <div className="card my-2">
                                    <div className="card-header justify-content-between d-flex">
                                        Pixels / Postbacks
                            </div>
                                    <div className="card-body" style={{ margin: "15px" }}>
                                        <div>
                                            <form onSubmit={this.savePostback}>
                                                <select
                                                    name="postback_type"
                                                    className="custom-select custom-select-sm mb-3"
                                                    defaultValue={this.state.campaignData.postback.type}>
                                                    {
                                                        this.props.globalPostback.type && this.props.globalPostback.code ?
                                                            <option value="">Use global postback</option>
                                                            :
                                                            <option value="">Select a postback type</option>
                                                    }
                                                    <option value="L">URL (any campaign tracking type)</option>
                                                    {
                                                        (this.state.campaignData.details.tracking_type === "I" || (this.state.campaignData.details.tracking_type === "S" && this.state.campaignData.details.supports_multiple_postback === 'Y') || this.state.campaignData.details.tracking_type === "M") &&
                                                        <option value="I">HTML (iFrame or server tracking)</option>
                                                    }
                                                    {
                                                        this.state.campaignData.details.tracking_type === "S" &&
                                                        <option value="S">Server-server URL (any campaign tracking type)</option>
                                                    }
                                                </select>
                                                <br />
                                                <textarea
                                                    name="postback_code"
                                                    className="form-control p-2"
                                                    rows="3"
                                                    defaultValue={this.state.campaignData.postback.code}></textarea>
                                                <button type="button" className="btn btn-sm btn-link p-0 m-0" onClick={this.macroModalHandler}>See available macros</button>
                                                <br />
                                                <br />
                                                {this.state.postbackLoaded ?
                                                    <button type="submit" className="btn btn-primary">Save Postback</button>
                                                    :
                                                    <button className="btn btn-primary" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                                }

                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                    </React.Fragment>

                    : this.state.dataLoaded && !this.state.campaignData ?

                        //This campaign doesn't exist
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card my-2">
                                    <div className="card-header">
                                        Error
                            </div>
                                    <div className="card-body">
                                        This campaign doesn't exist.
                            </div>
                                </div>
                            </div>
                        </div>

                        : !this.state.dataLoaded &&

                        //Data has not been loaded yet

                        <div style={{ marginTop: '4rem' }}>
                            <Loading />
                        </div>

                }
            </React.Fragment>
        );
    }
}
// Will receive campaign ID in props
// Fetch the rest of the detail through a GET request 

export default withRouter(CampaignPage);

// active build tracking link button -> load additional components upon selecting appropriate options