import React, { Component } from 'react';
import './Banner.css';

// Turning this component into container to handle the selection methods right here rather than passing to SingleCampaign class to reduce complexity

class Banner extends Component {

    // Send just the selected banner id and true/false value to indicate whether a banner was selected or not

    state = {
        isBannerSelected: false,
        selectedCreativeId: ''
    }

    bannerSelectedHandler = (event) => {
        this.setState({ isBannerSelected: true, selectedCreativeId: [event.currentTarget.value] }, () => {
            this.props.bannerSelected(this.state);
        });
    }

    render() {
        return (
            <div className="Banner">
                <div className="modal-header">
                    <h5 className="modal-title">{this.props.mode === 'tracking' ? <React.Fragment>Select a Banner</React.Fragment> : <React.Fragment>Banners</React.Fragment>}</h5>
                    <button type="button" className="close" onClick={this.props.modalClosed}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">

                    {/* Iterate through all the banners */}
                    {this.props.banners.map(banner => (
                        <div className="card my-2" key={banner.creative_id}>
                            <div className="card-body d-flex" >
                                <div className="d-flex align-items-center max-width-250"  >
                                    <img className="w-100" src={banner.image} alt="banner" />{/* props.imgURL */}
                                </div>
                                <div className="flex-1 d-flex align-items-center p-3">
                                    <div>
                                        Banner_{banner.creative_id} ({banner.width}x{banner.height})  {/* props.bannerCaption */}
                                        <br />
                                        <a target="_blank" href={banner.image} rel="noopener noreferrer" >Download</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center p-3">
                                    {this.props.mode === 'tracking' &&
                                        <button className="creativeSelect btn btn-outline-dark" value={banner.creative_id} onClick={this.bannerSelectedHandler}>Select</button> /* props.value */
                                    }
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.props.modalClosed}>Close</button>
                </div>
            </div>
        );
    }
}
export default Banner;